import React from 'react';
import { FormattedMessage, type MessageDescriptor } from 'react-intl';

import StatusInfo from '@components/Box/StatusBox';
import { cn } from '@utils/main';
import type { TimelineStatuses } from '@utils/order';
import type { TrackingOptionProps } from '@utils/types/order';
import './verticaltimeline.css';

interface VerticalTimelineProps<Status extends TimelineStatuses> extends TrackingOptionProps {
    timelineStatuses: TimelineStatuses[];
    currentCustomerTrackingStatus: Status;
    trackingOptions: Record<TimelineStatuses, (props: TrackingOptionProps) => React.ReactNode>;
    orderType: 'order' | 'return';
}
const VerticalTimeline = ({
    trackingOptions,
    timelineStatuses,
    currentCustomerTrackingStatus,
    orderType,
    uid,
    externalId,
    shippingModeType,
    isCancelable = false,
    isReturnable = false,
}: VerticalTimelineProps<TimelineStatuses>) => {
    const trackingStatusIndex =
        currentCustomerTrackingStatus && timelineStatuses.indexOf(currentCustomerTrackingStatus);

    return (trackingStatusIndex && trackingStatusIndex > -1) || trackingStatusIndex === 0
        ? timelineStatuses.map((stepStatus, index) => {
              const inProgress = timelineStatuses[trackingStatusIndex] === stepStatus;
              return (
                  <React.Fragment key={stepStatus}>
                      <div
                          className={cn('status-container text-base', {
                              'status-container_first': index === 0,
                              'status-container_in-progress': inProgress,
                              'status-container_future': index > trackingStatusIndex,
                              'status-container_last': index + 1 === timelineStatuses.length,
                          })}
                      >
                          <div
                              className={cn('status', {
                                  status_future: index > trackingStatusIndex,
                              })}
                          >
                              <span
                                  className={cn(
                                      'font-account-subheading font-medium uppercase',
                                      index > trackingStatusIndex && 'text-gray-light'
                                  )}
                              >
                                  {orderType === 'order' ? (
                                      <FormattedMessage
                                          id={`orders.order.detail.tracking.${stepStatus}` as MessageDescriptor['id']}
                                      />
                                  ) : (
                                      <FormattedMessage
                                          id={
                                              `orderReturn.return.detail.tracking.${stepStatus}` as MessageDescriptor['id']
                                          }
                                      />
                                  )}
                              </span>
                          </div>
                      </div>
                      {inProgress && currentCustomerTrackingStatus && trackingOptions[currentCustomerTrackingStatus] ? (
                          <StatusInfo
                              className={index + 1 === timelineStatuses.length ? 'mobile:border-none' : undefined}
                          >
                              {trackingOptions[currentCustomerTrackingStatus]({
                                  shippingModeType,
                                  isCancelable,
                                  isReturnable,
                                  uid,
                                  externalId,
                              })}
                          </StatusInfo>
                      ) : null}
                  </React.Fragment>
              );
          })
        : null;
};

export default VerticalTimeline;
