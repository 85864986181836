export const detectColor = (imgSrc: string): Promise<'white' | 'black'> => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    img.src = imgSrc;

    if (!context) {
        return Promise.reject('Canvas not supported');
    }

    return new Promise(resolve => {
        img.onload = () => {
            // draw 1px image from the top of image source
            context.drawImage(img, 0, 0, img.width, 100, 0, 0, 1, 1);

            // get rgb color from 1px image
            const [r, g, b] = context.getImageData(0, 0, 1, 1).data.slice(0, 3);

            // HSP equation from http://alienryderflex.com/hsp.html
            const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

            // Using the HSP value, determine whether the color is light or dark
            resolve(hsp <= 127.5 ? 'white' : 'black');
        };
    });
};

export const DEFAULT_IMAGE_WIDTH = 2000;
export const DEFAULT_IMAGE_HEIGHT = 2804;
