import { defineMessages } from 'react-intl';
import { isValidPhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';

const validationMessages = defineMessages({
    firstNameRequired: { id: 'validation.firstName.required' },
    lastNameRequired: { id: 'validation.lastName.required' },
    phoneRequired: { id: 'validation.phone.required' },
    phoneInvalid: { id: 'validation.phone.invalid' },
});

export const userInfo = yup.object().shape({
    firstName: yup.string().required(validationMessages.firstNameRequired.id),
    lastName: yup.string().required(validationMessages.lastNameRequired.id),
    birthDate: yup
        .object({
            year: yup.number().defined(),
            month: yup.number().required(),
            day: yup.number().required(),
        })
        .nullable(),
    phone: yup
        .string()
        .required(validationMessages.phoneRequired.id)
        .test('phoneNumberValid', validationMessages.phoneInvalid.id as yup.Message, val =>
            val ? isValidPhoneNumber(val) : false
        ),
});
