import { FormattedMessage } from 'react-intl';

import { useUrl } from '@hooks/useUrl';
import { DEFAULT_IMG_PLACEHOLDER } from '@utils/common';
import type { UserOrderReturn } from 'types/apiOms';

type Unpacked<T> = T extends (infer U)[] ? U : T;

interface DisplayReturnedItemProps {
    product: Unpacked<UserOrderReturn['returnedItems']>;
    displayReturnedQuantity?: boolean;
}

const DisplayReturnedItem = ({ product, displayReturnedQuantity }: DisplayReturnedItemProps) => {
    const { urls } = useUrl();
    return (
        <div className="flex flex-row gap-[1.6rem] rounded-[0.2rem] border border-gray-lighter p-6 desktop:p-4">
            {product?.orderLineItem?.productUrl ? (
                <a
                    href={`${urls.ecommerceRoot}${product?.orderLineItem.productUrl}`}
                    target="_blank"
                    rel="noreferrer"
                    className="w-1/3 shrink-0 desktop:w-16"
                >
                    <img
                        src={product?.orderLineItem?.imageUrl || DEFAULT_IMG_PLACEHOLDER}
                        alt={product?.orderLineItem?.label}
                    />
                </a>
            ) : (
                <img
                    className="w-1/3 shrink-0 desktop:w-16"
                    src={product?.orderLineItem?.imageUrl || DEFAULT_IMG_PLACEHOLDER}
                    alt={product?.orderLineItem?.label}
                />
            )}
            <div className="flex flex-col justify-center font-account-heading desktop:justify-between">
                <span className="font-medium uppercase">
                    {product?.orderLineItem?.productUrl ? (
                        <a
                            href={`${urls.ecommerceRoot}${product?.orderLineItem.productUrl}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {product?.orderLineItem?.label}
                        </a>
                    ) : (
                        product?.orderLineItem?.label
                    )}
                </span>
                <span className="flex flex-col gap-2 text-gray-medium desktop:flex-row desktop:gap-4">
                    <span className="order-2">
                        {product?.orderLineItem?.colorLabel && product?.orderLineItem?.colorLabel}
                    </span>
                    <span className="order-3 uppercase">
                        {product?.orderLineItem?.sizeLabel && product?.orderLineItem?.sizeLabel}
                    </span>
                    <span className="order-1">
                        {product?.orderLineItem?.localeTotalDisplayPrice || product?.orderLineItem?.totalDisplayPrice}
                    </span>
                </span>
                {displayReturnedQuantity && (
                    <span className="flex flex-row gap-2 text-gray-medium">
                        <span>
                            <FormattedMessage id="orderReturn.return.detail.tracking.products.returned" />
                        </span>
                        <span>{product?.receivedQuantity}</span>
                    </span>
                )}
            </div>
        </div>
    );
};

export default DisplayReturnedItem;
