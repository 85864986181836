import Cookies from 'js-cookie';
import { analytics } from '@sezane/front-components';
import { useQueryClient } from '@tanstack/react-query';

import { useAppContext } from '@context/AppContext';
import { useAuth } from '@context/AuthContext';
import { useCountry } from '@context/CountryContext';
import type { AlertsData } from '@pages/AlertsPage/config';
import { ALERTS_KEY } from '@pages/AlertsPage/config';
import type { WishlistData } from '@pages/WishlistsPage/config';
import { WISHLIST_KEY } from '@pages/WishlistsPage/config';
import { BRANDS, getBrand } from '../utils/brand';
import useCurrency from './useCurrency';
import type { Site } from 'types/apiEcommerce';
import type { OrderRead, User } from 'types/apiOms';

const brand = getBrand();
const segmentProps = {
    writeKey:
        (brand === BRANDS.sezane
            ? process.env.REACT_APP_SEZANE_SEGMENT_KEY
            : process.env.REACT_APP_OCTOBRE_SEGMENT_KEY) || '',
    cdnURL:
        (brand === BRANDS.sezane
            ? process.env.REACT_APP_SEZANE_SEGMENT_CDN
            : process.env.REACT_APP_OCTOBRE_SEGMENT_CDN) || '',
};

const getClientType = (user: User): string => {
    if (user.nbOrders === 0) {
        return 'Prospect';
    } else if (user.nbOrders === 1) {
        return 'New Customer';
    }

    return 'Repeat Customer';
};

const formatPrice = (price?: number): number | undefined =>
    price !== undefined ? parseFloat((price / 100).toFixed(2)) : undefined;

const getProductValue = (order: OrderRead): number => formatPrice(order.totalPrice ? order.totalPrice : 0)!;

const getProductsForSegment = (order: OrderRead, brand: string) =>
    order.products!.map(product => ({
        product_id: product.productProperty?.selectedVariant?.selectedItem?.uuid!,
        product_code: product.productProperty?.selectedVariant?.code!,
        sku: product.productProperty?.selectedVariant?.selectedItem?.ean,
        name: product.label,
        price: formatPrice(product.unitPrice),
        quantity: product.quantity,
        category: product.productProperty?.subcategory || '',
        inter_category: product.productProperty?.selectedVariant?.tracking?.categoryReference || '',
        productUUID: product.productProperty?.selectedVariant?.uuid!,
        url: product.productUrl,
        brand: brand,
        color: product.productProperty?.selectedVariant?.tracking?.variantColor,
        collection: product.productProperty?.selectedVariant?.tracking?.category,
        fs_color: product.productProperty?.selectedVariant?.tracking?.variantColor,
    }));

const useProductFromCache = () => {
    const queryClient = useQueryClient();

    return (search: { ean?: string; url?: string }) => {
        const wishlist: WishlistData | undefined = queryClient.getQueryData([WISHLIST_KEY], { exact: false });
        const alerts: AlertsData | undefined = queryClient.getQueryData([ALERTS_KEY], { exact: false });

        return (
            wishlist?.data.find(item => item.ean === search.ean || item.link === search.url) ||
            alerts?.data.find(item => item.ean === search.ean || item.link === search.url)
        );
    };
};

export const useAnalytics = () => {
    const environnement = analytics.getEnv();
    const { keycloakId, user } = useAuth();
    const currency = useCurrency();
    const {
        country: { countryISO },
    } = useCountry();
    const { site } = useAppContext();
    const getProduct = useProductFromCache();
    const getSiteName = (site: Site) => `${site.label?.toLowerCase()}_${currency!.label}`;

    const onPageChange = async (site: Site) => {
        try {
            const instance = await analytics.getInstance(segmentProps);

            instance.page(document.title, {
                event_id: analytics.getUniqueEventId('pageChange'),
                breadcrumb: document.title,
                site: getSiteName(site),
                siteBrand: brand,
                siteCurrency: site.defaultCurrency?.code,
                langue: site.defaultLocale?.code,
                environnement,
                platform: 'web',
                'type-contenu': document.title,
                'user-id': keycloakId,
                'adresse-email': user?.email ? await analytics.getSha256(user.email) : undefined,
                country: countryISO,
                subscriber_key: user?.salesforceId,
                fbp: Cookies.get('_fbp'),
            });
        } catch (e) {
            console.error(e);
        }
    };

    const onProductAddedToCart = async (ean: string) => {
        const instance = await analytics.getInstance(segmentProps);

        // Fetch product from react query
        const product = getProduct({ ean });
        if (!product) return;

        instance.track('Product Added', {
            event_id: analytics.getUniqueEventId('productAdded'),
            product_id: product.id,
            product_code: product.tracking?.product?.code,
            productUUID: product.tracking?.product?.uuid,
            sku: product.ean,
            category: product.tracking?.category?.label,
            country: countryISO,
            name: product.name,
            brand: product.tracking?.brand?.toLowerCase(),
            variant: product.color,
            price: product.displayPrice,
            quantity: 1,
            productStatus: product.status?.label,
            site: getSiteName(site),
            siteBrand: brand,
            siteCurrency: site.defaultCurrency?.code,
            langue: site.defaultLocale?.code,
            currency: currency?.code,
            environnement,
            platform: 'web',
            url: product.link,
            'event-type': 'Account',
            label: product.name,
            value: product.displayPrice,
            'adresse-email': user?.email ? await analytics.getSha256(user.email) : undefined,
            fbp: Cookies.get('_fbp'),
            cart_id: Cookies.get('SezaneCartUuid'),
            subscriber_key: user?.salesforceId,
            fs_color: product.color,
            color: product.color,
        });
    };

    const onProductClicked = async (url: string) => {
        const instance = await analytics.getInstance(segmentProps);

        // Fetch product from react query
        const product = getProduct({ url });
        if (!product) return;

        instance.track('Product Clicked', {
            event_id: analytics.getUniqueEventId('productClicked'),
            product_id: product.id,
            product_code: product.tracking?.product?.code,
            productUUID: product.tracking?.product?.uuid,
            sku: product.ean,
            category: product.tracking?.category?.label,
            country: countryISO,
            name: product.name,
            brand: product.tracking?.brand?.toLowerCase(),
            variant: product.color,
            price: product.displayPrice,
            productStatus: product.status?.label,
            site,
            siteBrand: brand,
            siteCurrency: site.defaultCurrency?.code,
            langue: site.defaultLocale?.code,
            currency: currency?.code,
            environnement,
            platform: 'web',
            url: product.link,
            fs_color: product.color,
            color: product.color,
        });
    };

    const onProductRemovedWishlist = async (ean: string) => {
        const instance = await analytics.getInstance(segmentProps);

        const product = getProduct({ ean });
        if (!product) return;

        instance.track('Product Removed from Wishlist', {
            event_id: analytics.getUniqueEventId('productRemovedWishlist'),
            product_id: product.id,
            product_code: product.tracking?.product?.code,
            productUUID: product.tracking?.product?.uuid,
            sku: product.ean,
            category: product.tracking?.category?.label,
            country: countryISO,
            name: product.name,
            brand: product.tracking?.brand?.toLowerCase(),
            variant: product.color,
            price: product.displayPrice,
            productStatus: product.status?.label,
            site,
            siteBrand: brand,
            siteCurrency: site.defaultCurrency?.code,
            langue: site.defaultLocale?.code,
            currency: currency?.code,
            environnement,
            platform: 'web',
            url: product.link,
            fs_color: product.color,
            color: product.color,
        });
    };

    const onOrderRefunded = async (orders: OrderRead[]) => {
        const instance = await analytics.getInstance(segmentProps);
        const order = orders[0];
        if (!order) return;

        instance.track('Order Refunded', {
            event_id: analytics.getUniqueEventId('orderRefunded'),
            order_id: order.number,
            total: order.totalDisplayPrice,
            currency: order.currencyCode,
            country: countryISO,
            site,
            siteBrand: brand,
            siteCurrency: site.defaultCurrency?.code,
            langue: site.defaultLocale?.code,
            environnement,
            platform: 'web',
            'type-client': getClientType(user!),
            productValue: getProductValue(order),
            products: getProductsForSegment(order, brand),
        });
    };

    const interceptProductClick = async () => {
        document.body.addEventListener('click', function (event) {
            const target: HTMLAnchorElement | null = (event.target as HTMLElement)?.closest('a[href]');
            if (!target) return;

            if (target.href.includes('/product/')) {
                onProductClicked(new URL(target.href).pathname);
            }
        });
    };

    return { onPageChange, onProductAddedToCart, onProductRemovedWishlist, onOrderRefunded, interceptProductClick };
};
