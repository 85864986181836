import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';

import type { OMS } from 'types';

interface OrderCustomisationProps {
    className?: string;
    product: OMS.OrderProduct;
}

const OrderCustomisation = ({ className, product }: OrderCustomisationProps) => {
    return !!product.customisation?.rows?.length ? (
        <span className={clsx('text-gray-medium', className)}>
            <FormattedMessage id="orders.order.detail.body.customisation" />{' '}
            <i>{product.customisation.rows?.join(' ')}</i>
            {product.customisation.color && (
                <>
                    {' / '}
                    <FormattedMessage id="orders.order.detail.body.customisation_color" />{' '}
                    <i>{product.customisation.color}</i>
                </>
            )}
        </span>
    ) : null;
};

export default OrderCustomisation;
