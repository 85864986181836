import type { ButtonHTMLAttributes } from 'react';

import LoaderWrapper from '@components/Loader/LoaderWrapper';
import { type ColorType, defaultBlockButtonStyles, defaultFloatingButtonStyles, variantStyles } from '@utils/button';
import { cn } from '@utils/main';

export interface ButtonPropsTypes extends ButtonHTMLAttributes<HTMLButtonElement> {
    color?: ColorType;
    isLoading?: boolean;
    floating?: boolean;
    fullWidth?: boolean;
}

const Button = ({
    children,
    className,
    color = 'primary',
    isLoading = false,
    disabled,
    fullWidth,
    floating = false,
    ...props
}: ButtonPropsTypes) => {
    const colorClassNames = variantStyles[color];

    return (
        <button
            className={cn(
                'box-border inline-block text-base ',
                colorClassNames,
                !floating && defaultBlockButtonStyles,
                floating && defaultFloatingButtonStyles,
                fullWidth && 'w-full',
                className
            )}
            disabled={disabled || isLoading}
            {...props}
        >
            <LoaderWrapper isLoading={isLoading} loaderProps={{ size: 22.5 }}>
                {children}
            </LoaderWrapper>
        </button>
    );
};

export default Button;
