import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dialog, Radio, RadioGroup } from '@sezane/front-components';

import Button from '@components/Button/Button';
import { useAppContext } from '@context/AppContext';
import { useAuth } from '@context/AuthContext';
import apiOms from '@utils/apiOms';

type FormValues = { consent: 'yes' | 'no' };

const NewsletterSignup = () => {
    const { user } = useAuth();
    const { brandCode, locale, site } = useAppContext();
    const intl = useIntl();
    const [open, setOpen] = useState(false);
    const media = {
        sezane: [
            'https://media.sezane.com/v1723125123/website/assets/visuels/socialsignup.jpg',
            'https://media.sezane.com/image/upload/c_thumb,g_auto,h_240,w_650,z_3/v1723125123/website/assets/visuels/socialsignup.jpg',
            'https://media.sezane.com/image/upload/w_1000/v1723125123/website/assets/visuels/socialsignup.jpg',
        ],
        octobre: [
            'https://media.octobre-editions.com/image/upload/v1728565100/website/assets/visuels/socialsignup.jpg',
            'https://media.octobre-editions.com/image/upload/c_thumb,g_auto,h_240,w_650,z_3/v1728565100/website/assets/visuels/socialsignup.jpg',
            'https://media.octobre-editions.com/image/upload/w_1000/v1728565100/website/assets/visuels/socialsignup.jpg',
        ],
    };
    const {
        handleSubmit,
        setValue,
        formState: { isSubmitting },
    } = useForm<FormValues>({ defaultValues: { consent: 'yes' } });

    const onClose = () => {
        setOpen(false);
    };

    const onSave = async (data: FormValues) => {
        const consent = data.consent === 'yes';

        await apiOms.omsAuthenticated.notificationsUpdate(
            {
                brandCode,
                localeCode: locale,
                siteCode: site.code!,
            },
            { newsletter: [{ marketingBrandCode: brandCode, status: consent }] }
        );

        onClose();
    };

    useEffect(() => {
        if (user?.subscribedToInvitationsEmails === null) {
            setOpen(true);
        }
    }, [user]);

    return (
        open && (
            <Dialog mandatory={true} open={true} onClose={onClose} panelClassName="max-w-[68rem] p-0">
                <div className="flex w-full flex-wrap bg-white">
                    <div className="w-1/2 mobile:w-full">
                        <img
                            src={media[brandCode][0]}
                            srcSet={`${media[brandCode][1]} 200w, ${media[brandCode][2]} 1000w`}
                            sizes="(max-width: 767px) 200px, 1000px"
                            alt="Newsletter Signup"
                            className="h-full w-full object-cover object-top mobile:max-h-[24rem]"
                        />
                    </div>
                    <form
                        className="relative flex w-1/2 flex-col justify-center p-8 mobile:w-full"
                        onSubmit={handleSubmit(onSave)}
                    >
                        <div className="space-y-10 px-6">
                            <h2
                                className="mb-4 text-center text-[3.2rem] font-medium uppercase leading-none"
                                dangerouslySetInnerHTML={{
                                    __html: intl.formatMessage({ id: 'newsletter_signup.title' }),
                                }}
                            />
                            <p className="mb-6 text-center">
                                <FormattedMessage id="newsletter_signup.description" />
                            </p>

                            <div className="flex flex-row justify-center">
                                <RadioGroup
                                    name="consent"
                                    defaultValue="yes"
                                    direction="horizontal"
                                    className="flex flex-col"
                                    onChange={value => setValue('consent', value as FormValues['consent'])}
                                >
                                    <Radio
                                        value="yes"
                                        className="font-account-heading font-medium tracking-normal !text-black"
                                    >
                                        <FormattedMessage id="newsletter_signup.yes_others" />
                                    </Radio>
                                    <Radio
                                        value="no"
                                        className="font-account-heading font-medium tracking-normal !text-black"
                                    >
                                        <FormattedMessage id="newsletter_signup.no" />
                                    </Radio>
                                </RadioGroup>
                            </div>

                            <Button className="!mt-14 w-full" type="submit" isLoading={isSubmitting}>
                                <FormattedMessage id="newsletter_signup.submit" />
                            </Button>
                        </div>
                    </form>
                </div>
            </Dialog>
        )
    );
};

export default NewsletterSignup;
