import { type ReactNode } from 'react';

import { SecondTitle } from '@components/commons';
import IconReturnInfo from '@components/Icons/IconReturnInfo';

interface AdditionnalInfoReturnBoxProps {
    children: ReactNode;
    title: ReactNode;
}
const AdditionnalInfoReturnBox = ({ children, title }: AdditionnalInfoReturnBoxProps) => (
    <div className="flex w-full flex-col gap-8 rounded-[0.2rem] bg-gray-lightest p-8 desktop:flex-row desktop:items-center">
        <span className="flex h-14 w-14 shrink-0 items-center justify-center rounded-full bg-gray-lighter font-account-heading font-medium text-gray-medium">
            <IconReturnInfo className="w-[0.4rem] fill-gray-medium" aria-hidden />
        </span>
        <div className="flex w-full flex-col">
            <SecondTitle>{title}</SecondTitle>
            <span>{children}</span>
        </div>
    </div>
);

export default AdditionnalInfoReturnBox;
