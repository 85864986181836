import { type ChangeEvent, forwardRef } from 'react';
import clsx from 'clsx';
import { type AriaDateFieldProps, type DateValue, useDateField } from 'react-aria';
import { useDateFieldState } from 'react-stately';
import { createCalendar } from '@internationalized/date';

import { useAppContext } from '@context/AppContext';
import useForwardedRef from '@hooks/useForwardedRef';
import { DEFAULT_LOCALE } from '@utils/common';
import { DateSegmentField } from './DateSegmentField';
import './date-field.css';

interface DateFieldProps extends AriaDateFieldProps<DateValue> {
    value?: DateValue;
    defaultValue?: DateValue;
    onChange?: (event: DateValue | ChangeEvent<Element>) => void;
    inputClassName?: string;
}
const DateField = forwardRef<HTMLInputElement, DateFieldProps>(({ inputClassName, ...props }, ref) => {
    const {
        site: { defaultLocale },
    } = useAppContext();
    const state = useDateFieldState({
        ...props,
        locale: defaultLocale?.code || DEFAULT_LOCALE,
        createCalendar,
    });
    const innerRef = useForwardedRef(ref);
    const { labelProps, fieldProps, errorMessageProps } = useDateField({ ...props }, state, innerRef);

    return (
        <div className="date-field flex flex-col">
            <label {...labelProps} className="date-field__label">
                {props.label}
            </label>
            <div
                {...fieldProps}
                ref={ref}
                className="flex flex-row  border border-gray-lighter font-account-heading uppercase"
            >
                {state.segments.map((segment, i) => (
                    <DateSegmentField
                        key={i}
                        segment={segment}
                        state={state}
                        inputClass={clsx(
                            'date-field__input text-base outline-none ring-0 ring-black focus:ring-1 ',
                            segment.type === 'literal' && 'px-0',
                            inputClassName
                        )}
                    />
                ))}
            </div>
            {props.errorMessage && (
                <div
                    {...errorMessageProps}
                    className="font-account-body font-medium text-error before:empty:inline-block before:empty:content-['']"
                >
                    {props.errorMessage}
                </div>
            )}
        </div>
    );
});

export default DateField;
