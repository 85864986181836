import { Fragment } from 'react';
import { defineMessages, FormattedDate, FormattedMessage, FormattedTime, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { BoxWithTimeline } from '@components/Box/BoxWithTimeline';
import CancelOrderReturnButton from '@components/Button/CancelOrderReturnButton';
import LinkButton from '@components/Link/LinkButton';
import Separator from '@components/Separator';
import HorizontalTimeline from '@components/Timeline/HorizontalTimeline';
import { useUrl } from '@hooks/useUrl';
import { DEFAULT_IMG_PLACEHOLDER } from '@utils/common';
import { DEFAULT_IMAGE_HEIGHT, DEFAULT_IMAGE_WIDTH } from '@utils/image';
import { getCustomerReturnTrackingStatus, returnTimelineStatuses } from '@utils/order';
import { refundModeMessages } from '@utils/return';
import type { UserOrderReturn } from 'types/apiOms';

export const returnTrackingMessages = defineMessages({
    in_transit: { id: 'orderReturn.return.detail.tracking.in_transit' },
    validated: { id: 'orderReturn.return.detail.tracking.validated' },
    refunded: { id: 'orderReturn.return.detail.tracking.refunded' },
    canceled: { id: 'orderReturn.return.detail.tracking.canceled' },
});

const ReturnsListItem = ({ className, orderReturn }: { orderReturn: UserOrderReturn; className?: string }) => {
    const intl = useIntl();
    const { routes } = useUrl();

    const currentTrackingStatus =
        getCustomerReturnTrackingStatus(orderReturn.status) || orderReturn.status || 'canceled';

    const detailLink = `${routes.returns}/detail/${orderReturn.uid}`;

    return (
        <>
            <BoxWithTimeline
                testId={orderReturn.firstOrderReference}
                className={className}
                logo={
                    <Link to={detailLink}>
                        <img
                            src={orderReturn.imageUrl || DEFAULT_IMG_PLACEHOLDER}
                            width={DEFAULT_IMAGE_WIDTH}
                            height={DEFAULT_IMAGE_HEIGHT}
                            className="h-full object-cover"
                            alt={intl.formatMessage(
                                { id: 'orderReturns.return.label' },
                                { reference: orderReturn.firstOrderReference }
                            )}
                        />
                    </Link>
                }
                horizontalTimeline={
                    <HorizontalTimeline
                        className="col-[2_/_4_span] mt-8 self-end mobile:mt-2 tablet:col-span-full tablet:mt-12"
                        displayedTrackingStatus={returnTimelineStatuses.filter(status =>
                            orderReturn.returnModeType === 'store_return' ? status !== 'in_transit' : true
                        )}
                        currentTrackingStatus={currentTrackingStatus}
                        statusMessages={returnTrackingMessages}
                    />
                }
                buttons={
                    <>
                        <LinkButton className="m-0 mb-5 w-full px-4 tablet:mb-0" to={detailLink}>
                            <FormattedMessage id="orderReturns.return.detail" />
                        </LinkButton>
                        {orderReturn.uid && orderReturn?.status === 'pending' && (
                            <CancelOrderReturnButton
                                uid={orderReturn.uid}
                                className="m-0 mb-5 w-full px-4 tablet:mb-0"
                            />
                        )}
                        {orderReturn.returnTrackingLink &&
                            orderReturn.returnModeType === 'return_label' &&
                            orderReturn.status &&
                            orderReturn.status !== 'processed' && (
                                <LinkButton
                                    className="m-0 mb-5 w-full px-4 tablet:mb-0"
                                    href={orderReturn.returnTrackingLink}
                                    target="_blank"
                                >
                                    <FormattedMessage id="orderReturns.return.track" />
                                </LinkButton>
                            )}
                    </>
                }
            >
                <div className="flex">
                    <div className="flex grow flex-col gap-4 font-account-heading text-base uppercase ">
                        <div className="flex uppercase mobile:flex-col desktop:items-center">
                            <Link className="font-account-heading font-medium text-black desktop:mr-3" to={detailLink}>
                                <FormattedMessage
                                    id="orderReturns.return.label"
                                    values={{ reference: orderReturn.id }}
                                />
                            </Link>
                        </div>
                        <div className="-mt-5 font-medium">
                            {orderReturn.localeTotalRefundAmount || orderReturn.totalRefundAmount}
                        </div>
                        {orderReturn.orderReferences?.length === 1 ? (
                            <Link to={`${routes.orders}/detail/${orderReturn.firstOrderReference}`}>
                                <FormattedMessage
                                    id="orderReturns.return.reference"
                                    values={{ reference: orderReturn.firstOrderReference }}
                                />
                            </Link>
                        ) : (
                            <FormattedMessage
                                id="orderReturns.return.merged"
                                values={{
                                    references: orderReturn.orderReferences?.map((reference, index, references) => (
                                        <Fragment key={reference}>
                                            <Link to={`${routes.orders}/detail/${reference}`}>{reference}</Link>
                                            {index < references.length - 1 && ', '}
                                        </Fragment>
                                    )),
                                }}
                            />
                        )}
                        {orderReturn.refund_mode ? (
                            <FormattedMessage id={refundModeMessages[orderReturn.refund_mode].id} />
                        ) : null}
                    </div>
                    <div className="text-right font-account-subheading uppercase">
                        <div>
                            <FormattedDate value={orderReturn.createdAt} day="numeric" month="long" />
                        </div>
                        <div>
                            <FormattedTime value={orderReturn.createdAt} hour="numeric" minute="numeric" />
                        </div>
                        <div>
                            <FormattedMessage
                                id="orderReturns.return.article"
                                values={{ number: orderReturn.totalReturnItems }}
                            />
                        </div>
                    </div>
                </div>
            </BoxWithTimeline>
            <Separator mobileOnly />
        </>
    );
};

export default ReturnsListItem;
