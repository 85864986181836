import { FormattedMessage } from 'react-intl';

import ReturnOrderButton from '@components/Button/ReturnOrderButton';
import { PageHeader } from '@components/commons';
import LinkButton from '@components/Link/LinkButton';
import OrdersTabs from '@components/Tabs/OrdersTabs';
import { ReturnsProvider } from '@context/ReturnsContext';
import { useUrl } from '@hooks/useUrl';
import ReturnsList from './ReturnsList';

const ReturnsPage = () => {
    const { routes } = useUrl();

    return (
        <>
            <OrdersTabs />
            <PageHeader title="orderReturns.title">
                <FormattedMessage id="orderReturns.title" />
            </PageHeader>
            <p className="mt-8 py-4 text-center">
                {process.env.REACT_APP_RETURN_V2 === 'true' ? (
                    <LinkButton to={routes.returnCreate}>
                        <FormattedMessage id="orders.order.detail.action.return" />
                    </LinkButton>
                ) : (
                    <ReturnOrderButton step={1} color="primary" />
                )}
            </p>
            <ReturnsProvider>
                <ReturnsList />
            </ReturnsProvider>
        </>
    );
};

export default ReturnsPage;
