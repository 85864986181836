import type { SVGProps } from 'react';

const IconEdit = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={21} fill="none" className={props.className}>
        <path
            fillRule="evenodd"
            d="m9.372 15.193-4.125-.014.028-4.141 9.068-8.857c.705-.695 1.938-.69 2.64.021l1.472 1.493c.351.356.542.826.539 1.325-.003.5-.201.967-.557 1.318l-9.065 8.855zm7.755-10.188-1.475-1.49-1.5 1.466 1.472 1.492 1.503-1.468zm-4.312 1.281L7.139 11.83l-.01 1.486 1.484.005 5.674-5.542-1.472-1.493zm3.623 12.697-13.087-.09a1.871 1.871 0 0 1-1.856-1.883l.09-13.086a1.871 1.871 0 0 1 1.883-1.857l8.27.058L9.855 3.98l-6.4-.044-.09 13.086 2.784.02c.015 0 .03.002.044.004.017.003.033.005.05.005.011 0 .024-.002.036-.004a.2.2 0 0 1 .037-.005l10.135.07.043-6.232 1.882-1.857-.056 8.103a1.871 1.871 0 0 1-1.882 1.856z"
            clipRule="evenodd"
        />
    </svg>
);
export default IconEdit;
