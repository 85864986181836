import { useFormContext } from 'react-hook-form';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { useUrl } from '@hooks/useUrl';
import { type FormValues } from './utils';

export const refundOptionsMessages = defineMessages({
    card: { id: 'return.recap.refund_mode.card' },
    credits: { id: 'return.recap.refund_mode.credits' },
});

const ReturnRecap = () => {
    const { routes } = useUrl();

    const { watch } = useFormContext<FormValues>();
    const selectedOrder = watch('order');
    const products = watch('products');
    const refundOption = watch('refundOption');
    const productsList = Object.values(products || {});

    return (
        <div className="mt-14">
            {selectedOrder && (
                <div className="font-heading uppercase">
                    <div className="font-heading uppercase">
                        <FormattedMessage id="return.recap.order" />
                    </div>
                    <Link
                        className="font-bold underline"
                        to={`${routes.orders}/detail/${selectedOrder.number}`}
                        target="_blank"
                    >
                        N°{selectedOrder.number}
                    </Link>
                </div>
            )}
            {!!productsList.length && (
                <div className="mt-7 font-heading uppercase">
                    <div className="font-heading uppercase">
                        <FormattedMessage id="return.recap.products" />
                    </div>
                    <ul>
                        {productsList.map(({ product, reason }, index) => (
                            <li key={`${product.ean}-${index}`}>
                                <b>{product.label}</b>
                                {reason ? ` - ${reason.translation}` : null}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            {refundOption && (
                <div className="mt-7 font-heading uppercase">
                    <div className="font-heading uppercase">
                        <FormattedMessage id="return.recap.refund_mode" />
                    </div>
                    <b>
                        <FormattedMessage id={refundOptionsMessages[refundOption].id} />
                    </b>
                </div>
            )}
        </div>
    );
};

export default ReturnRecap;
