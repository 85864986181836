import { FormattedMessage } from 'react-intl';

import AdditionnalInfoReturnBox from '@components/Box/Returns/additionalInfos/AdditionnalInfoReturnBox';
import ReturnSection from '@components/Box/Returns/ReturnSection';
import IconPlace from '@components/Icons/IconPlace';
import { useUrl } from '@hooks/useUrl';
import { infoReturnBoxes, type ReturnBoxes } from './commons';

interface FreeReturnSectionProps {
    hasReturnLabel?: boolean;
    orderReference?: string;
    sectionCounter: boolean;
}

const freeReturnSchema: ReturnBoxes[] = ['packaging', 'free_return'];

export const FreeReturnSection = ({ hasReturnLabel, orderReference, sectionCounter }: FreeReturnSectionProps) => {
    const { urls } = useUrl();

    return (
        <ReturnSection
            title={<FormattedMessage id="orderReturn.return.detail.option.free_return" />}
            icon={IconPlace}
            counter={sectionCounter}
        >
            <AdditionnalInfoReturnBox
                title={<FormattedMessage id="orderReturn.return.detail.info.free_return.title" />}
            >
                <FormattedMessage
                    id="orderReturn.return.detail.info.free_return.description"
                    values={{
                        faq: (
                            <a className="underline" href={urls.faq.return_second} target="_blank" rel="noreferrer">
                                <FormattedMessage id="orderReturn.return.detail.faq" />
                            </a>
                        ),
                    }}
                />
            </AdditionnalInfoReturnBox>
            {freeReturnSchema.map(
                (box, index) =>
                    infoReturnBoxes({
                        boxIndex: index + 1,
                        orderReference,
                        hasReturnLabel,
                    })[box]
            )}
        </ReturnSection>
    );
};
