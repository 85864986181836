import { FormattedMessage } from 'react-intl';

import ReturnSection from '@components/Box/Returns/ReturnSection';
import IconPlace from '@components/Icons/IconPlace';
import { infoReturnBoxes, type ReturnBoxes } from './commons';

interface ReturnLabelProps {
    hasReturnLabel?: boolean;
    orderReference?: string;
    sectionCounter: boolean;
}

const returnSchneiderSchema: ReturnBoxes[] = ['packaging', 'return_label', 'schneider_info', 'dont_delay'];

export const ReturnSchneiderSection = ({ hasReturnLabel, orderReference, sectionCounter }: ReturnLabelProps) => (
    <ReturnSection
        title={<FormattedMessage id="orderReturn.return.detail.option.schneider" />}
        icon={IconPlace}
        counter={sectionCounter}
    >
        {returnSchneiderSchema.map(
            (box, index) =>
                infoReturnBoxes({
                    boxIndex: index + 1,
                    orderReference,
                    hasReturnLabel,
                })[box]
        )}
    </ReturnSection>
);
