import { FormattedMessage } from 'react-intl';
import { useMutation } from '@tanstack/react-query';

import apiOms from '@utils/apiOms';
import type { ButtonPropsTypes } from './Button';
import Button from './Button';

interface ReturnPoshmarkProps extends ButtonPropsTypes {
    orderNumber: string;
    lineItemUuid: string;
}

const ReturnPoshmark = ({
    orderNumber,
    lineItemUuid,
    className,
    color = 'secondary',
    ...props
}: ReturnPoshmarkProps) => {
    const { mutateAsync, isLoading } = useMutation(() =>
        apiOms.omsAuthenticated.markLineItemAsResellableOnPoshmark(orderNumber, lineItemUuid).then(response => {
            if (response.data.data?.url) {
                window.location.href = response.data.data?.url;
            } else {
                console.error('Failed to get Poshmark URL');
            }
        })
    );

    return (
        <Button color={color} className={className} onClick={() => mutateAsync()} isLoading={isLoading} {...props}>
            <FormattedMessage id="orders.order.detail.action.resell_on_poshmark" />
        </Button>
    );
};

export default ReturnPoshmark;
