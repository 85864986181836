import React, { useContext, useEffect, useState } from 'react';
import type { ReactNode } from 'react';
import { useQuery } from '@tanstack/react-query';

import Loader from '@components/Loader/Loader';
import apiOms from '@utils/apiOms';
import type { AuthData } from '@utils/auth';
import { getAuth } from '@utils/auth';
import { DEFAULT_SITECODE } from '@utils/common';
import { useAppContext } from './AppContext';
import type { User } from 'types/apiOms';

export type AuthContextType = {
    authenticated: boolean;
    keycloakId: string;
    user?: User;
    isAuthenticating: boolean;
};

type AuthProviderProps = {
    children?: ReactNode;
    initialAuthData?: {
        auth: AuthData;
        user: User;
    };
};

const AuthContext = React.createContext<AuthContextType>({
    authenticated: false,
    keycloakId: '',
    isAuthenticating: false,
});

export const AuthProvider = ({ children, initialAuthData }: AuthProviderProps) => {
    const { brandCode, site } = useAppContext();
    const [auth, setAuth] = useState<AuthData | undefined>(initialAuthData?.auth);
    const [isAuthenticating, setIsAuthenticating] = useState<boolean>(process.env.NODE_ENV !== 'test');

    useEffect(() => {
        (async () => {
            try {
                const authData = await getAuth();

                if (authData) {
                    setAuth(authData);
                } else {
                    setIsAuthenticating(false);
                }
            } catch (e) {
                setIsAuthenticating(false);
            }
        })();
    }, []);

    const keycloakId = auth?.parsedToken.sub || '';
    const authenticated = !!auth?.accessToken;

    const { data } = useQuery(
        ['user'],
        () =>
            apiOms.omsAuthenticated.getUser(keycloakId, {
                brandCode,
                siteCode: site.code || DEFAULT_SITECODE,
            }),
        {
            enabled: !!(keycloakId && auth?.parsedToken && site.code),
            onSettled: () => {
                if (process.env.NODE_ENV !== 'test') {
                    // Prevent rerender of context in test
                    setIsAuthenticating(false);
                }
            },
        }
    );

    const user = initialAuthData ? initialAuthData?.user : data?.data;

    if (isAuthenticating || !authenticated || !user) return <Loader fullPage />;

    return (
        <AuthContext.Provider
            value={{
                authenticated,
                keycloakId,
                user,
                isAuthenticating,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const authContext = useContext(AuthContext);

    return authContext;
};
