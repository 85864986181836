import { type ReactNode } from 'react';

import { cn } from '@utils/main';
import { Box } from './Box';

interface BoxWithTimelineProps {
    children: ReactNode;
    logo: ReactNode;
    horizontalTimeline: ReactNode;
    buttons: ReactNode;
    testId?: string | number | null;
    className?: string;
}

export const BoxWithTimeline = ({
    children,
    logo,
    horizontalTimeline,
    buttons,
    className,
    testId,
}: BoxWithTimelineProps) => (
    <Box
        grid
        border
        dense
        className={cn(
            className,
            'grid-cols-[1.5fr_repeat(6,minmax(0,1fr))] grid-rows-[1fr,_0.fr] gap-8 p-12 mobile:grid-cols-[1.2fr_repeat(3,minmax(0,1fr))] mobile:px-0 mobile:pb-8 mobile:pt-12 desktop:gap-y-2 desktop:p-12 large:grid-cols-7 tablet:grid-cols-7'
        )}
        data-testid={testId}
    >
        <div className="col-start-1 row-span-1 row-start-1 tablet:col-span-2">{logo}</div>
        <div className="col-[2_/_span_4] col-start-2 text-sm text-gray-medium mobile:col-[2_/_span_3] mobile:row-span-2 tablet:col-[3_/_span_5] tablet:col-start-3 tablet:row-start-1">
            {children}
        </div>
        {horizontalTimeline}
        <div className="col-[6_/_span_2] row-start-1 flex flex-col gap-5 pl-4 mobile:col-span-full mobile:col-start-1 mobile:row-start-4 mobile:mb-4 mobile:pl-0 tablet:col-span-full tablet:col-start-1 tablet:row-start-3 tablet:px-0 tablet:pt-6">
            {buttons}
        </div>
    </Box>
);
