import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { formatPostCodeAndCity } from '@sezane/front-components';

import { SecondTitle } from '@components/commons';
import InfoReturnBox from './InfoReturnBox';
import type { OptionalPhysicalAddress } from 'types/apiOms';

interface PickupBoxProps {
    boxIndex: number;
    pickupAddress: OptionalPhysicalAddress;
    pickupDateStart: string;
    pickupDateEnd: string;
}

const pickupDateFormatted = (pickupDateStart: string, pickupDateEnd: string) => ({
    date: <FormattedDate day="numeric" month="numeric" value={pickupDateStart} />,
    time: <FormattedTime value={pickupDateStart} hour="numeric" minute="numeric" />,
    timeEnd: <FormattedTime value={pickupDateEnd} hour="numeric" minute="numeric" />,
});

const PickupBox = ({ boxIndex, pickupAddress, pickupDateStart, pickupDateEnd }: PickupBoxProps) => {
    return (
        <InfoReturnBox boxIndex={boxIndex}>
            <SecondTitle
                description={
                    <>
                        <p>
                            <FormattedMessage id="orderReturn.return.detail.dhl.appointment.description" />
                        </p>
                        <p>
                            <FormattedMessage
                                id="orderReturn.return.detail.pickup.description"
                                values={{
                                    ...pickupDateFormatted(pickupDateStart, pickupDateEnd),
                                }}
                            />
                        </p>
                        <p>{pickupAddress?.address}</p>
                        {pickupAddress?.addressMore && <p>{pickupAddress?.addressMore}</p>}
                        <p>
                            {formatPostCodeAndCity({
                                countryCode: pickupAddress?.countryCode,
                                city: pickupAddress?.city,
                                postcode: pickupAddress.postcode!,
                            })}
                        </p>
                    </>
                }
            >
                <FormattedMessage id="orderReturn.return.detail.pickup.title" />
            </SecondTitle>
        </InfoReturnBox>
    );
};

export default PickupBox;
