import { type ReactNode } from 'react';

import DepositBox from '@components/Box/Returns/Deposit';
import DhlConfirmBox from '@components/Box/Returns/DhlConfirmBox';
import DontDelay from '@components/Box/Returns/DontDelay';
import FreeReturnBox from '@components/Box/Returns/FreeReturnBox';
import PackagingBox from '@components/Box/Returns/Packaging';
import PickupBox from '@components/Box/Returns/Pickup';
import ReturnLabel from '@components/Box/Returns/ReturnLabel';
import SchneiderInfo from '@components/Box/Returns/SchneiderInfoBox';
import { type OptionalPhysicalAddress } from 'types/apiOms';

export type ReturnBoxes =
    | 'packaging'
    | 'return_label'
    | 'dont_delay'
    | 'deposit'
    | 'pickup'
    | 'schneider_info'
    | 'free_return'
    | 'dhl_confirm';

interface InfoReturnBoxProps {
    boxIndex: number;
    countryISO?: string | null;
    hasReturnLabel?: boolean;
    orderReference?: string;
    pickupAddress?: OptionalPhysicalAddress;
    pickupDateEnd?: string | null;
    pickupDateStart?: string | null;
}

export const infoReturnBoxes = ({
    boxIndex,
    countryISO,
    hasReturnLabel,
    orderReference,
    pickupAddress,
    pickupDateEnd,
    pickupDateStart,
}: InfoReturnBoxProps): { [key in ReturnBoxes]: ReactNode } => ({
    packaging: <PackagingBox boxIndex={boxIndex} key={`inforeturnbox${boxIndex}`} />,
    return_label: (
        <ReturnLabel
            key={`inforeturnbox${boxIndex}`}
            boxIndex={boxIndex}
            hasReturnLabel={hasReturnLabel}
            orderReference={orderReference}
        />
    ),
    dont_delay: <DontDelay boxIndex={boxIndex} key={`inforeturnbox${boxIndex}`} />,
    deposit: <DepositBox boxIndex={boxIndex} key={`inforeturnbox${boxIndex}`} countryISO={countryISO} />,
    pickup: pickupDateStart && pickupDateEnd && pickupAddress && (
        <PickupBox
            boxIndex={boxIndex}
            key={`inforeturnbox${boxIndex}`}
            pickupAddress={pickupAddress}
            pickupDateStart={pickupDateStart}
            pickupDateEnd={pickupDateEnd}
        />
    ),
    schneider_info: <SchneiderInfo boxIndex={boxIndex} key={`inforeturnbox${boxIndex}`} />,
    free_return: <FreeReturnBox boxIndex={boxIndex} key={`inforeturnbox${boxIndex}`} />,
    dhl_confirm: <DhlConfirmBox boxIndex={boxIndex} key={`inforeturnbox${boxIndex}`} />,
});
