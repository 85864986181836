import type { SVGProps } from 'react';

const IconShop = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={29} height={23} fill="none" {...props}>
        <path
            fill="#000"
            d="M.75 8.74c0-.26.04-.51.17-.77l3.35-6.1A2 2 0 0 1 6.07.75h16.8a2 2 0 0 1 1.81 1.12l3.36 6.1c.12.26.21.51.21.77 0 .9-.77 1.63-1.68 1.63H25.5v11.7a.7.7 0 0 1-.69.68.68.68 0 0 1-.68-.69V10.38H4.88v5.5h11v-3.44c0-.35.3-.69.68-.69.35 0 .69.34.69.69v8.25c0 1.16-.95 2.06-2.06 2.06H5.56c-1.16 0-2.06-.9-2.06-2.06V10.37H2.38c-.9 0-1.63-.73-1.63-1.63ZM26.57 9c.18 0 .3-.09.3-.26 0-.04-.04-.08-.04-.13l-3.35-6.1c-.13-.21-.34-.38-.6-.38H6.08c-.26 0-.47.17-.6.38l-3.36 6.1v.13c0 .17.1.26.26.26h24.2Zm-21 12.38h9.62a.7.7 0 0 0 .69-.7v-3.43h-11v3.44c0 .38.3.68.68.68Z"
        />
    </svg>
);

export default IconShop;
