import { useEffect } from 'react';
import fileDownload from 'js-file-download';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@tanstack/react-query';

import Button from '@components/Button/Button';
import { SecondTitle } from '@components/commons';
import apiOms from '@utils/apiOms';
import InfoReturnBox from './InfoReturnBox';

interface ReturnLabelProps {
    boxIndex: number;
    hasReturnLabel?: boolean;
    orderReference?: string;
}
const ReturnLabel = ({ boxIndex, hasReturnLabel, orderReference }: ReturnLabelProps) => {
    const {
        isFetching: isFetchingReturnLabel,
        refetch: getReturnLabel,
        isSuccess,
        data,
    } = useQuery({
        queryKey: ['returnLabel', orderReference],
        queryFn: () => apiOms.omsAuthenticated.getOrderReturnLabelFromOrder(orderReference || ''),
        enabled: false,
    });

    useEffect(() => {
        const getBlob = async () => {
            if (data) {
                const blob = await data.blob();
                try {
                    fileDownload(blob, `label-${orderReference}.pdf`);
                } catch (error) {
                    console.error(error);
                }
            }
        };
        if (isSuccess && data) {
            getBlob();
        }
    }, [data, isSuccess, orderReference]);

    return (
        <InfoReturnBox boxIndex={boxIndex}>
            <SecondTitle
                description={
                    <FormattedMessage
                        id="orderReturn.return.detail.return_label.description"
                        values={{ br: <br />, i: chunks => <i>{chunks}</i> }}
                    />
                }
            >
                <FormattedMessage id="orderReturn.return.detail.return_label.title" />
            </SecondTitle>

            {hasReturnLabel ? (
                <Button
                    className="mt-8"
                    color="primary"
                    onClick={() => {
                        getReturnLabel();
                    }}
                    isLoading={isFetchingReturnLabel}
                >
                    <FormattedMessage id="orderReturn.return.detail.return_label.cta" />
                </Button>
            ) : null}
        </InfoReturnBox>
    );
};

export default ReturnLabel;
