import { isRouteErrorResponse, useRouteError } from "react-router";

const ErrorBoundary = () => {
    let error = useRouteError();
    console.error(error);

    if (isRouteErrorResponse(error)) {
        return (
            <>
                <div>{error.status}</div>
                <div>{error?.data}</div>
            </>
        );
    }

    return <p role="alert" className="p-4">Oops ! Sorry, we encountered a technical problem. Please reload the page or try again later.</p>;
};

export default ErrorBoundary