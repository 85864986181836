import { useId } from 'react';

const MastercardLogo = ({ title }: { title: string }) => {
    const id = useId();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={id}
            role="img"
            width="40"
            height="41"
            viewBox="0 150 1000 618"
        >
            <title id={id}>{title}</title>
            <path fill="#EB001B" d="M308 0a309 309 0 102 0z"></path>
            <path fill="#F79E1B" d="M690 0a309 309 0 102 0z"></path>
            <path fill="#FF5F00" d="M500 66a309 309 0 000 486 309 309 0 000-486"></path>
        </svg>
    );
};

export default MastercardLogo;
