import { FormattedMessage } from 'react-intl';

import AdditionnalInfoReturnBox from './AdditionnalInfoReturnBox';

const EcardInfo = () => (
    <AdditionnalInfoReturnBox title={<FormattedMessage id="orderReturn.return.detail.info.ecard.title" />}>
        <FormattedMessage id="orderReturn.return.detail.info.ecard.description" />
    </AdditionnalInfoReturnBox>
);

export default EcardInfo;
