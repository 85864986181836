import { defineMessages, FormattedMessage } from 'react-intl';

import type { Urls } from '@hooks/useUrl';
import { useUrl } from '@hooks/useUrl';
import LinkButton from './Link/LinkButton';

type FaqKey = keyof Urls['faq'];

interface FaqProps {
    faqLinks?: FaqKey[];
    returns?: boolean;
}

const faqLinksMessages = defineMessages({
    faq_return: { id: 'faq.link.return' },
    faq_help: { id: 'faq.link.help' },
    returns: { id: 'faq.link.returns' },
    return_conditions: { id: 'faq.link.return_conditions' },
    orders: { id: 'faq.link.orders' },
    refund: { id: 'faq.link.refund' },
    fabrication: { id: 'faq.link.fabrication' },
    shipping_conditions: { id: 'faq.link.shipping_conditions' },
    exchange: { id: 'faq.link.exchange' },
    refund_delay: { id: 'faq.link.refund_delay' },
    defect: { id: 'faq.link.defect' },
});

const FaqItem = ({ name, url }: { name: FaqKey; url: string }) => (
    <li>
        <a href={url} className="border-b hover:border-b-2">
            <FormattedMessage id={faqLinksMessages[name].id} />
        </a>
    </li>
);

export default function Faq({ faqLinks, returns = false }: FaqProps) {
    const { urls } = useUrl();
    return (
        <>
            {!!faqLinks?.length && (
                <ul className="mt-6 flex flex-col gap-3">
                    {faqLinks.map(faqName => (
                        <FaqItem key={faqName} url={urls.faq[faqName]} name={faqName} />
                    ))}
                </ul>
            )}
            <LinkButton
                className="mt-12 px-4"
                fullWidth
                color="secondary"
                href={returns ? urls.faq.return_refunds : urls.faq.root}
            >
                <FormattedMessage id={returns ? 'faq.link.return' : 'faq.link.help'} />
            </LinkButton>
        </>
    );
}
