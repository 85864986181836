import { FormattedMessage } from 'react-intl';

import AdditionnalInfoReturnBox from './AdditionnalInfoReturnBox';

const GiftsInfo = () => (
    <AdditionnalInfoReturnBox title={<FormattedMessage id="orderReturn.return.detail.info.gifts.title" />}>
        <FormattedMessage id="orderReturn.return.detail.info.gifts.description" />
    </AdditionnalInfoReturnBox>
);

export default GiftsInfo;
