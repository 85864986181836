export enum BRANDS {
    sezane = 'sezane',
    octobre = 'octobre',
}

export const getBrand = () => (window.location.hostname.includes('octobre') ? BRANDS.octobre : BRANDS.sezane);

export const getFrontUrl = () => {
    const frontUrl =
        getBrand() === BRANDS.octobre
            ? process.env.REACT_APP_OCTOBRE_FRONT_URL
            : process.env.REACT_APP_SEZANE_FRONT_URL;
    return frontUrl || '';
};
