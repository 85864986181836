import { clsx } from 'clsx';
import { matchPath, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

interface MenuMobileItemProps {
    label: string;
    link?: string;
    onClick?: () => void;
    className?: string;
}

const MenuMobileItem = ({ label, link, onClick, className }: MenuMobileItemProps) => {
    const { pathname } = useLocation();
    const selected = !!link && matchPath(pathname, link);

    return (
        <li
            className={clsx(
                'mb-4 text-xl transition-colors last:mb-0',
                selected || !link ? 'text-black' : 'text-gray-light',
                className
            )}
        >
            {link ? (
                <Link className="block" to={link} onClick={onClick}>
                    {label}
                </Link>
            ) : (
                <div className="cursor-pointer" onClick={onClick}>
                    {label}
                </div>
            )}
        </li>
    );
};

export default MenuMobileItem;
