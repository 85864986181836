import type { ClassValue } from 'clsx';
import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

// Wrapper to resolve class conflicts
export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export const replaceToMultilangUrl = (siteCode: string) => {
    let pathname = window.location.pathname;
    let pathnameSplitted = pathname.split('/');

    if (
        pathname === '/' ||
        pathname.match(new RegExp(`/${siteCode}/`)) ||
        pathnameSplitted[pathnameSplitted.length - 1] === siteCode
    ) {
        return true;
    }
    return false;
};

export const SEZANE_HEADER_URL = 'https://media.sezane.com/image/upload/evergreen_media/account_sezane.jpg';
export const OCTOBRE_HEADER_URL = 'https://media.octobre-editions.com/image/upload/evergreen_media/account_octobre.jpg';

export const sezaneCSS = `
    :root {
        --account-body: "Baskerville";
        --account-heading: "Oswald";
        --account-subheading: "Oswald";
        --ecard-url: url("https://media.sezane.com/image/upload/v1696579529/evergreen_media/ecard_sezane.jpg");
        --button-font-weight: 600;
    }`;
export const octobreCSS = `
    :root {
        --account-body: "Graphik";
        --account-heading: "Domaine Display";
        --account-subheading: "GT America";
        --ecard-url: url("https://media.octobre-editions.com/image/upload/v1704442447/evergreen_media/ecard_octobre.jpg");
        --button-font-weight: 400;
    }`;
