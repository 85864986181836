import { useQuery } from '@tanstack/react-query';

import apiOms from '@utils/apiOms';
import type { BRANDS } from '@utils/brand';

export interface UseOrderHook {
    keycloakId: string;
    currentPage: number;
    brandCode: BRANDS;
    dateFrom?: string;
    dateTo?: string;
}

export const ORDERS_ITEMS_PER_PAGE = 15;
const ORDERS_KEY = 'orders';

export const useOrders = ({ brandCode, currentPage, keycloakId, dateFrom, dateTo }: UseOrderHook) =>
    useQuery(
        [
            ORDERS_KEY,
            `${
                dateFrom || dateTo
                    ? `${currentPage}-${ORDERS_ITEMS_PER_PAGE}-${dateFrom}-${dateTo}`
                    : `${currentPage}-${ORDERS_ITEMS_PER_PAGE}`
            }`,
        ],
        () =>
            apiOms.omsAuthenticated.listUserOrdersAuthenticated({
                brandCode,
                page: currentPage,
                dateFrom,
                dateTo,
                itemsPerPage: ORDERS_ITEMS_PER_PAGE,
                // @ts-expect-error
                'order[createdAt]': 'DESC',
            }),
        {
            enabled: !!keycloakId && !!brandCode,
            keepPreviousData: true,
        }
    );
