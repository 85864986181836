import clsx from 'clsx';

export const variantStyles = {
    primary: clsx(
        'border border-black bg-black text-white hover:bg-hover-black active:border-active-black active:bg-active-black disabled:border-gray-light disabled:bg-gray-light disabled:hover:border-gray-light disabled:hover:bg-gray-light'
    ),
    secondary: clsx(
        'border border-gray-lighter bg-white hover:border-black  focus:border-black active:border-black disabled:hover:bg-current'
    ),
    tertiary: clsx('text-gray-light underline-offset-4 hover:underline'),
    quartenary: clsx(
        'border border-black transition-all hover:bg-black hover:text-white focus:border-black active:border-black disabled:hover:bg-current'
    ),
    invisible: 'hover:text-gray-light',
    alert: clsx(
        'border border-alert bg-alert text-white active:border-active-black active:bg-active-black disabled:border-gray-light disabled:bg-gray-light disabled:hover:border-gray-light disabled:hover:bg-gray-light'
    ),
    danger: 'text-error border-error border transition-colors',
} as const;

export type ColorType = keyof typeof variantStyles;

export const defaultBlockButtonStyles = clsx(
    'transition-color px-16 py-5 font-account-heading text-lg uppercase leading-9 duration-300'
);

export const defaultFloatingButtonStyles = clsx(
    'transition-color px-3 py-5 font-account-heading text-lg uppercase text-white duration-300'
);
