import { type ReactNode } from 'react';
import clsx from 'clsx';

import IconMarker from '@components/Icons/IconMarker';
import { cn } from '@utils/main';

interface MapMarkerProps {
    icon?: ReactNode;
    iconClassname?: string;
    lat: number;
    lng: number;
    onClick?: () => void;
    renderTooltip?: () => ReactNode;
    selected?: boolean;
}

const MapMarker = ({ icon, iconClassname, onClick, renderTooltip, selected }: MapMarkerProps) => (
    <div
        className={clsx('relative h-8 w-8 cursor-pointer', {
            'text-black': selected,
            'text-white': !selected,
        })}
    >
        <i
            className={cn(iconClassname)}
            onClick={event => {
                event.stopPropagation(); // to prevent Google map triggers
                onClick && onClick();
            }}
        >
            {icon ? icon : <IconMarker className="h-8 w-auto fill-current stroke-current stroke-0" />}
        </i>
        {selected && renderTooltip && renderTooltip()}
    </div>
);

export default MapMarker;
