import { cn } from '@utils/main';

interface SeparatorProps {
    mobileOnly?: boolean;
    className?: string;
}
export const Separator = ({ mobileOnly = false, className }: SeparatorProps) => (
    <hr className={cn('mx-[-1rem] text-gray-lighter', { 'desktop:hidden': mobileOnly }, className)} />
);

export default Separator;
