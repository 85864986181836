import { useId } from 'react';

const MaestroLogo = ({ title }: { title: string }) => {
    const id = useId();

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            role="img"
            aria-labelledby={id}
            width="40"
            height="41"
            viewBox="0 10 200 155.6"
        >
            <title id={id}>{title}</title>
            <path
                fill="#00A2E5"
                d="M198.1 100.1v-2.4h-.6l-.7 1.6-.7-1.6h-.6v2.4h.4v-1.8l.7 1.6h.5l.7-1.6v1.8h.3zm-4 0v-2h.8v-.4h-2v.4h.8v2h.4z"
            ></path>
            <path fill="#7375CF" d="M127 110.4H72.9V13.2H127v97.2z"></path>
            <path
                fill="#EB001B"
                d="M76.4 61.8c0-19.7 9.2-37.3 23.6-48.6C89.5 4.9 76.2 0 61.8 0 27.7 0 0 27.7 0 61.8s27.7 61.8 61.8 61.8c14.4 0 27.7-4.9 38.2-13.2-14.4-11.3-23.6-28.9-23.6-48.6"
            ></path>
            <path
                fill="#00A2E5"
                d="M200 61.8c0 34.1-27.7 61.8-61.8 61.8-14.4 0-27.7-4.9-38.2-13.2 14.4-11.3 23.6-28.9 23.6-48.6s-9.2-37.3-23.6-48.6C110.5 4.9 123.8 0 138.2 0 172.3 0 200 27.7 200 61.8"
            ></path>
        </svg>
    );
};

export default MaestroLogo;
