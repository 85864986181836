import { defineMessages } from 'react-intl';
import * as yup from 'yup';

const validationMessages = defineMessages({
    email: { id: 'validation.credits_ecards.ecards.email' },
    code: { id: 'validation.credits_ecards.ecards.code' },
});

const ecardInfo = yup.object().shape({
    email: yup.string().email(validationMessages.email.id).required(validationMessages.email.id),
    code: yup
        .string()
        .required(validationMessages.code.id)
        .matches(/\w-\w{5}-\w{5}/, { message: validationMessages.code.id }),
});

export default ecardInfo;
