import { defineMessages } from 'react-intl';

export const refundModeMessages = defineMessages({
    credit: { id: 'orderReturn.refunds.type.credit' },
    credits: { id: 'orderReturn.refunds.type.credits' },
    cash: { id: 'orderReturn.refunds.type.cash' },
    adyen: { id: 'orderReturn.refunds.type.adyen' },
    card: { id: 'orderReturn.refunds.type.card' },
    'global-e': { id: 'orderReturn.refunds.type.globale' },
    customer_service: { id: 'orderReturn.refunds.type.customer_service' },
    ecard: { id: 'orderReturn.refunds.type.ecard' },
});

export const returnModeTypes = ['dhl_express', 'free', 'return_label', 'schneider', 'special', 'store_return'] as const;

export type ReturnModeType = (typeof returnModeTypes)[number];

export const currentTrackingStatus = ['validated', 'in_transit', 'refunded', 'canceled'] as const;

export type CurrentTrackingStatus = (typeof currentTrackingStatus)[number];
