import { useAppContext } from '@context/AppContext';
import { useCountry } from '@context/CountryContext';

export default function useCurrency() {
    const { site } = useAppContext();
    const { country } = useCountry();
    const defaultCurrency = site.defaultCurrency;

    if (country) {
        const siteCurrency = site.availableCurrencies?.find(currency => currency.code === country.currencyCode);

        return siteCurrency ? siteCurrency : defaultCurrency;
    }

    return defaultCurrency;
}
