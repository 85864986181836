import { useEffect } from 'react';
import clsx from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Dropdown, getCountryName, UpdateAddress } from '@sezane/front-components';
import { useQuery } from '@tanstack/react-query';

import Loader from '@components/Loader/Loader';
import { useAppContext } from '@context/AppContext';
import apiOms from '@utils/apiOms';
import Step from './Step';
import type { FormValues, Timezone } from './utils';
import { getCreateReturnSteps } from './utils';

interface StepDhlPickupProps {
    onChange: () => void;
}

const StepDhlPickup = ({ onChange }: StepDhlPickupProps) => {
    const { locale } = useAppContext();

    const { formState, setValue, watch } = useFormContext<FormValues>();
    const selectedOrder = watch('order');
    const address = watch('address');

    const { data: timezonesResponse, isFetching: isLoadingTimezones } = useQuery(['getTimezones'], () =>
        apiOms.omsAuthenticated.getOrderReturnDhlTimezoneRequiredCountriesTimezones()
    );
    const timezones = timezonesResponse?.data.timezones;
    const countryTimezones =
        timezones && address?.countryCode ? timezones[address.countryCode.split('-')[0]] : undefined;
    const timezoneError = formState.errors.timezone;

    useEffect(() => {
        const defaultAddress = selectedOrder?.addresses?.shipping;
        if (defaultAddress) {
            setValue('address', {
                ...defaultAddress,
                // @ts-expect-error
                id: defaultAddress.id || 0,
                company: defaultAddress.company || '',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOrder]);

    useEffect(() => {
        if (countryTimezones) {
            setValue('withTimezone', true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryTimezones]);

    useEffect(() => {
        onChange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(address)]);

    return (
        <Step
            id={getCreateReturnSteps(true)[3]}
            className="mt-24 pt-6"
            title={<FormattedMessage id="return.step_dhl_pickup.title" />}
            description={<FormattedMessage id="return.step_dhl_pickup.description" />}
        >
            {isLoadingTimezones ? (
                <Loader className="mt-10" />
            ) : (
                <>
                    <UpdateAddress<FormValues>
                        className="mt-10"
                        country={{
                            countryISO: address?.countryCode || '',
                            label: address?.countryCode
                                ? // TODO: remove this when we get address.country from API
                                  getCountryName(address.countryCode.split('-')[0], locale)
                                : '',
                        }}
                    />
                    {countryTimezones && (
                        <Controller
                            name="timezone"
                            render={({ field, fieldState }) => (
                                <Dropdown<Timezone>
                                    {...field}
                                    {...fieldState}
                                    id="timezone"
                                    className={clsx('mt-7', timezoneError && 'border-error text-error')}
                                    items={Object.keys(countryTimezones).map(timezone => ({
                                        id: timezone,
                                        value: countryTimezones[timezone],
                                    }))}
                                    defaultLabel={<FormattedMessage id="return.step_dhl_pickup.timezone" />}
                                    renderItem={({ id, value }) => `(${id}) ${value}`}
                                    onChange={value => {
                                        field.onChange(value);
                                        onChange();
                                    }}
                                />
                            )}
                        />
                    )}
                </>
            )}
        </Step>
    );
};

export default StepDhlPickup;
