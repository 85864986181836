import { forwardRef } from 'react';
import type { AriaTextFieldProps } from 'react-aria';
import { useTextField } from 'react-aria';

import useForwardedRef from '@hooks/useForwardedRef';
import './text-field.css';

export const TextField = forwardRef<HTMLInputElement, AriaTextFieldProps>(({ type = 'text', ...props }, ref) => {
    const { label, isRequired } = props;
    const innerRef = useForwardedRef(ref);
    const { labelProps, inputProps, descriptionProps, errorMessageProps } = useTextField({ type, ...props }, innerRef);

    function onFocusInput(e: React.FocusEvent<HTMLElement>) {
        const input = e.currentTarget as HTMLInputElement;

        if (input.parentElement) {
            if (input.parentElement.classList.contains('is-empty')) {
                input.parentElement.classList.remove('is-empty');
            }
        }
    }

    function onBlurInput(e: React.FocusEvent<HTMLElement>) {
        const input = e.currentTarget as HTMLInputElement;

        if (input.value === '' && input.parentElement) {
            input.parentElement.classList.add('is-empty');
        }
    }

    return (
        <div className="textfield flex grow flex-col gap-5">
            <label {...labelProps}>
                {label}
                {isRequired ? '*' : null}
            </label>
            <input
                {...inputProps}
                className="border border-gray-lighter font-account-heading uppercase outline-none ring-0 ring-black focus:ring-1"
                onFocus={onFocusInput}
                onBlur={onBlurInput}
                ref={innerRef}
            />
            {props.description && <div {...descriptionProps}>{props.description}</div>}
            {props.errorMessage && (
                <div
                    {...errorMessageProps}
                    className="pl-2 font-account-body text-xs font-medium text-error before:empty:inline-block before:empty:content-['']"
                >
                    {props.errorMessage}
                </div>
            )}
        </div>
    );
});
