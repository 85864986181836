import { Link } from 'react-router-dom';

import { cn } from '@utils/main';
import type { MenuItemProps } from '@utils/menu';

const HeaderItem = ({
    className,
    displayIconDesktop = true,
    displayLabel = true,
    icon,
    label,
    link,
    mobile = true,
    onClick,
    text,
}: MenuItemProps) => {
    const containerClassName = cn('flex items-center', !mobile && 'mobile:hidden', className);
    const contentEl = (
        <>
            {icon && <span className={cn('mr-1', !displayIconDesktop && 'desktop:hidden large:hidden')}>{icon}</span>}
            {displayLabel && <span className="text-lg font-medium uppercase mobile:hidden">{text ?? label}</span>}
        </>
    );

    return link ? (
        <Link className={containerClassName} to={link} {...(!displayLabel && { 'aria-label': label })}>
            {contentEl}
        </Link>
    ) : (
        <div className={containerClassName} onClick={onClick}>
            {contentEl}
        </div>
    );
};

export default HeaderItem;
