import type { SVGProps } from 'react';

const IconLogout = (props: SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 381 482" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <title>logout</title>
        <g id="logout" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g
                id="noun-logout-1262637"
                transform="translate(0.068550, -0.000000)"
                fill="currentColor"
                fillRule="nonzero"
            >
                <polygon
                    id="Path"
                    points="243.04 355.04 346.08 252 350.0019 252 350.0019 248.082 357.2792 240.8008 350.0019 233.5196 350.0019 229.6016 346.08 229.6016 243.04 126.5616 227.36 142.2416 314.719 229.6006 72.799 229.6006 72.799 251.9986 314.719 251.9986 227.36 339.3576"
                ></polygon>
                <polygon
                    id="Path"
                    points="380.8 156.8 380.8 2.84217094e-14 -2.84217094e-14 2.84217094e-14 -2.84217094e-14 481.6 380.8 481.6 380.8 324.8 358.402 324.8 358.402 459.2 22.402 459.2 22.402 22.4 358.402 22.4 358.402 156.8"
                ></polygon>
            </g>
        </g>
    </svg>
);

export default IconLogout;
