import type { SVGProps } from 'react';

const IconDeleteAccount = (props: SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 513 513" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <title>cross</title>
        <g id="cross" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="noun-cross-3875626" fill="currentColor" fillRule="nonzero">
                <path
                    d="M256.666,0 C188.596,0 123.306,27.043 75.176,75.176 C27.043,123.313 0,188.596 0,256.666 C0,324.736 27.043,390.026 75.176,438.156 C123.313,486.289 188.596,513.332 256.666,513.332 C324.736,513.332 390.026,486.289 438.156,438.156 C486.289,390.019 513.332,324.736 513.332,256.666 C513.249969,188.619 486.184,123.376 438.066,75.266 C389.949,27.149 324.706,0.082 256.666,0 L256.666,0 Z M256.666,466.67 C200.971,466.67 147.556,444.545 108.176,405.162 C68.793,365.779 46.668,312.365 46.668,256.672 C46.668,200.979 68.793,147.562 108.176,108.182 C147.559,68.799 200.973,46.674 256.666,46.674 C312.359,46.674 365.776,68.799 405.156,108.182 C444.539,147.565 466.664,200.979 466.664,256.672 C466.6015,312.348 444.457,365.722 405.09,405.102 C365.719,444.469 312.34,466.614 256.66,466.676 L256.666,466.67 Z"
                    id="Shape"
                ></path>
                <path
                    d="M355.646,157.668 C351.271,153.293 345.337,150.836 339.15,150.836 C332.963,150.836 327.029,153.293 322.654,157.668 L256.666,223.68 L190.678,157.668 C186.3303,153.168 180.358,150.6016 174.1,150.546008 C167.8422,150.492212 161.823,152.9531 157.397,157.3789 C152.971,161.8047 150.5103,167.8239 150.564107,174.0819 C150.619687,180.3397 153.1861,186.3119 157.6861,190.6599 L223.6741,256.6719 L157.6861,322.6839 C153.1861,327.0316 150.6197,333.0039 150.564107,339.2619 C150.510312,345.5197 152.9712,351.5389 157.397,355.9649 C161.8228,360.3909 167.842,362.8516 174.1,362.797793 C180.3578,362.742212 186.33,360.1758 190.678,355.6758 L256.666,289.6638 L322.654,355.6758 C327.0017,360.1758 332.974,362.7422 339.232,362.797793 C345.4898,362.851588 351.509,360.3907 355.935,355.9649 C360.361,351.5391 362.8217,345.5199 362.767893,339.2619 C362.712313,333.0041 360.1459,327.0319 355.6459,322.6839 L289.6579,256.6719 L355.6459,190.6599 C360.0209,186.2849 362.4779,180.3509 362.4779,174.1639 C362.4779,167.9769 360.0209,162.0429 355.6459,157.6679 L355.646,157.668 Z"
                    id="Path"
                ></path>
            </g>
        </g>
    </svg>
);

export default IconDeleteAccount;
