import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import useConfirm from '@hooks/useConfirm';
import { RETURNS_KEY } from '@hooks/useReturns';
import apiOms from '@utils/apiOms';
import Button from './Button';

interface CancelOrderReturnButtonProps {
    className?: string;
    uid: string;
}
const CancelOrderReturnButton = ({ className, uid }: CancelOrderReturnButtonProps) => {
    const queryClient = useQueryClient();
    const intl = useIntl();
    const cancelOrderReturn = useMutation(
        [RETURNS_KEY],
        async (uid: string) => await apiOms.omsAuthenticated.cancelOrderReturnAuthenticated(uid),
        {
            onSettled: () => {
                queryClient.invalidateQueries({ queryKey: [RETURNS_KEY] });
            },
        }
    );
    const confirmCancelOrderReturn = useConfirm(intl.formatMessage({ id: 'orderReturns.return.cancel.confirm' }), () =>
        cancelOrderReturn.mutate(uid)
    );

    return (
        <Button className={className} onClick={confirmCancelOrderReturn} isLoading={cancelOrderReturn.isLoading}>
            <FormattedMessage id="orderReturns.return.cancel.cta" />
        </Button>
    );
};

export default CancelOrderReturnButton;
