import { defineMessages, FormattedMessage } from 'react-intl';

import { SecondTitle } from '@components/commons';
import LinkButton from '@components/Link/LinkButton';
import { useAppContext } from '@context/AppContext';
import { useUrl } from '@hooks/useUrl';
import InfoReturnBox from './InfoReturnBox';

const faqMessages = defineMessages({
    octobre: { id: 'orderReturn.return.detail.free_return.link.description.octobre' },
    sezane: { id: 'orderReturn.return.detail.free_return.link.description.sezane' },
});

interface FreeReturnBoxProps {
    boxIndex: number;
}

const FreeReturnBox = ({ boxIndex }: FreeReturnBoxProps) => {
    const { brandCode } = useAppContext();
    const { urls } = useUrl();

    return (
        <InfoReturnBox boxIndex={boxIndex}>
            <SecondTitle description={<FormattedMessage id="orderReturn.return.detail.free_return.description" />}>
                <FormattedMessage id="orderReturn.return.detail.free_return.title" />
            </SecondTitle>
            <LinkButton className="mt-8" fullWidth color="quartenary" href={urls.faq.return_second} target="_blank">
                <FormattedMessage id={faqMessages[brandCode].id} />
            </LinkButton>
        </InfoReturnBox>
    );
};

export default FreeReturnBox;
