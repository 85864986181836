import { useQuery } from '@tanstack/react-query';

import apiOms from '@utils/apiOms';
import { type BRANDS } from '@utils/brand';

export interface UseReturnHook {
    currentPage: number;
    brandCode: BRANDS;
    dateFrom?: string;
    dateTo?: string;
}

export const RETURNS_ITEMS_PER_PAGE = 15;
export const RETURNS_KEY = 'returns';

export const useReturns = ({ currentPage, brandCode, dateFrom, dateTo }: UseReturnHook) =>
    useQuery(
        [
            RETURNS_KEY,
            `${
                dateFrom || dateTo
                    ? `${currentPage}-${RETURNS_ITEMS_PER_PAGE}-${dateFrom}-${dateTo}`
                    : `${currentPage}-${RETURNS_ITEMS_PER_PAGE}`
            }`,
        ],
        () =>
            apiOms.omsAuthenticated.getUserOrderReturnsAuthenticated({
                page: currentPage,
                itemsPerPage: RETURNS_ITEMS_PER_PAGE,
                brandCode,
            }),
        {
            keepPreviousData: true,
        }
    );
