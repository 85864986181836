import { defineMessages, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { useUrl } from '@hooks/useUrl';

const messages = defineMessages({
    credit: { id: 'orderReturn.refunds.type.credit' },
    credits: { id: 'orderReturn.refunds.type.credits' },
    cash: { id: 'orderReturn.refunds.type.cash' },
    adyen: { id: 'orderReturn.refunds.type.adyen' },
    card: { id: 'orderReturn.refunds.type.card' },
    globale: { id: 'orderReturn.refunds.type.globale' },
    customer_service: { id: 'orderReturn.refunds.type.customer_service' },
    ecard: { id: 'orderReturn.refunds.type.ecard' },
});

interface ReturnInformationsProps {
    orderReference?: number | null;
    refundMode?: string | null;
    totalRefundAmount?: string | null;
}

const ReturnInformations = ({ orderReference, refundMode, totalRefundAmount }: ReturnInformationsProps) => {
    const { routes } = useUrl();
    return (
        <div className="flex flex-col gap-12 font-account-heading uppercase tracking-wide">
            {orderReference && (
                <div>
                    <p className="text-sm">
                        <FormattedMessage id="orderReturn.return.detail.selected_order" />
                    </p>
                    <p className="text-sm">
                        <Link className="font-semibold underline" to={`${routes.orders}/detail/${orderReference}`}>
                            <FormattedMessage id="orderReturn.return.detail.order" values={{ order: orderReference }} />
                        </Link>
                    </p>
                </div>
            )}
            {!!refundMode && (
                <div>
                    <p className="text-sm">
                        <FormattedMessage id="orderReturn.return.detail.refund_mode" />
                    </p>
                    <p className="text-sm font-semibold">
                        {messages[refundMode].id ? <FormattedMessage id={messages[refundMode].id} /> : refundMode}
                    </p>
                </div>
            )}
            {!!totalRefundAmount && (
                <div>
                    <p className="text-sm">
                        <FormattedMessage id="orderReturns.return.detail.total" />
                    </p>
                    <p className="text-sm font-semibold">{totalRefundAmount}</p>
                </div>
            )}
        </div>
    );
};

export default ReturnInformations;
