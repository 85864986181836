import { forwardRef } from 'react';
import { Link, type LinkProps, type To } from 'react-router-dom';

import { type ColorType, defaultBlockButtonStyles, variantStyles } from '@utils/button';
import { cn } from '@utils/main';

type DefaultButtonClasses = { color?: ColorType };
export interface LinkButtonProps extends DefaultButtonClasses, Omit<LinkProps, 'to' | 'color'> {
    href?: string;
    to?: To;
    fullWidth?: boolean;
    children?: React.ReactNode;
}

const LinkButton = forwardRef<HTMLAnchorElement, LinkButtonProps>(
    ({ href, to, className, color = 'primary', fullWidth, children, ...props }, ref) => {
        const baseClassNames = 'm-auto inline-block text-center text-base';
        const colorClassNames = variantStyles[color];
        const allClassNames = cn(
            baseClassNames,
            colorClassNames,
            defaultBlockButtonStyles,
            className,
            fullWidth && 'w-full'
        );

        return to ? (
            <Link {...props} to={to} ref={ref} className={allClassNames}>
                {children}
            </Link>
        ) : (
            <a
                {...props}
                href={href}
                ref={ref}
                className={allClassNames}
                {...(props?.target === '_blank' && { rel: 'noreferrer noopener' })}
            >
                {children}
            </a>
        );
    }
);

export default LinkButton;
