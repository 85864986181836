import { Fragment } from 'react';
import clsx from 'clsx';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { SubTitle } from '@components/commons';
import IconOrders from '@components/Icons/IconOrders';
import LoaderWrapper from '@components/Loader/LoaderWrapper';
import EmptyList from '@components/Page/EmptyList';
import ErrorOnFetch from '@components/Page/ErrorOnFetch';
import Pagination from '@components/Pagination/Pagination';
import { useOrdersContext } from '@context/OrdersContext';
import { ORDERS_ITEMS_PER_PAGE } from '@hooks/useOrders';
import { isSameMonthAndYear, type ItemToCompare } from '@utils/order';
import OrdersListItem from './OrdersListItem';

const OrdersList = () => {
    const { orders, isSuccess, contentRange, isError, isLoading, currentPage, setCurrentPage } = useOrdersContext();

    return (
        <LoaderWrapper isLoading={isLoading}>
            {isSuccess &&
                (orders?.length ? (
                    orders?.map((order, idx) => {
                        const changeMonthTitle = isSameMonthAndYear(
                            order as ItemToCompare,
                            orders as ItemToCompare[],
                            idx
                        );
                        return (
                            <Fragment key={`o-${idx}`}>
                                {changeMonthTitle ? null : (
                                    <SubTitle className="mt-8 font-account-heading font-medium uppercase desktop:mb-3">
                                        <FormattedDate value={order.createdAt} month="long" year="numeric" />
                                    </SubTitle>
                                )}
                                <OrdersListItem
                                    order={order}
                                    className={clsx(
                                        changeMonthTitle ? 'desktop:border-t-0' : 'desktop:border-t',
                                        'desktop:border-b'
                                    )}
                                />
                            </Fragment>
                        );
                    })
                ) : (
                    <EmptyList
                        icon={<IconOrders className="h-8" />}
                        message={<FormattedMessage id="orders.no_orders" />}
                    />
                ))}
            {isError && <ErrorOnFetch />}
            {orders && orders?.length ? (
                <Pagination
                    contentRange={contentRange}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    resultsByPage={ORDERS_ITEMS_PER_PAGE}
                />
            ) : null}
        </LoaderWrapper>
    );
};

export default OrdersList;
