import { defineMessages, FormattedMessage } from 'react-intl';

import Button from '@components/Button/Button';
import { PageHeader } from '@components/commons';
import LinkButton from '@components/Link/LinkButton';
import { contactIconMessages, useContactData } from '@hooks/useContact';
import { useUrl } from '@hooks/useUrl';
import imgFaqDelivery from '@img/faqDelivery.png';
import imgFaqOrders from '@img/faqOrders.png';
import imgFaqReturns from '@img/faqReturns.png';

const faqMessages = defineMessages({
    delivery: { id: 'contact.faq.delivery' },
    orders: { id: 'contact.faq.orders' },
    returns: { id: 'contact.faq.returns' },
});

const ContactPage = () => {
    const { urls } = useUrl();
    const contactData = useContactData();
    const faqData = [
        {
            id: 'delivery',
            icon: imgFaqDelivery,
            link: urls.faq.shipping,
        },
        {
            id: 'returns',
            icon: imgFaqReturns,
            link: urls.faq.return_refunds,
        },
        {
            id: 'orders',
            icon: imgFaqOrders,
            link: urls.faq.payment,
        },
    ];

    return (
        <>
            <PageHeader>
                <FormattedMessage id="contact.faq.title" />
            </PageHeader>
            <p className="mb-10">
                <FormattedMessage id="contact.faq.description" />
            </p>
            <div className="mb-12 flex mobile:flex-col">
                {faqData.map(({ id, icon, link }) => (
                    <div
                        key={id}
                        className="flex grow basis-0 flex-col justify-between border border-gray-lighter py-10 text-center mobile:mb-4 mobile:last:mb-0 desktop:mr-6 desktop:px-14 desktop:last:mr-0"
                    >
                        <img className="mx-auto mb-10 h-11" src={icon} alt="" />
                        <p className="mb-10">
                            <FormattedMessage id={faqMessages[id as keyof typeof faqMessages].id} />
                        </p>
                        <a href={link} className="text-sm underline">
                            <FormattedMessage id="contact.faq.cta" />
                        </a>
                    </div>
                ))}
            </div>
            <div className="text-center">
                <a className="inline-block" href={urls.faq.root}>
                    <Button>
                        <FormattedMessage id="contact.faq.cta_home" />
                    </Button>
                </a>
            </div>

            <PageHeader className="mt-10" title="contact.title">
                <FormattedMessage id="contact.title" />
            </PageHeader>
            <div className="mb-9 flex border-y border-gray-lighter mobile:flex-col desktop:py-8">
                {contactData.map(({ id, icon, link }) => (
                    <div
                        key={id}
                        className="grow basis-0 border-gray-lighter text-center last:border-none mobile:border-b mobile:py-6 desktop:border-r desktop:px-6"
                    >
                        {icon}
                        <div className="mb-6 font-account-heading text-sm uppercase">
                            <FormattedMessage id={contactIconMessages[id as keyof typeof contactIconMessages].id} />
                        </div>
                        <LinkButton className="mb-3" color="secondary" href={link} target="_blank">
                            <FormattedMessage id="contact.links.cta" />
                        </LinkButton>
                    </div>
                ))}
            </div>
        </>
    );
};

export default ContactPage;
