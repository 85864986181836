const IconStar = ({fillRule='evenodd', ...props}: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} {...props}>
        <path
            fill="#000"
            fillRule={fillRule}
            d="M16.351 10.764 14.001 6l-2.352 4.764-5.257.764 3.804 3.708-.898 5.236L14 18l4.702 2.472-.898-5.236 3.805-3.708-5.258-.764Zm2.249 1.741-3.179-.462-1.42-2.88-1.422 2.88-3.179.462 2.3 2.242-.543 3.166L14 16.418l2.843 1.495-.543-3.166 2.3-2.242Z"
            clipRule="evenodd"
        />
    </svg>
);
export default IconStar;
