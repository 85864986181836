import { FormattedMessage } from 'react-intl';

import { PageHeader } from '@components/commons';
import PeriodDropdown from '@components/Drodown/OrdersPeriodDropdown';
import OrdersTabs from '@components/Tabs/OrdersTabs';
import { useAuth } from '@context/AuthContext';
import { OrdersProvider } from '@context/OrdersContext';
import OrdersList from './OrdersList';

const OrdersPage = () => {
    const { keycloakId } = useAuth();

    return (
        <>
            <OrdersTabs />
            <PageHeader
                title="orders.title"
                column={
                    <div className="desktop:w-60">
                        <PeriodDropdown />
                    </div>
                }
            >
                <FormattedMessage id="orders.title" />
            </PageHeader>
            <OrdersProvider keycloakId={keycloakId}>
                <OrdersList />
            </OrdersProvider>
        </>
    );
};

export default OrdersPage;
