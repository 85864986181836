import { FormattedMessage } from 'react-intl';

import LinkButton, { type LinkButtonProps } from '@components/Link/LinkButton';
import { useAuth } from '@context/AuthContext';
import { useUrl } from '@hooks/useUrl';

interface ReturnOrderButtonBasisProps extends LinkButtonProps {
    step?: 1;
    orderId?: undefined;
    externalId?: undefined;
}
interface ReturnOrderButtonStep2Props extends LinkButtonProps {
    step: 2;
    orderId: string;
    externalId: string | null;
}

const getGlobalEPortalUrl = ({ externalId, email }: { externalId: string; email: string }) =>
    `${process.env.REACT_APP_GLOBALE_RETURN_URL}?OrderID=${externalId}&Email=${email}`;

const ReturnOrderButton = ({
    step = 1,
    orderId,
    externalId,
    className,
    color = 'secondary',
    ...props
}: ReturnOrderButtonBasisProps | ReturnOrderButtonStep2Props) => {
    const { urls } = useUrl();
    const { user } = useAuth();
    const { routes } = useUrl();

    return process.env.REACT_APP_RETURN_V2 === 'true' ? (
        <LinkButton color={color} className={className} to={routes.returnCreate} state={{ orderId }}>
            <FormattedMessage id="orders.order.detail.action.return" />
        </LinkButton>
    ) : (
        <LinkButton
            color={color}
            className={className}
            href={
                externalId
                    ? getGlobalEPortalUrl({ externalId, email: user?.email! })
                    : step === 2 && orderId
                    ? urls.return({ step: 2, orderId: orderId })
                    : urls.return({ step: 1 })
            }
            {...props}
        >
            <FormattedMessage id="orders.order.detail.action.return" />
        </LinkButton>
    );
};

export default ReturnOrderButton;
