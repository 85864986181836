import { Link, type LinkProps } from 'react-router-dom';

import { cn } from '@utils/main';

interface TabsProps {
    items: Array<{ current?: boolean } & LinkProps>;
}

export const Tabs = ({ items }: TabsProps) => (
    <div className="mb-8 border-b border-gray-lighter">
        <nav className="-mb-px flex" aria-label="Tabs">
            {items.map(({ current, children, ...props }, key) => (
                <Link
                    key={key}
                    {...props}
                    className={cn(
                        current ? 'text-black-600 b-2 border-black' : 'border-gray-lighter text-gray-light',
                        'w-full whitespace-nowrap border-b-2 px-1 py-4 text-center font-account-heading text-xl font-medium uppercase'
                    )}
                    aria-current={current ? 'page' : undefined}
                >
                    {children}
                </Link>
            ))}
        </nav>
    </div>
);

export default Tabs;
