import { type PropsWithChildren, useEffect, useRef } from 'react';
import type { ReactNode } from 'react';

interface StepProps {
    className?: string;
    id: string;
    title?: ReactNode;
    description?: ReactNode;
    withoutInitialScroll?: boolean;
}

const Step = ({ children, className, description, id, title, withoutInitialScroll }: StepProps & PropsWithChildren) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref.current) {
            !withoutInitialScroll && ref.current.scrollIntoView({ behavior: 'smooth' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section id={id} className={className} ref={ref}>
            {title && <h2>{title}</h2>}
            {description && <p className="mt-6">{description}</p>}
            <div className="mt-6">{children}</div>
        </section>
    );
};

export default Step;
