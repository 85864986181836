import type { FieldErrors } from 'react-hook-form';
import { type MessageDescriptor, useIntl } from 'react-intl';
import { ErrorMessage } from '@hookform/error-message';

interface FieldErrorMessageProps {
    name: string;
    errors: FieldErrors;
}

const FieldErrorMessage = ({ name, errors }: FieldErrorMessageProps) => {
    const intl = useIntl();
    return (
        <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => intl.formatMessage({ id: message, defaultMessage: message } as MessageDescriptor)}
        />
    );
};

export default FieldErrorMessage;
