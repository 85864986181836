import { type ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import type frMessages from 'src/lang/fr.json';
import { useAppTranslations } from '@sezane/front-components';

import LoaderWrapper from '@components/Loader/LoaderWrapper';
import { DEFAULT_APP_NAME, DEFAULT_LOCALE } from '@utils/common';
import { useAppContext } from './AppContext';
import type { IntlLocales } from 'types';

type TranslationProviderProps = {
    children: ReactNode;
    disableTranslationLoading?: boolean;
};

export type Messages = typeof frMessages;

const TranslationProvider = ({ children, disableTranslationLoading }: TranslationProviderProps) => {
    const { site, locale = site.defaultLocale?.code || DEFAULT_LOCALE } = useAppContext();

    const importTranslation = async () => {
        const localeTranslation = await import(`../lang/${locale}.json`);
        return localeTranslation.default;
    };

    const translations = useAppTranslations({
        disableTranslationLoading,
        getLocaleTranslations: importTranslation,
        site,
        locale,
        app: DEFAULT_APP_NAME,
    });
    return (
        <LoaderWrapper isLoading={!site?.id || !translations} loaderProps={{ fullScreen: true }}>
            <IntlProvider locale={locale as IntlLocales} messages={translations}>
                {children}
            </IntlProvider>
        </LoaderWrapper>
    );
};

export default TranslationProvider;
