import { useState } from 'react';
import { defineMessages, FormattedMessage, type MessageDescriptor, useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { Dropdown } from '@sezane/front-components';
import { useQuery } from '@tanstack/react-query';

import { useAppContext } from '@context/AppContext';
import apiOms from '@utils/apiOms';

const dropdownMessages = defineMessages({
    default: { id: 'dropdown.6-month' },
});

const DEFAULT: Item = { id: '6-MONTH', label: dropdownMessages.default.id, value: null };

type Item = {
    id: string;
    label: MessageDescriptor['id'] | string;
    value: Date | null;
};

export default function OrdersPeriodDropdown() {
    const [value, setValue] = useState<Item>(DEFAULT);
    const intl = useIntl();
    const { brandCode } = useAppContext();
    let [_, setSearchParams] = useSearchParams();

    const { data, isLoading } = useQuery(['summary-orders'], () =>
        apiOms.omsAuthenticated.summaryUserOrdersAuthenticated({ brandCode })
    );
    const items: Item[] = [
        DEFAULT,
        ...(data?.data.summary?.map(item => ({
            id: item.yearMonth!,
            label: item.yearMonth!,
            value: new Date(item.yearMonth!),
        })) || []),
    ];
    const onChange = (item: Item) => {
        setValue(item);

        if (item.value) {
            setSearchParams({
                dateFrom: new Date(item.value?.getFullYear(), item.value?.getMonth(), 1).toISOString(),
                dateTo: new Date(item.value?.getFullYear(), item.value?.getMonth() + 1, 0).toISOString(),
            });
        } else {
            const now = new Date();
            setSearchParams({
                dateFrom: new Date(now.getFullYear(), now.getMonth() - 6, 1).toISOString(),
                dateTo: new Date().toISOString(),
            });
        }
    };

    return (
        <Dropdown
            id="period-dropdown"
            disabled={isLoading}
            defaultLabel={<FormattedMessage id={DEFAULT.label as MessageDescriptor['id']} />}
            className="font-account-body"
            wrappingListClassName="font-account-body"
            value={value}
            items={items}
            onChange={onChange}
            renderItem={item =>
                item.value
                    ? `${item.value.toLocaleString(intl.locale, { month: 'long' })} ${item.value.getFullYear()}`
                    : intl.formatMessage({ id: item.label as MessageDescriptor['id'] })
            }
        />
    );
}
