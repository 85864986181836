const GITHUB_URL = 'https://github.com/sezane/account-js';

export const logAppVersion = () => {
    console.info(`App version: ${process.env.COMMIT_HASH}`);
    if (
        process.env.NODE_ENV === 'development' ||
        (process.env.REACT_APP_ENV && ['qlf', 'preprod'].includes(process.env.REACT_APP_ENV))
    ) {
        console.info(`App version link: ${GITHUB_URL}/commit/${process.env.COMMIT_HASH}`);
    }
};
