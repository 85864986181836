import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import CancelOrderReturnButton from '@components/Button/CancelOrderReturnButton';
import { PageHeader, SubTitle } from '@components/commons';
import IconChevron from '@components/Icons/IconChevron';
import IconContact from '@components/Icons/IconContact';
import LinkButton from '@components/Link/LinkButton';
import Separator from '@components/Separator';
import VerticalTimeline from '@components/Timeline/VerticalTimeline';
import { useUrl } from '@hooks/useUrl';
import apiOms from '@utils/apiOms';
import { DEFAULT_IMG_PLACEHOLDER } from '@utils/common';
import { getCustomerReturnTrackingStatus, returnTimelineStatuses } from '@utils/order';
import ReturnDetailsV2Tracking from './ReturnDetailsTracking';
import ReturnDetailsV2Step1 from './ReturnDetailsV2';
import trackingOptions from './trackingOptions';

const RETURN_KEY = 'returns';
const getOrderReturnDetail = (uuid: string) => ({
    queryKey: [RETURN_KEY, `${uuid}`],
    queryFn: () => apiOms.omsAuthenticated.getOrderReturnAuthenticated(uuid),
    keepPreviousData: true,
});

export const returnModesMessages = defineMessages({
    dhl_express: { id: 'orderReturn.return.type.dhl_express' },
    free: { id: 'orderReturn.return.type.free' },
    return_label: { id: 'orderReturn.return.type.return_label' },
    schneider: { id: 'orderReturn.return.type.schneider' },
    special: { id: 'orderReturn.return.type.special' },
    store_return: { id: 'orderReturn.return.type.store_return' },
});

const ReturnDetails = () => {
    const { returnUid = 'testId' } = useParams();

    const { data, isSuccess } = useQuery(getOrderReturnDetail(returnUid));
    const orderReturn = data?.data;

    const currentCustomerTrackingStatus = getCustomerReturnTrackingStatus(orderReturn?.status) || 'canceled';
    const intl = useIntl();
    const { routes, urls } = useUrl();

    const returnModeTranslation = orderReturn?.returnModeType
        ? returnModesMessages[orderReturn?.returnModeType]?.id
        : null;

    if (isSuccess && orderReturn) {
        if (process.env.REACT_APP_RETURN_V2 === 'true') {
            return currentCustomerTrackingStatus === 'validated' ? (
                <ReturnDetailsV2Step1 orderReturn={orderReturn} currentTrackingStatus={currentCustomerTrackingStatus} />
            ) : (
                <ReturnDetailsV2Tracking
                    orderReturn={orderReturn}
                    currentTrackingStatus={currentCustomerTrackingStatus}
                />
            );
        } else
            return (
                <>
                    <section className="container mb-20 flex w-full flex-col items-center gap-[1.6rem] mobile:mb-12">
                        <Link
                            className="flex items-center self-start text-left text-sm text-gray-medium desktop:hidden"
                            to={routes.returns}
                        >
                            <IconChevron className="mr-3 rotate-180" height={10} />
                            <FormattedMessage id="header.back.returns" />
                        </Link>
                        <PageHeader
                            className="text-5xl mobile:mb-4 mobile:text-3xl"
                            title="orderReturns.return.label"
                            titleValues={{ reference: orderReturn.id }}
                        >
                            <FormattedMessage id="orderReturns.return.label" values={{ reference: orderReturn.id }} />
                        </PageHeader>
                        <p className="text-center font-account-subheading text-base uppercase text-gray-medium">
                            <FormattedMessage
                                id="orderReturn.return.detail.head.creation_date"
                                values={{
                                    date: intl.formatDate(new Date(orderReturn.createdAt!), {
                                        dateStyle: 'short',
                                        timeStyle: 'short',
                                    }),
                                }}
                            />
                            <br />
                            {returnModeTranslation ? (
                                <FormattedMessage id={returnModeTranslation} />
                            ) : (
                                orderReturn.returnModeType ?? null
                            )}
                        </p>
                        <div className="mt-10 grid auto-cols-fr auto-rows-max gap-8 mobile:mt-6 mobile:w-full mobile:gap-4 desktop:grid-flow-col">
                            <LinkButton className="flex h-full w-full items-center justify-center" to={routes.contact}>
                                <IconContact className="mr-4 h-10 w-10" />
                                <FormattedMessage id="orderReturn.return.detail.action.contact_us" />
                            </LinkButton>
                            {orderReturn.uid && orderReturn.status === 'pending' && (
                                <CancelOrderReturnButton uid={orderReturn.uid} />
                            )}

                            <LinkButton
                                className="flex h-full w-full items-center justify-center"
                                to={`${routes.orders}/detail/${orderReturn.firstOrderReference}`}
                            >
                                <FormattedMessage id="returns.order.see" />
                            </LinkButton>
                        </div>
                    </section>
                    <Separator />
                    <section className="container mt-12 grid gap-16 mobile:mt-4 desktop:mt-16 desktop:grid-cols-2">
                        <div>
                            <SubTitle className="mb-8">
                                <FormattedMessage
                                    id="orders.order.detail.body.count_articles"
                                    values={{ number: orderReturn.totalReturnItems }}
                                />
                            </SubTitle>
                            <div className="mb-8 flex flex-col gap-6 text-base">
                                {orderReturn.returnedItems &&
                                    orderReturn.returnedItems.map(product => (
                                        <div className="flex flex-row gap-6" key={product.orderLineItem?.uid}>
                                            {product.orderLineItem?.productUrl ? (
                                                <a
                                                    href={`${urls.ecommerceRoot}${product.orderLineItem.productUrl}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="w-[130px] shrink-0"
                                                >
                                                    <img
                                                        src={product.orderLineItem?.imageUrl || DEFAULT_IMG_PLACEHOLDER}
                                                        alt={product.orderLineItem?.label}
                                                    />
                                                </a>
                                            ) : (
                                                <img
                                                    className="w-[130px] shrink-0"
                                                    src={product.orderLineItem?.imageUrl || DEFAULT_IMG_PLACEHOLDER}
                                                    alt={product.orderLineItem?.label}
                                                />
                                            )}
                                            <div className="flex flex-col justify-center">
                                                <span className="mb-4 font-account-heading text-3xl font-medium uppercase">
                                                    {product.orderLineItem?.productUrl ? (
                                                        <a
                                                            href={`${urls.ecommerceRoot}${product.orderLineItem.productUrl}`}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {product.orderLineItem?.label}
                                                        </a>
                                                    ) : (
                                                        product.orderLineItem?.label
                                                    )}
                                                </span>
                                                <span className="mb-4 font-account-heading text-lg font-medium uppercase text-gray-medium">
                                                    {product.orderLineItem?.localeTotalDisplayPrice ||
                                                        product.orderLineItem?.totalDisplayPrice}
                                                </span>
                                                {product.orderLineItem?.colorLabel && (
                                                    <span className="text-gray-medium ">
                                                        {product.orderLineItem?.colorLabel}
                                                    </span>
                                                )}
                                                {product.orderLineItem?.sizeLabel && (
                                                    <span className="text-gray-medium">
                                                        <FormattedMessage
                                                            id="orders.order.detail.body.size"
                                                            values={{ size: product.orderLineItem?.sizeLabel }}
                                                        />
                                                    </span>
                                                )}
                                                <span className="mt-4 text-gray-medium">
                                                    <FormattedMessage
                                                        id="orderReturns.return.body.expected_quantity"
                                                        values={{ quantity: product.expectedQuantity }}
                                                    />
                                                </span>
                                                <span className="text-gray-medium">
                                                    <FormattedMessage
                                                        id="orderReturns.return.body.received_quantity"
                                                        values={{ quantity: product.receivedQuantity }}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                            <div className="flex flex-col gap-3 font-account-heading font-medium uppercase desktop:max-w-[80%]">
                                <span className="mt-6 flex justify-between font-medium uppercase text-gray-medium">
                                    <span>
                                        <FormattedMessage id="orderReturns.return.body.total" />{' '}
                                        <span className="text-sm font-normal">
                                            <FormattedMessage id="orders.order.detail.body.taxes" />
                                        </span>
                                    </span>
                                    <span>{orderReturn.localeTotalRefundAmount || orderReturn.totalRefundAmount}</span>
                                </span>
                            </div>
                        </div>
                        <div>
                            <VerticalTimeline
                                orderType="return"
                                uid={returnUid}
                                externalId={orderReturn.externalId!}
                                trackingOptions={trackingOptions}
                                timelineStatuses={returnTimelineStatuses.filter(status =>
                                    orderReturn.returnModeType === 'store_return' ? status !== 'in_transit' : true
                                )}
                                currentCustomerTrackingStatus={currentCustomerTrackingStatus}
                            />
                        </div>
                    </section>
                </>
            );
    }
};

export default ReturnDetails;
