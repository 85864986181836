import type { SVGProps } from 'react';

const IconInfo = (props: SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 504 504" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <title>infos</title>
        <g id="infos" fill="currentColor" fillRule="evenodd">
            <g id="noun-i-2490657" transform="translate(0.470550, 0.000000)" fill="currentColor" fillRule="nonzero">
                <path
                    d="M503.999,251.999 C503.999,185.163 477.447,121.069 430.189,73.809 C382.931,26.551 318.829,0 251.999,0 C185.169,0 121.069,26.551 73.809,73.809 C26.551,121.067 0,185.169 0,251.999 C0,318.829 26.551,382.929 73.809,430.189 C121.067,477.447 185.169,503.998 251.999,503.998 C296.233,503.998 339.69,492.353 377.999,470.236 C416.308,448.119 448.12,416.306 470.237,377.998 C492.354,339.689 503.999,296.232 503.999,251.998 L503.999,251.999 Z M252.696,479.500706 C192.358509,479.500706 134.487089,455.533921 91.830427,412.866279 C49.1638834,370.204127 25.196,312.336001 25.196,252.000706 C25.196,191.665411 49.1627854,133.791795 91.830427,91.135133 C134.492579,48.4685894 192.360705,24.500706 252.696,24.500706 C313.031295,24.500706 370.904911,48.4674914 413.561573,91.135133 C456.228117,133.797285 480.196,191.665411 480.196,252.000706 C480.127376,312.317336 456.13918,370.143738 413.489106,412.8004 C370.839032,455.457062 313.010434,479.438121 252.689412,479.507294 L252.696,479.500706 Z"
                    id="Shape"
                ></path>
                <path
                    d="M251.998,155.699 C257.9121,155.699 263.58,153.3474 267.764,149.1678 C271.9437,144.9881 274.2913,139.3162 274.2913,133.4018 C274.2913,127.4877 271.9436,121.8158 267.764,117.6358 C263.5804,113.4561 257.9124,111.1046 251.998,111.1046 C246.0836,111.1046 240.412,113.4562 236.232,117.6358 C232.0484,121.8155 229.7008,127.4874 229.7008,133.4018 C229.7008,139.3159 232.0485,144.9878 236.232,149.1678 C240.4117,153.3475 246.0836,155.699 251.998,155.699 Z"
                    id="Path"
                ></path>
                <path
                    d="M291.438,359.799 C283.7232,357.3263 274.293,350.0724 274.293,329.389 L274.293,193.589 C274.293,192.61634 273.90238,191.6828 273.2149,190.9952 C272.5274,190.30379 271.5938,189.9171 270.6172,189.9171 L216.4922,189.9171 C212.832,189.9171 209.3203,191.3702 206.7344,193.9601 C204.1446,196.546 202.6914,200.0578 202.6914,203.714 C202.742182,209.7999 206.7383,215.152 212.5547,216.929 C220.2695,219.4017 229.6997,226.6556 229.6997,247.339 L229.6997,329.39 C229.6997,350.074 220.27,357.328 212.5547,359.8 C206.7383,361.5812 202.7461,366.9289 202.6914,373.015 C202.6914,376.6752 204.1445,380.183 206.7344,382.7728 C209.3203,385.3587 212.8321,386.8119 216.4922,386.8119 L287.5002,386.8119 C291.1604,386.8119 294.6721,385.3588 297.258,382.7728 C299.8439,380.183 301.301,376.6751 301.301,373.015 C301.246312,366.9291 297.2541,361.581 291.4377,359.8 L291.438,359.799 Z"
                    id="Path"
                ></path>
            </g>
        </g>
    </svg>
);

export default IconInfo;
