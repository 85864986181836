import type { SVGProps } from 'react';

const IconAlert = (props: SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" version="1.1" {...props}>
        <title>alert</title>
        <g id="alert" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="noun-alert-1421872" transform="translate(0.470550, 0.000000)" fill="currentColor" fillRule="nonzero">
                <path
                    id="Shape"
                    d="m18.62491,14.87378l0,-4.50213c0.00073,-1.54663 -0.46272,-3.05807 -1.33025,-4.33855c-0.86772,-1.28053 -2.09941,-2.27112 -3.53622,-2.84402l0,-0.6506c-0.0895,-0.91763 -0.63136,-1.73 -1.44425,-2.16475c-0.81307,-0.43495 -1.78953,-0.43495 -2.60242,0c-0.81307,0.43477 -1.35493,1.24711 -1.44443,2.16475l0,0.6506c-1.44155,0.56872 -2.67886,1.55773 -3.55128,2.83859c-0.87241,1.28071 -1.33987,2.7943 -1.34113,4.34404l0,4.50213l-2.78468,4.32006l7.02652,0c0.1514,0.77859 0.56946,1.48003 1.18215,1.98341c0.61287,0.50357 1.38198,0.77766 2.17511,0.77517l0.0521,0l-0.00018,0c0.79309,0.00252 1.56224,-0.27159 2.17511,-0.77517c0.61268,-0.50338 1.03074,-1.20483 1.18215,-1.98341l7.02652,0l-2.78483,-4.3201zm-9.34267,-12.33545c0.06935,-0.55929 0.40844,-1.04905 0.90764,-1.31046c0.4992,-0.26158 1.09483,-0.26158 1.59403,0c0.4992,0.2614 0.8383,0.75117 0.90745,1.31046l0,0.33837l0,-0.00018c-1.12132,-0.26177 -2.2878,-0.26177 -3.40912,0l0,-0.33819zm-4.86652,12.64774l0,-4.81447c0,-2.35226 1.25492,-4.52593 3.29206,-5.70208c2.03713,-1.17615 4.54698,-1.17615 6.58411,0c2.03713,1.17615 3.29206,3.34977 3.29206,5.70208l0,4.81447l1.92583,2.96675l-17.0198,0l1.92574,-2.96675zm6.61014,5.72532l-0.0521,0l0.00018,0c-0.51244,-0.0029 -1.0102,-0.17173 -1.41846,-0.4814c-0.40808,-0.30969 -0.70488,-0.74355 -0.84574,-1.23624l4.58035,0c-0.14087,0.49269 -0.43767,0.92655 -0.84574,1.23624c-0.40826,0.30969 -0.90601,0.47852 -1.41846,0.4814l-0.00004,0z"
                />
            </g>
        </g>
    </svg>
);

export default IconAlert;
