import { defineMessages, FormattedMessage } from 'react-intl';
import { formatPostCodeAndCity } from '@sezane/front-components';

import ReturnSection from '@components/Box/Returns/ReturnSection';
import IconShop from '@components/Icons/IconShop';
import type { OpenHours, ShopList, ShopOpenHour } from 'types/apiOms';

interface StoreReturnSectionProps {
    shops: ShopList;
    sectionCounter: boolean;
}
const messages = defineMessages<keyof OpenHours>({
    day1: { id: 'shipping.relays.days.monday' },
    day2: { id: 'shipping.relays.days.tuesday' },
    day3: { id: 'shipping.relays.days.wednesday' },
    day4: { id: 'shipping.relays.days.thursday' },
    day5: { id: 'shipping.relays.days.friday' },
    day6: { id: 'shipping.relays.days.saturday' },
    day7: { id: 'shipping.relays.days.sunday' },
});

export const StoreReturnSection = ({ shops, sectionCounter }: StoreReturnSectionProps) => {
    return (
        <ReturnSection
            title={<FormattedMessage id="orderReturn.return.detail.option.shop" />}
            icon={IconShop}
            counter={sectionCounter}
            childrenClassName="desktop:grid desktop:grid-cols-2"
        >
            {shops?.map(shop => (
                <div
                    className="flex flex-col gap-2 rounded-[0.2rem] border border-gray-lighter p-6 desktop:p-4"
                    key={shop.uid}
                >
                    <p className="font-account-heading text-lg uppercase">{shop.address.name}</p>
                    <div className="flex flex-col gap-2 desktop:flex-row">
                        <p>{shop.address.address}</p>
                        <p>
                            {formatPostCodeAndCity({
                                countryCode: shop?.address?.countryCode,
                                city: shop?.address?.city,
                                postcode: shop?.address?.postcode!,
                            })}
                        </p>
                    </div>
                    {shop.openHours && (
                        <table className="mt-2 w-auto max-w-2xl table-fixed border-collapse text-base">
                            <tbody>
                                {Object.keys(shop.openHours).map(day => {
                                    const dayHours = (shop.openHours as Record<string, ShopOpenHour[]>)[day];
                                    return (
                                        <tr key={day}>
                                            <td className="pr-4 pt-1">
                                                <FormattedMessage id={messages[day as keyof OpenHours].id} />
                                            </td>
                                            {dayHours.length ? (
                                                dayHours.map(hours => (
                                                    <td key={`${day}-${hours}`} colSpan={3 - dayHours.length}>
                                                        {hours.startTime} - {hours.endTime}
                                                    </td>
                                                ))
                                            ) : (
                                                <td colSpan={2}>
                                                    <FormattedMessage id="shipping.relays.closed" />
                                                </td>
                                            )}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    )}
                </div>
            ))}
        </ReturnSection>
    );
};
