import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';

import 'vite/modulepreload-polyfill'; // Use vite polyfill here as we serve html page from php backend

function renderApp() {
    const container = document.getElementById('root');
    const root = createRoot(container!);

    root.render(
        <StrictMode>
            <App />
        </StrictMode>
    );
}

renderApp();
