import type { SVGProps } from 'react';

const TrashIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        className={props.className}
        width="16"
        height="22"
        viewBox="0 0 12 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.06977 1.87021V1.45392C4.06977 1.22533 4.2975 1.03901 4.57705 1.03901H7.42364C7.70386 1.03901 7.93159 1.22533 7.93159 1.45392V1.87021H4.06977ZM10.2355 2.90852C10.1761 4.47742 9.80864 14.0903 9.76227 14.5232C9.735 14.7823 9.64091 14.9097 9.59045 14.961H2.41023C2.35909 14.9097 2.26568 14.7823 2.23841 14.5232C2.19273 14.0903 1.78159 4.48435 1.71477 2.90852H10.2355ZM7.52523 0C8.31136 0 8.95023 0.651803 8.95023 1.45392V1.87021H12V2.90852H11.28L10.7802 14.8668C10.7666 14.9451 10.5395 16 9.66 16H2.33932C1.46045 16 1.23341 14.9451 1.22455 14.9007L0.695454 2.90852H0V1.87021H3.05045V1.45392C3.05045 0.651803 3.68932 0 4.47545 0H7.52523ZM8.74276 3.74056H7.72344L7.40571 14.1299H8.42503L8.74276 3.74056ZM6.50952 3.7407H5.4902V14.1301H6.50952V3.7407ZM4.27646 3.74056H3.25714L3.57419 14.1299H4.59419L4.27646 3.74056Z"
        />
    </svg>
);

export default TrashIcon;
