import { FormattedMessage } from 'react-intl';

import AdditionnalInfoReturnBox from './AdditionnalInfoReturnBox';

const CreditsdInfo = () => (
    <AdditionnalInfoReturnBox title={<FormattedMessage id="orderReturn.return.detail.info.credits.title" />}>
        <FormattedMessage id="orderReturn.return.detail.info.credits.description" />
    </AdditionnalInfoReturnBox>
);

export default CreditsdInfo;
