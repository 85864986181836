import { FormattedMessage } from 'react-intl';

import { SecondTitle } from '@components/commons';
import InfoReturnBox from './InfoReturnBox';

interface PackagingBoxProps {
    boxIndex: number;
}
const PackagingBox = ({ boxIndex }: PackagingBoxProps) => {
    return (
        <InfoReturnBox boxIndex={boxIndex}>
            <SecondTitle
                description={
                    <FormattedMessage
                        id="orderReturn.return.detail.packaging.description"
                        values={{ br: <br />, i: chunks => <i>{chunks}</i> }}
                    />
                }
            >
                <FormattedMessage id="orderReturn.return.detail.packaging.title" />
            </SecondTitle>
        </InfoReturnBox>
    );
};

export default PackagingBox;
