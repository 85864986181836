import type { SVGProps } from 'react';

const IconSearch = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M7.75.25a7.5 7.5 0 1 0 4.466 13.526l3.754 3.754 1.06-1.06-3.704-3.704A7.5 7.5 0 0 0 7.75.25Zm-6 7.5a6 6 0 1 1 12 0 6 6 0 0 1-12 0Z"
            clipRule="evenodd"
        />
    </svg>
);
export default IconSearch;
