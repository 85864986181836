import { createContext, useContext } from 'react';

import { usePagination } from '@hooks/usePagination';
import { useReturns } from '@hooks/useReturns';
import { formatIsoDate } from '@utils/date';
import { getContentRange } from '@utils/fetch';
import { useAppContext } from './AppContext';
import type { ReturnContextType, ReturnsProviderProps } from 'types/context/returns';

const ReturnsContext = createContext<ReturnContextType>({} as ReturnContextType);

export const ReturnsProvider = ({ children }: ReturnsProviderProps) => {
    const { brandCode } = useAppContext();
    const { searchParams, currentPage, setCurrentPage } = usePagination();
    const dateFrom = searchParams.has('dateFrom') ? formatIsoDate(searchParams.get('dateFrom')!) : undefined;
    const dateTo = searchParams.has('dateTo') ? formatIsoDate(searchParams.get('dateTo')!) : undefined;
    const {
        data: returnsResponse,
        isSuccess,
        isLoading,
        isError,
    } = useReturns({
        currentPage,
        brandCode,
        dateFrom,
        dateTo,
    });
    const contentRange = returnsResponse && getContentRange(returnsResponse);

    return (
        <ReturnsContext.Provider
            value={{
                returns: returnsResponse?.data,
                isSuccess,
                isLoading,
                isError,
                currentPage,
                setCurrentPage,
                contentRange,
            }}
        >
            {children}
        </ReturnsContext.Provider>
    );
};

export const useReturnsContext = () => useContext(ReturnsContext);
