import type { PropsWithChildren, ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import type { MessageDescriptor, PrimitiveType } from 'react-intl';
import { useIntl } from 'react-intl';

import { BRANDS, getBrand } from '@utils/brand';
import { cn } from '@utils/main';

interface PageHeaderTitle {
    title?: MessageDescriptor['id'];
    titleValues?: Record<string, PrimitiveType>;
    children?: ReactNode;
    className?: string;
}
interface PageHeaderProps extends PageHeaderTitle {
    description?: ReactNode;
    column?: ReactNode;
}
interface PageHeaderProps extends PageHeaderTitle {
    column?: ReactNode;
}

export const Title = ({ title, titleValues, className, children }: PageHeaderTitle) => {
    const intl = useIntl();
    const brand = getBrand();

    return (
        <>
            {title && (
                <Helmet>
                    <title>
                        {intl.formatMessage({ id: title }, titleValues)}
                        {' | '}
                        {brand === BRANDS.sezane ? 'Sézane' : 'Octobre Éditions'}
                    </title>
                </Helmet>
            )}
            <h1 className={cn('mb-4 font-medium uppercase', className)}>{children}</h1>
        </>
    );
};

export const SecondTitle = ({ className, children, description }: PageHeaderTitle & { description?: ReactNode }) => (
    <>
        <h2 className={cn('mb-4 font-medium uppercase', className)}>{children}</h2>
        {description ? <p className="text-gray-medium">{description}</p> : null}
    </>
);

export const SubTitle = ({ className, children }: PageHeaderTitle & { description?: ReactNode }) => (
    <h3 className={cn('mb-5 mt-8 font-medium uppercase', className)}>{children}</h3>
);

export const PageHeader = ({ children, className, title, titleValues, description, column }: PageHeaderProps) => {
    if (!description && !column) {
        return (
            <Title title={title} titleValues={titleValues} className={cn('mb-8', className)}>
                {children}
            </Title>
        );
    }

    return (
        <div className="flex gap-6 mobile:flex-col desktop:flex-row">
            <div className="flex grow flex-col">
                <Title title={title} titleValues={titleValues} className={cn('mb-5', className)}>
                    {children}
                </Title>
                <p className="w-5/6	tracking-wide text-gray-medium">{description}</p>
            </div>
            <div className="flex flex-col gap-[1.6rem] desktop:ml-auto">{column}</div>
        </div>
    );
};

export const Error = ({ children, className }: PropsWithChildren & { className?: string }) => (
    <p className={cn('text-sm italic text-error', className)}>{children}</p>
);
