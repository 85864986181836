import clsx from 'clsx';
import type { ComponentType, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Toggle } from '@sezane/front-components';

import { SubTitle } from '@components/commons';
import IconChevron from '@components/Icons/IconChevron';
import Separator from '@components/Separator';
import { cn } from '@utils/main';

interface ReturnSectionProps {
    children: ReactNode;
    title: ReactNode;
    icon: ComponentType<{ className?: string }>;
    counter: boolean;
    childrenClassName?: string;
}

export const returnSectionStyles = 'flex flex-col gap-[1.6rem] pt-12';

const ReturnSection = ({ children, title, counter, childrenClassName, ...props }: ReturnSectionProps) => (
    <>
        <Toggle
            className="group [counter-increment:section]"
            defaultOpen={!counter}
            label={
                <SubTitle className="mt-5 flex items-center justify-between px-6 font-normal normal-case">
                    <span className={clsx('flex items-center text-start text-2xl desktop:items-start')}>
                        <props.icon className="mr-4 shrink-0 desktop:mr-6" />
                        {counter && (
                            <span className="shrink-0">
                                {/* CSS counter */}
                                <span className="text-2xl after:content-['_'counter(section)]">
                                    <FormattedMessage id="orderReturn.return.detail.option.option_counter" />
                                </span>
                                <span className="mx-2 text-2xl mobile:mr-4">:</span>
                            </span>
                        )}
                        {title}
                    </span>
                    <IconChevron
                        className=" ml-4 shrink-0 transition-transform group-[[data-open]]:rotate-90"
                        height={12}
                    />
                </SubTitle>
            }
        >
            <div className={cn(returnSectionStyles, childrenClassName)}>{children}</div>
        </Toggle>
        <Separator className="mx-0 my-12  desktop:mx-8 desktop:last:hidden" />
    </>
);

export default ReturnSection;
