import React, { useEffect } from 'react';
import clsx from 'clsx';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';
import { addToast } from '@sezane/front-components';

import { SubTitle } from '@components/commons';
import IconReturns from '@components/Icons/IconReturns';
import LoaderWrapper from '@components/Loader/LoaderWrapper';
import EmptyList from '@components/Page/EmptyList';
import ErrorOnFetch from '@components/Page/ErrorOnFetch';
import Pagination from '@components/Pagination/Pagination';
import { useReturnsContext } from '@context/ReturnsContext';
import { RETURNS_ITEMS_PER_PAGE } from '@hooks/useReturns';
import type { ItemToCompare } from '@utils/order';
import { isSameMonthAndYear } from '@utils/order';
import ReturnsListItem from './ReturnsListItem';

const OrdersList = () => {
    const { state } = useLocation();

    const { returns, isSuccess, isLoading, isError, contentRange, currentPage, setCurrentPage } = useReturnsContext();

    useEffect(() => {
        if (state?.createReturnSuccess) {
            addToast(<FormattedMessage id="orderReturns.creation.success" />, 'success');
            window.history.replaceState({}, '');
        }
    }, [state]);

    return (
        <LoaderWrapper isLoading={isLoading}>
            {isSuccess &&
                !!returns?.length &&
                returns?.map((orderReturn, idx) => {
                    const changeMonthTitle = isSameMonthAndYear(
                        orderReturn as ItemToCompare,
                        returns as ItemToCompare[],
                        idx
                    );

                    return (
                        <React.Fragment key={`r-${idx}`}>
                            {changeMonthTitle ? null : (
                                <SubTitle className="mt-8 font-account-heading font-medium uppercase desktop:mb-3">
                                    <FormattedDate value={orderReturn.createdAt} month="long" year="numeric" />
                                </SubTitle>
                            )}
                            <ReturnsListItem
                                orderReturn={orderReturn}
                                className={clsx(
                                    changeMonthTitle ? 'desktop:border-t-0' : 'desktop:border-t',
                                    'desktop:border-b'
                                )}
                            />
                        </React.Fragment>
                    );
                })}
            {isSuccess && !returns?.length && (
                <EmptyList
                    icon={<IconReturns className="h-8" />}
                    message={<FormattedMessage id="orderReturns.no_return" />}
                    withoutLink
                />
            )}
            {isError && <ErrorOnFetch />}
            {returns && returns?.length ? (
                <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    contentRange={contentRange}
                    resultsByPage={RETURNS_ITEMS_PER_PAGE}
                />
            ) : null}
        </LoaderWrapper>
    );
};

export default OrdersList;
