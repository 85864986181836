import type { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { BoxCard } from '@components/Box/BoxCard';
import { PageHeader } from '@components/commons';
import IconPayment from '@components/Icons/IconPayment';
import LoaderWrapper from '@components/Loader/LoaderWrapper';
import EmptyList from '@components/Page/EmptyList';
import { useAppContext } from '@context/AppContext';
import useCurrency from '@hooks/useCurrency';
import amex from '@img/cards/amex-lg.png';
import cartebancaire from '@img/cards/cartebancaire-lg.png';
import maestro from '@img/cards/maestro-lg.png';
import apiOms from '@utils/apiOms';
import { DEFAULT_CURRENCYCODE } from '@utils/common';
import Maestro from './svg/Maestro';
import Mastercard from './svg/MastercardLogo';
import Visa from './svg/VisaLogo';

const img: Record<string, string> = {
    amex,
    maestro,
    cartebancaire,
};
const svgs: Record<string, (title: string) => ReactNode> = {
    visa: (title: string) => <Visa title={title} />,
    mc: (title: string) => <Mastercard title={title} />,
    maestro: (title: string) => <Maestro title={title} />,
};

const PaymentPage = () => {
    const { brandCode } = useAppContext();
    const queryClient = useQueryClient();
    const intl = useIntl();
    const {
        site: { code },
    } = useAppContext();
    const currency = useCurrency();

    // Doesn't need to check if client use globalE as there's no Adyen account bound to. Default to fr/EUR
    const {
        data: paymentsResponse,
        isLoading: isLoadingPayments,
        isSuccess: isSuccessPayments,
    } = useQuery(['payments'], () =>
        apiOms.omsAuthenticated.getUserPaymentMethodsAuthenticated({
            brandCode: brandCode,
            siteCode: code || 'fr',
            currencyCode: currency?.code || DEFAULT_CURRENCYCODE,
        })
    );
    const payments = paymentsResponse && Object.values(paymentsResponse?.data);

    const { mutate: deletePaymentMethod, isLoading: isLoadingDeletePayment } = useMutation(
        (reference: string) =>
            apiOms.omsAuthenticated.deleteUserPaymentMethodsAuthenticated(reference, {
                brandCode: brandCode,
                siteCode: code || 'fr',
                currencyCode: currency?.code || DEFAULT_CURRENCYCODE,
            }),
        {
            onSettled: () => {
                queryClient.invalidateQueries(['payments']);
            },
        }
    );
    const confirmDeletePaymentMethod = (reference: string) => {
        if (window.confirm(intl.formatMessage({ id: 'payments.actions.delete_payment_method.confirm' }))) {
            return deletePaymentMethod(reference);
        }
    };

    return (
        <>
            <PageHeader title="payments.title">
                <FormattedMessage id="payments.title" />
            </PageHeader>
            <LoaderWrapper isLoading={isLoadingPayments}>
                {isSuccessPayments && payments?.length ? (
                    <div>
                        <div className="flex items-center justify-between p-4 font-account-heading text-base uppercase text-gray-medium mobile:hidden">
                            <span className="flex-1">
                                <FormattedMessage id="payments.methods.card_number" />
                            </span>
                            <span className="flex-1">
                                <FormattedMessage id="payments.methods.expiration_date" />
                            </span>
                            <span className="flex-1">
                                <FormattedMessage id="payments.methods.type" />
                            </span>
                            <span className="flex-1">
                                <FormattedMessage id="payments.methods.action" />
                            </span>
                        </div>
                        {payments?.map(card => (
                            <BoxCard
                                key={card.reference}
                                card={card}
                                src={card.type && img.hasOwnProperty(card.type) ? img[card.type] : null}
                                svg={card.type && svgs.hasOwnProperty(card.type) ? svgs[card.type] : null}
                                deletePaymentMethod={confirmDeletePaymentMethod}
                                isLoading={isLoadingDeletePayment}
                            />
                        ))}
                    </div>
                ) : null}
                {isSuccessPayments && payments?.length === 0 ? (
                    <EmptyList
                        icon={<IconPayment className="h-8" />}
                        message={<FormattedMessage id="payments.methods.no_payment_method" />}
                        withoutLink
                    />
                ) : null}
            </LoaderWrapper>
        </>
    );
};

export default PaymentPage;
