import { createContext, useContext, useState } from 'react';
import Cookies from 'js-cookie';
import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useAnalytics } from '@hooks/useAnalytics';
import useCurrency from '@hooks/useCurrency';
import apiOms from '@utils/apiOms';
import { DEFAULT_CURRENCYCODE, DEFAULT_SITECODE } from '@utils/common';
import { useAppContext } from './AppContext';
import type { OMS } from 'types';
import type { Cart } from 'types/apiOms';

const CART_COOKIE_NAME = 'SezaneCartUuid';

type CartContextType = {
    cart?: Cart;
    addToCart: UseMutationResult<
        Awaited<ReturnType<typeof apiOms.omsAuthenticated.createCartAndAddProduct>>,
        unknown,
        string,
        unknown
    >;
    isAddingToCart: Record<string, boolean>;
    overlayVisible: boolean;
    setOverlayVisible: (visibility: boolean) => void;
};

type CartProviderProps = {
    children: React.ReactNode;
};

export const getDomain = (host: string = window.location.host) =>
    host.replace('account', '').replace('www-nocache', '').replace('www', '');

export const getCartId = () => {
    return Cookies.get(CART_COOKIE_NAME) || '';
};

export const setCartId = (cartId: string) => {
    return Cookies.set(CART_COOKIE_NAME, cartId, { path: '/', domain: getDomain() });
};

export const resetCartId = () => {
    Cookies.remove(CART_COOKIE_NAME, { path: '/', domain: getDomain() });
};

const CartContext = createContext<CartContextType>({} as CartContextType);

export const CartProvider = ({ children }: CartProviderProps) => {
    const cartId = getCartId();
    const {
        brandCode,
        isGlobalE,
        site: { code },
    } = useAppContext();
    const queryClient = useQueryClient();
    const currency = useCurrency();
    const { onProductAddedToCart } = useAnalytics();

    const [overlayVisible, setOverlayVisible] = useState(false);
    const [isAddingToCart, setIsAddingToCart] = useState<Record<string, boolean>>({});

    const { data } = useQuery(['cart', cartId], () => apiOms.omsPublic.getCart(cartId), {
        enabled: !!cartId,
        onError: resetCartId,
        refetchOnWindowFocus: true,
    });
    const addToCart = useMutation(
        (ean: string, lms: boolean | undefined = false, customisation: OMS.ItemCustomisation | undefined = {}) => {
            setIsAddingToCart({ ...isAddingToCart, [ean]: true });

            if (cartId) {
                return apiOms.omsPublic.addProduct(
                    cartId,
                    {
                        brandCode,
                        siteCode: code || DEFAULT_SITECODE,
                        currencyCode: currency?.code || DEFAULT_CURRENCYCODE,
                    },
                    { eans: [ean], lms, customisation }
                );
            }

            return apiOms.omsAuthenticated.createCartAndAddProduct(
                {
                    brandCode,
                    siteCode: code || 'eu',
                    currencyCode: currency?.code || 'EUR',
                    globale: isGlobalE,
                },
                { eans: [ean], lms, customisation }
            );
        },
        {
            onSuccess: (data, ean) => {
                setIsAddingToCart({ ...isAddingToCart, [ean]: false });

                if (data) {
                    const cartId = data.data.number!;
                    queryClient.setQueryData(['cart', cartId], data);
                    setCartId(data.data.number!);
                    setOverlayVisible(true);
                }

                onProductAddedToCart(ean);
            },
            onError: (_error, ean) => {
                setIsAddingToCart({ ...isAddingToCart, [ean]: false });
            },
        }
    );

    return (
        <CartContext.Provider
            value={{
                cart: data?.data,
                addToCart,
                isAddingToCart,
                overlayVisible,
                setOverlayVisible,
            }}
        >
            {children}
        </CartContext.Provider>
    );
};

export const useCartContext = () => useContext(CartContext);
