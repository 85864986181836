import { FormattedMessage, useIntl } from 'react-intl';

import { SecondTitle } from '@components/commons';
import LinkButton from '@components/Link/LinkButton';
import InfoReturnBox from './InfoReturnBox';

interface SchneiderInfoProps {
    boxIndex: number;
}
const SchneiderInfo = ({ boxIndex }: SchneiderInfoProps) => {
    const intl = useIntl();
    return (
        <InfoReturnBox boxIndex={boxIndex}>
            <SecondTitle
                description={
                    <>
                        <FormattedMessage
                            id="orderReturn.return.detail.schneider.description"
                            values={{ br: <br />, i: chunks => <i>{chunks}</i> }}
                        />
                        <LinkButton
                            className="mt-8"
                            fullWidth
                            color="quartenary"
                            href={intl.formatMessage({ id: 'orderReturn.return.detail.schneider.href' })}
                            target="_blank"
                        >
                            <FormattedMessage id="orderReturn.return.detail.schneider.cta" />
                        </LinkButton>
                    </>
                }
            >
                <FormattedMessage id="orderReturn.return.detail.schneider.title" />
            </SecondTitle>
        </InfoReturnBox>
    );
};

export default SchneiderInfo;
