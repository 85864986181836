import React from 'react';
import clsx from 'clsx';

type StatusInfoProps = {
    children: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const StatusInfo = ({ className, children }: StatusInfoProps) => {
    return (
        <div
            className={clsx(
                'status-info bg-gray-lightest px-16 py-12 text-base text-gray-medium mobile:border-l-[0.125rem] mobile:border-gray-lighter desktop:-ml-6',
                className
            )}
        >
            {children}
        </div>
    );
};

export default StatusInfo;
