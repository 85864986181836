import React, { type ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import {
    getCountryName,
    getGlobalECountryCookie,
    type GlobalECountry,
    type GlobalECountryData,
    setGlobalECountryCookie,
} from '@sezane/front-components';

type CountryContextType = {
    country: GlobalECountry;
    setCountry: (country: GlobalECountryData) => void;
};

interface CountryProviderProps {
    initialData?: CountryContextType;
    children: ReactNode;
}

const CountryContext = React.createContext<CountryContextType>({
    country: {
        label: '',
        currencyCode: '',
        countryISO: '',
    },
} as CountryContextType);

export const CountryProvider: React.FC<CountryProviderProps> = ({ children, initialData }) => {
    const intl = useIntl();
    const [globalECountry, setGlobalECountryState] = useState(
        initialData ? initialData.country : getGlobalECountryCookie()
    );
    const country: GlobalECountry = useMemo(
        () => ({
            ...globalECountry,
            label: getCountryName(globalECountry.countryISO || '', intl.locale),
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [globalECountry]
    );

    const setCountry = (country: GlobalECountryData) => {
        setGlobalECountryCookie(country);
        setGlobalECountryState(country);
    };

    useEffect(() => {
        const onFocus = () => setGlobalECountryState(getGlobalECountryCookie());
        window.addEventListener('focus', onFocus);
        return () => window.removeEventListener('focus', onFocus);
    }, []);

    return <CountryContext.Provider value={{ country, setCountry }}>{children}</CountryContext.Provider>;
};

export const useCountry = () => {
    const countryContext = useContext(CountryContext);
    return countryContext;
};
