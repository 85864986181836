import clsx from 'clsx';
import type { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

interface ReturnNavStepProps {
    active?: boolean;
    current?: boolean;
    onClick: () => void;
}

const ReturnNavStep = ({ active, children, current, onClick }: ReturnNavStepProps & PropsWithChildren) => (
    <div
        className={clsx(
            'font-heading uppercase transition-all mobile:flex mobile:grow mobile:basis-0 mobile:items-end mobile:justify-center mobile:text-center desktop:mb-3 desktop:py-5',
            active ? 'cursor-pointer border-black text-black' : 'cursor-not-allowed border-gray-200 text-gray-light',
            current
                ? 'mobile:border-b-[6px] mobile:pb-[6px] desktop:border-l-[6px] desktop:pl-[6px]'
                : 'mobile:border-b-4 mobile:pb-[8px] desktop:border-l-4 desktop:pl-[8px]'
        )}
        onClick={active ? onClick : undefined}
    >
        {children}
    </div>
);

interface ReturnNavProps {
    currentStep: number;
    goToStep: (step: number, withScroll?: boolean) => void;
    isDhl?: boolean;
}

const ReturnNav = ({ currentStep, goToStep, isDhl }: ReturnNavProps) => {
    return (
        <nav className="mobile:flex mobile:gap-4 mobile:pb-4">
            <ReturnNavStep onClick={() => goToStep(0, true)} active current={currentStep === 0}>
                <FormattedMessage id="return.nav.step_order" />
            </ReturnNavStep>
            <ReturnNavStep onClick={() => goToStep(1, true)} active={currentStep > 0} current={currentStep === 1}>
                <FormattedMessage id="return.nav.step_products" />
            </ReturnNavStep>
            <ReturnNavStep onClick={() => goToStep(2, true)} active={currentStep > 1} current={currentStep === 2}>
                <FormattedMessage id="return.nav.step_refund" />
            </ReturnNavStep>
            {isDhl ? (
                <>
                    <ReturnNavStep
                        onClick={() => goToStep(3, true)}
                        active={currentStep > 2}
                        current={currentStep === 3}
                    >
                        <FormattedMessage id="return.nav.step_dhl_pickup" />
                    </ReturnNavStep>
                    <ReturnNavStep
                        onClick={() => goToStep(4, true)}
                        active={currentStep > 3}
                        current={currentStep === 4}
                    >
                        <FormattedMessage id="return.nav.step_dhl_slot" />
                    </ReturnNavStep>
                </>
            ) : (
                <ReturnNavStep onClick={() => goToStep(3, true)} active={currentStep > 2} current={currentStep === 3}>
                    <FormattedMessage id="return.nav.step_confirm" />
                </ReturnNavStep>
            )}
        </nav>
    );
};

export default ReturnNav;
