import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMutation } from '@tanstack/react-query';

import { useAppContext } from '@context/AppContext';
import useCurrency from '@hooks/useCurrency';
import api from '@utils/apiOms';
import { DEFAULT_CURRENCYCODE, DEFAULT_SITECODE } from '@utils/common';
import { cn } from '@utils/main';
import Button from './Button';
import type { OMS } from 'types';

type Props = {
    campaignId?: number;
    className?: string;
    ean: string;
    label?: ReactNode;
    onAdd?: () => void;
    setProductAlert: (ean: OMS.WishlistAndAlertItem) => void;
};

const CreateAlert = ({ className, ean, label, setProductAlert }: Props) => {
    const { brandCode, site, locale } = useAppContext();
    const currency = useCurrency();

    const { mutateAsync: addAlert, isLoading: isLoadingAddAlert } = useMutation(
        () =>
            api.omsAuthenticated.addAlertAuthenticated(ean, {
                brandCode,
                siteCode: site.code || DEFAULT_SITECODE,
                locale,
                currencyCode: currency?.code || DEFAULT_CURRENCYCODE,
            }),
        { onSuccess: ({ data }) => setProductAlert(data) }
    );

    return (
        <Button
            type="button"
            color="alert"
            className={cn(className, 'px-10')}
            onClick={() => addAlert()}
            isLoading={!!isLoadingAddAlert}
        >
            {label ? label : <FormattedMessage id="alerts.create_alert" />}
        </Button>
    );
};

export default CreateAlert;
