import { useEffect } from 'react';
import clsx from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { getLocalTimeZone, today } from '@internationalized/date';
import { useQuery } from '@tanstack/react-query';

import Button from '@components/Button/Button';
import { Error } from '@components/commons';
import Calendar from '@components/Form/Calendar';
import Loader from '@components/Loader/Loader';
import Separator from '@components/Separator';
import { useAppContext } from '@context/AppContext';
import apiOms from '@utils/apiOms';
import Step from './Step';
import type { DhlSlot, FormValues, Timezone } from './utils';
import { formatSlotDate, formatSlotTime, getCreateReturnSteps, parseTimezoneDate, parseZonedDate } from './utils';

const getSlotsForCalendarDate = (slots: DhlSlot[], calendarDate: Date, timezone?: Timezone) =>
    slots.filter(({ startTime }) => {
        if (!startTime) return false;

        const slotDate = parseTimezoneDate(startTime, timezone);

        return (
            calendarDate.getDate() === slotDate.getDate() &&
            calendarDate.getMonth() === slotDate.getMonth() &&
            calendarDate.getFullYear() === slotDate.getFullYear()
        );
    });

const filterMorningSlots =
    (morning: boolean, timezone?: Timezone) =>
    ({ startTime }: DhlSlot) => {
        if (!startTime) return false;
        const date = parseTimezoneDate(startTime, timezone);
        return morning ? date.getHours() < 12 : date.getHours() >= 12;
    };

interface SlotButtonProps {
    locale: string;
    onClick: () => void;
    pickupSlot?: DhlSlot;
    slot: DhlSlot;
    timezone?: Timezone;
}

const SlotButton = ({ locale, onClick, pickupSlot, slot, timezone }: SlotButtonProps) => {
    if (!slot.closeTime || !slot.startTime) return null;

    const isSelected = pickupSlot && pickupSlot.id === slot.id;

    return (
        <Button
            className={clsx('whitespace-nowrap', isSelected && 'border-black bg-black text-white')}
            color="secondary"
            onClick={onClick}
        >
            <span className="font-medium">
                {formatSlotTime(parseTimezoneDate(slot.startTime, timezone), locale)}
                {' - '}
                {formatSlotTime(parseTimezoneDate(slot.closeTime, timezone), locale)}
            </span>
            {timezone && (
                <>
                    <br />
                    <span className="font-normal text-gray-500">{timezone.id}</span>
                </>
            )}
        </Button>
    );
};

const StepDhlSlot = () => {
    const { locale } = useAppContext();

    const { clearErrors, control, formState, setValue, watch } = useFormContext<FormValues>();
    const address = watch('address');
    const pickupDate = watch('pickupDate');
    const pickupSlot = watch('pickupSlot');
    const returnMode = watch('returnMode');
    const timezone = watch('timezone');

    const defaultTimezone = getLocalTimeZone();

    const selectedDate = pickupDate ? pickupDate.toDate(timezone?.value || defaultTimezone) : undefined;

    const { data: orderReturnSlotsResponse, isFetching: isLoadingOrderReturnSlots } = useQuery(
        ['getOrderReturnSlots'],
        () =>
            apiOms.omsAuthenticated.getOrderReturnDhlSlotsAuthenticated(returnMode?.id!, {
                ...address,
                timezone: timezone?.value,
                countryCode: address?.countryCode?.split('-')[0],
                state: address?.countryCode?.split('-')[1] || address?.state || null,
            })
    );

    const slots: DhlSlot[] | undefined = orderReturnSlotsResponse?.data.slots;
    const selectedDateSlots =
        selectedDate && slots ? getSlotsForCalendarDate(slots, selectedDate, timezone) : undefined;
    const morningSlots = selectedDateSlots?.filter(filterMorningSlots(true, timezone));
    const afternoonSlots = selectedDateSlots?.filter(filterMorningSlots(false, timezone));
    const lastSlot = slots ? slots[slots.length - 1] : undefined;

    useEffect(() => {
        setValue('pickupSlot', undefined);
        clearErrors();

        const stepEl = document.getElementById('pickup_slot_time');
        if (stepEl) {
            stepEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pickupDate]);

    return (
        <Step
            id={getCreateReturnSteps(true)[4]}
            className="mt-24 pt-6"
            title={<FormattedMessage id="return.step_dhl_slot.title" />}
        >
            {isLoadingOrderReturnSlots ? (
                <Loader className="mt-10" />
            ) : (
                <>
                    <div className="mt-10 text-center">
                        <Controller
                            control={control}
                            name="pickupDate"
                            render={({ field, fieldState }) => (
                                <Calendar
                                    {...field}
                                    {...fieldState}
                                    minValue={today(timezone?.value || defaultTimezone)}
                                    maxValue={
                                        lastSlot?.startTime ? parseZonedDate(lastSlot.startTime, timezone) : undefined
                                    }
                                    // maxValue={lastSlot?.}
                                    isDateUnavailable={date => {
                                        if (!slots) return true;
                                        const calendarDate = date.toDate(timezone?.value || defaultTimezone);
                                        return !getSlotsForCalendarDate(slots, calendarDate, timezone).length;
                                    }}
                                />
                            )}
                        />
                    </div>

                    {selectedDate && (
                        <>
                            <Separator className="mt-10" />
                            <div id="pickup_slot_time" className="pt-10">
                                <h2 className="text-center capitalize">{formatSlotDate(selectedDate, locale)}</h2>
                                {!!morningSlots?.length && (
                                    <div className="mt-10">
                                        <p className="text-center font-heading uppercase text-gray-600">
                                            <FormattedMessage id="return.step_dhl_slot.times.morning" />
                                        </p>
                                        <div className="mt-4 flex flex-wrap justify-center gap-4">
                                            {morningSlots.map(slot => (
                                                <SlotButton
                                                    key={slot.id}
                                                    slot={slot}
                                                    pickupSlot={pickupSlot}
                                                    locale={locale}
                                                    timezone={timezone}
                                                    onClick={() => setValue('pickupSlot', slot)}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {!!afternoonSlots?.length && (
                                    <div className="mt-10">
                                        <p className="text-center font-heading uppercase text-gray-600">
                                            <FormattedMessage id="return.step_dhl_slot.times.afternoon" />
                                        </p>
                                        <div className="mt-4 flex flex-wrap justify-center gap-4">
                                            {afternoonSlots.map(slot => (
                                                <SlotButton
                                                    key={slot.id}
                                                    slot={slot}
                                                    pickupSlot={pickupSlot}
                                                    locale={locale}
                                                    timezone={timezone}
                                                    onClick={() => setValue('pickupSlot', slot)}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {formState.errors.pickupSlot && (
                                    <Error className="mt-10 text-center">{formState.errors.pickupSlot.message}</Error>
                                )}
                            </div>
                        </>
                    )}
                </>
            )}
        </Step>
    );
};

export default StepDhlSlot;
