import React, { useContext } from 'react';
import Cookies from 'js-cookie';
import type { ReactNode } from 'react';
import { getSiteUrlSegment, getValidSiteCodeAndLocaleFromUrl, isGlobalECountry } from '@sezane/front-components';
import { useQuery } from '@tanstack/react-query';

import Loader from '@components/Loader/Loader';
import apiClient from '@utils/api';
import apiOms from '@utils/apiOms';
import { BRANDS, getFrontUrl } from '@utils/brand';
import type { OMS } from 'types';
import type { Site } from 'types/apiEcommerce';

const DEVISE_COOKIE_NAME = 'Sezane';
const DEFAULT_DEVISE = 1;

type AppContextType = {
    brandCode: BRANDS;
    frontUrl: string;
    isGlobalE?: boolean;
    locale: string;
    mappingCountrySite: Record<string, string>;
    referencesOms: OMS.References;
    site: Site;
    sites: Site[];
    siteSegment: string;
};

type InitialData = Pick<AppContextType, 'site' | 'sites' | 'mappingCountrySite' | 'locale'>;

type AppProviderProps = {
    brandCode: BRANDS;
    children?: ReactNode;
    initialData?: InitialData;
};

const AppContext = React.createContext<AppContextType>({
    brandCode: BRANDS.sezane,
    frontUrl: '',
    locale: '',
    mappingCountrySite: {},
    referencesOms: {},
    site: undefined as any,
    sites: [],
    siteSegment: '',
});

export const getSite = (sites?: Site[]) => {
    const deviseCookie = Cookies.get(DEVISE_COOKIE_NAME);

    let site: Site | undefined;

    if (sites) {
        // if sites loaded
        if (deviseCookie) {
            // if we have a cookie, get site value from there
            const devise = Number(deviseCookie.replace('SiteDevise=', ''));
            site = sites.find(s => s.legacyCurrencyCookie === (isNaN(devise) ? DEFAULT_DEVISE : devise));
        } else {
            // if we don't, guess the site value from the url
            site = sites.find(s => window.location.pathname.startsWith(`/${s.code}`));

            if (!site) {
                // if we still haven't found the site, we default to FR
                site = sites.find(s => s.code === 'fr');
            }
        }
    }

    return site;
};

export const AppContextProvider = ({ brandCode, children, initialData }: AppProviderProps) => {
    const { data: sitesData, isFetching: isFetchingSites } = useQuery(
        ['sites'],
        async () => {
            const response = await apiClient.getBrandWebsites(brandCode, undefined, { secure: false });
            return response.data;
        },
        {
            enabled: !initialData,
        }
    );

    const sites = initialData ? initialData.sites : sitesData?.sites;
    const site = initialData ? initialData.site : getSite(sites);
    const siteSegment = site ? getSiteUrlSegment(site, window.location.pathname) : '';
    const mappingCountrySite = initialData ? initialData.mappingCountrySite : sitesData?.mappingCountrySite || {};
    const isGlobalE =
        site?.code === 'eu' && sitesData?.globalECountries ? isGlobalECountry(sitesData?.globalECountries) : false;
    const locale = initialData ? initialData.locale : site && getValidSiteCodeAndLocaleFromUrl(site).localeCode;

    const { data: referencesOms, isFetching: isFetchingReferencesOms } = useQuery(
        ['referencesOms'],
        async () => {
            const response = await apiOms.omsPublic.getReferencesList({
                localeCode: locale,
            });
            return response.data;
        },
        {
            enabled: !initialData && !!locale,
        }
    );

    if (!initialData) {
        if (isFetchingSites || isFetchingReferencesOms) {
            return <Loader fullPage />;
        }
        if (!isFetchingSites && !isFetchingReferencesOms && !site) {
            // Site not matched => redirect to root
            window.location.replace('/');
            return null;
        }
    }

    return (
        <AppContext.Provider
            value={{
                brandCode,
                frontUrl: getFrontUrl(),
                isGlobalE,
                locale: locale || 'FR',
                mappingCountrySite: mappingCountrySite as Record<string, string>,
                referencesOms: referencesOms || {},
                site: site || {},
                sites: sites || [],
                siteSegment,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => {
    const siteContext = useContext(AppContext);
    return siteContext;
};
