import { type ReactNode } from 'react';

interface InfoReturnBoxProps {
    boxIndex: number;
    children: ReactNode;
}
const InfoReturnBox = ({ children, boxIndex }: InfoReturnBoxProps) => (
    <div className="flex w-full flex-col gap-8 rounded-sm border border-gray-lighter p-8 desktop:flex-row desktop:items-center">
        <span className="flex h-14 w-14 shrink-0 items-center justify-center rounded-full bg-gray-lighter font-account-subheading font-medium text-gray-medium">
            {boxIndex}
        </span>
        <div className="flex w-full flex-col">{children}</div>
    </div>
);

export default InfoReturnBox;
