import type { SVGProps } from 'react';

const IconReturns = (props: SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.2693 0.350098H2.73086L0.350098 2.73086V16.6501H14.6501V13.3768L13.3501 13.5526V15.3501H1.6501V3.50009H5.50012V7.43434L7.50012 6.10101L9.50012 7.43434V3.50009H13.3501V4.34254L14.6501 4.16669V2.73086L12.2693 0.350098ZM5.60973 2.50009H2.41934L3.26934 1.6501H5.82223L5.60973 2.50009ZM6.64051 2.50009L6.85301 1.6501H8.14724L8.35974 2.50009H6.64051ZM9.39051 2.50009L9.17801 1.6501H11.7309L12.5809 2.50009H9.39051ZM6.50012 3.50009V5.56582L7.50012 4.89916L8.50012 5.56582V3.50009H6.50012ZM13.0355 5.64655L13.7426 6.35365L12.7631 7.33319H14.6113C15.8999 7.33319 16.9446 8.37786 16.9446 9.66652C16.9446 10.9552 15.8999 11.9999 14.6113 11.9999H13.1854V10.9999H14.6113C15.3477 10.9999 15.9446 10.4029 15.9446 9.66652C15.9446 8.93015 15.3477 8.33319 14.6113 8.33319H12.7626L13.7426 9.31321L13.0355 10.0203L10.8486 7.83343L13.0355 5.64655Z"
            fill="currentColor"
        />
    </svg>
);

export default IconReturns;
