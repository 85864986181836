import type { SVGProps } from 'react';

const IconCross = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="22"
        height="22"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 53.34 53.34"
        aria-hidden="true"
        {...props}
    >
        <path d="M53.34 7.64L45.7 0 26.67 19.03 7.64 0 0 7.64l19.03 19.03L0 45.7l7.64 7.64 19.03-19.03L45.7 53.34l7.64-7.64-19.03-19.03L53.34 7.64z" />
    </svg>
);

export default IconCross;
