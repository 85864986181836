import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { useCartContext } from '@context/CartContext';
import { cn } from '@utils/main';
import Button from './Button';

type Props = {
    campaignId?: number;
    className?: string;
    ean: string;
    label?: ReactNode;
    onAdd?: () => void;
};

const AddToCart = ({ className, ean, label }: Props) => {
    const { addToCart, isAddingToCart } = useCartContext();

    return (
        <Button
            type="button"
            className={cn(className, 'px-10')}
            onClick={() => addToCart.mutate(ean)}
            isLoading={!!isAddingToCart[ean]}
        >
            {label ? label : <FormattedMessage id="alerts.add_to_cart" />}
        </Button>
    );
};

export default AddToCart;
