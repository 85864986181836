import { defineMessages, FormattedMessage } from 'react-intl';

import { PageHeader } from '@components/commons';
import CreditsSection from './CreditsSection';
import ECardSection from './EcardSection';

const creditsPagesMessages = defineMessages({
    title: { id: 'credits_ecards.title' },
});

const CreditsPage = () => {
    return (
        <>
            <PageHeader title={creditsPagesMessages.title.id}>
                <FormattedMessage id="credits_ecards.credits.title" />
            </PageHeader>
            <CreditsSection />
            <ECardSection />
        </>
    );
};

export default CreditsPage;
