import clsx from 'clsx';
import type { CalendarProps } from 'react-aria';
import {
    Button,
    Calendar as CalendarComponent,
    CalendarCell,
    CalendarGrid,
    CalendarGridBody,
    CalendarGridHeader,
    CalendarHeaderCell,
    Heading,
} from 'react-aria-components';
import type { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';
import type { CalendarDate } from '@internationalized/date';

import { Error } from '@components/commons';
import IconChevron from '@components/Icons/IconChevron';

const Calendar = ({ value, ...props }: CalendarProps<CalendarDate> & ControllerRenderProps & ControllerFieldState) => (
    <>
        <CalendarComponent
            {...props}
            className={clsx('flex-col font-heading desktop:inline-flex', props.error && 'border border-error p-4')}
            // @ts-expect-error
            value={value as CalendarDate}
        >
            <header className="flex border border-gray-200">
                <Button
                    className={({ isDisabled }) =>
                        clsx(
                            'flex items-center justify-center border-r border-gray-200 mobile:h-20 mobile:w-20 desktop:h-24 desktop:w-24',
                            isDisabled && 'cursor-not-allowed text-gray-300'
                        )
                    }
                    slot="previous"
                >
                    <IconChevron className="h-6 rotate-180" />
                </Button>
                <Heading className="flex grow items-center justify-center text-xl uppercase mobile:h-20 desktop:h-24" />
                <Button
                    className={({ isDisabled }) =>
                        clsx(
                            'flex items-center justify-center border-l border-gray-200 mobile:h-20 mobile:w-20 desktop:h-24 desktop:w-24',
                            isDisabled && 'cursor-not-allowed text-gray-300'
                        )
                    }
                    slot="next"
                >
                    <IconChevron className="h-6" />
                </Button>
            </header>
            <CalendarGrid className="table-fixed border-collapse text-lg mobile:w-full" weekdayStyle="short">
                <CalendarGridHeader>
                    {day => (
                        <CalendarHeaderCell className="!font-normal text-gray-600 mobile:h-20 desktop:h-24">
                            {day}
                        </CalendarHeaderCell>
                    )}
                </CalendarGridHeader>
                <CalendarGridBody>
                    {date => (
                        <CalendarCell
                            className={({ isDisabled, isOutsideVisibleRange, isSelected, isUnavailable }) =>
                                clsx(
                                    'flex aspect-square items-center justify-center rounded-full outline-none transition-colors mobile:h-20 desktop:h-24',
                                    isOutsideVisibleRange
                                        ? 'cursor-default opacity-0'
                                        : isDisabled
                                        ? 'cursor-not-allowed text-gray-200'
                                        : isUnavailable
                                        ? 'cursor-not-allowed bg-gray-200 text-gray-500'
                                        : isSelected
                                        ? 'bg-black text-white'
                                        : 'border border-gray-200 bg-white hover:border-gray-600'
                                )
                            }
                            date={date}
                        />
                    )}
                </CalendarGridBody>
            </CalendarGrid>
        </CalendarComponent>
        {props.error && <Error className="mt-4">{props.error.message}</Error>}
    </>
);

export default Calendar;
