import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { PageHeader } from '@components/commons';
import IconChevron from '@components/Icons/IconChevron';
import HorizontalTimeline from '@components/Timeline/HorizontalTimeline';
import { useUrl } from '@hooks/useUrl';
import { returnTimelineStatuses } from '@utils/order';
import { type CurrentTrackingStatus, type ReturnModeType } from '@utils/return';
import { returnTrackingMessages } from '../ReturnsListItem';

interface ReturnDetailsTrackingProps {
    orderReturnId?: number;
    orderReturnModeType?: ReturnModeType | null;
    currentTrackingStatus: CurrentTrackingStatus;
    children: ReactNode;
}

const ReturnDetailsLayout = ({
    children,
    orderReturnId,
    orderReturnModeType,
    currentTrackingStatus,
}: ReturnDetailsTrackingProps) => {
    const { routes } = useUrl();

    return (
        <div className="flex flex-col gap-8">
            <Link
                className="flex items-center self-start text-left text-sm text-gray-medium desktop:hidden"
                to={routes.returns}
            >
                <IconChevron className="mr-3 rotate-180" height={10} />
                <FormattedMessage id="header.back.returns" />
            </Link>
            <PageHeader
                className="self-center text-5xl mobile:mb-4 mobile:text-3xl"
                title="orderReturns.return.label"
                titleValues={{ reference: orderReturnId }}
            >
                <FormattedMessage id="orderReturns.return.label" values={{ reference: orderReturnId }} />
            </PageHeader>
            <HorizontalTimeline
                displayedTrackingStatus={returnTimelineStatuses.filter(status =>
                    orderReturnModeType === 'store_return' ? status !== 'in_transit' : true
                )}
                currentTrackingStatus={currentTrackingStatus}
                statusMessages={returnTrackingMessages}
            />
            <div className="mb-20 grid w-full grid-cols-1 gap-8 mobile:mb-12 large:grid-cols-3 large:gap-28">
                {children}
            </div>
        </div>
    );
};

export default ReturnDetailsLayout;
