import { useState } from 'react';
import clsx from 'clsx';
import type { KeyboardEvent, ReactNode } from 'react';
import { formatPostCodeAndCity } from '@sezane/front-components';

import IconChevron from '@components/Icons/IconChevron';
import type { PhysicalAddress } from 'types/apiOms';

const ToggledBlockAddress = ({ address, label }: { address: PhysicalAddress; label: ReactNode }) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleClick = () => setIsOpen(!isOpen);
    const handlePressKey = (e: KeyboardEvent) => {
        if (e.key === 'Enter') setIsOpen(!isOpen);
    };
    return (
        <div className="text-center">
            <button
                className="mb-1 flex w-full flex-row justify-center mobile:cursor-pointer mobile:gap-6 desktop:cursor-default desktop:focus:outline-none"
                onClick={handleClick}
                onKeyDown={handlePressKey}
            >
                <span className="w-1/4 desktop:hidden"></span>
                <span className="flex flex-col font-account-heading font-medium uppercase mobile:w-1/2">{label}</span>
                <span className="w-1/4 self-center desktop:hidden">
                    <IconChevron
                        height={12}
                        className={clsx('transition-transform', isOpen ? 'rotate-90' : '-rotate-90')}
                    />
                </span>
            </button>
            <p className={clsx('flex flex-col items-center text-gray-medium', !isOpen && 'mobile:hidden')}>
                <span>
                    {address.firstName} {address.lastName}
                </span>
                <span>{address.address}</span>
                <span>
                    {formatPostCodeAndCity({
                        countryCode: address.countryCode,
                        city: address.city,
                        postcode: address.postcode,
                    })}
                    {address.country}
                </span>
            </p>
        </div>
    );
};

export default ToggledBlockAddress;
