import { useSearchParams } from 'react-router-dom';

export const usePagination = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = searchParams.has('page') ? Number(searchParams.get('page')) : 1;
    const setCurrentPage = (page: number) => {
        setSearchParams({ page: page.toString() });
    };

    return { currentPage, searchParams, setCurrentPage };
};
