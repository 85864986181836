import { returnSectionStyles } from '@components/Box/Returns/ReturnSection';
import { infoReturnBoxes, type ReturnBoxes } from './commons';
import type { OptionalPhysicalAddress } from 'types/apiOms';

interface DHLReturnProps {
    isDhlConfirm?: boolean;
    hasReturnLabel?: boolean;
    orderReference?: string;
    pickupAddress?: OptionalPhysicalAddress;
    pickupDateStart?: string | null;
    pickupDateEnd?: string | null;
}

const returnLabelSchema: ReturnBoxes[] = ['packaging', 'return_label', 'pickup'];
const returnLabelSchemaConfirm: ReturnBoxes[] = ['packaging', 'return_label', 'dhl_confirm'];

export const DHLReturnSection = ({
    hasReturnLabel,
    isDhlConfirm,
    orderReference,
    pickupDateStart,
    pickupDateEnd,
    pickupAddress,
}: DHLReturnProps) => (
    <div className={returnSectionStyles}>
        {(isDhlConfirm ? returnLabelSchemaConfirm : returnLabelSchema).map(
            (box, index) =>
                infoReturnBoxes({
                    boxIndex: index + 1,
                    orderReference,
                    hasReturnLabel,
                    pickupDateStart,
                    pickupDateEnd,
                    pickupAddress,
                })[box]
        )}
    </div>
);
