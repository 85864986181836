import { useRef } from 'react';
import { useDateSegment } from 'react-aria';
import type { DateFieldState, DateSegment } from 'react-stately';

export const DateSegmentField = ({
    segment,
    state,
    inputClass,
}: {
    segment: DateSegment;
    state: DateFieldState;
    inputClass: string;
}) => {
    const ref = useRef(null);
    const { segmentProps } = useDateSegment(segment, state, ref);
    return (
        <div {...segmentProps} ref={ref} className={`${inputClass} bg-clip-content focus:bg-black focus:text-white`}>
            {segment.text}
        </div>
    );
};
