import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';

import Button from '@components/Button/Button';
import { SecondTitle } from '@components/commons';
import FieldErrorMessage from '@components/Form/FieldErrorMessage';
import { TextField } from '@components/Form/TextField';
import LoaderWrapper from '@components/Loader/LoaderWrapper';
import { useAppContext } from '@context/AppContext';
import { useAuth } from '@context/AuthContext';
import apiOms from '@utils/apiOms';
import { DEFAULT_SITECODE } from '@utils/common';
import ecardInfo from '@utils/validation/credits';

interface ECardRequest {
    code: string;
    email: string;
}

export const ECardSection = () => {
    const { brandCode, site } = useAppContext();
    const { user } = useAuth();

    const {
        formState: { isDirty, errors },
        handleSubmit,
        control,
        reset: resetForm,
    } = useForm<ECardRequest>({
        defaultValues: { email: '', code: '' },
        values: {
            email: user?.email || '',
            code: '',
        },
        resolver: yupResolver(ecardInfo),
    });

    const {
        mutate,
        isIdle,
        isSuccess,
        isLoading,
        isError,
        data: eCardResponse,
        reset,
    } = useMutation(({ code, email }: ECardRequest) =>
        apiOms.omsAuthenticated.getEcardAuthenticated(
            {
                siteCode: site.code || DEFAULT_SITECODE,
                brandCode,
            },
            { code, email },
            { format: 'json' }
        )
    );
    const eCard = eCardResponse?.data;

    const onSubmit = (data: ECardRequest) => {
        mutate(
            { code: data.code.trim(), email: data.email.trim() },
            {
                onSuccess: () => {
                    resetForm();
                },
            }
        );
    };

    return (
        <section>
            <SecondTitle description={<FormattedMessage id="credits_ecards.ecards.description" />}>
                <FormattedMessage id="credits_ecards.ecards.title" />
            </SecondTitle>
            <div className="desktop:py-54 mt-6 flex w-full justify-center bg-ecard bg-cover p-12">
                <div className="min-h-[24.5rem] border border-gray-lighter bg-white p-8 mobile:w-full desktop:w-[500px]">
                    <LoaderWrapper isLoading={isLoading}>
                        {(isIdle || isError) && (
                            <form onSubmit={handleSubmit(onSubmit)} className="grid gap-5">
                                <Controller
                                    control={control}
                                    name="email"
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            type="email"
                                            isRequired
                                            label={<FormattedMessage id="credits_ecards.ecards.email" />}
                                            errorMessage={<FieldErrorMessage name="email" errors={errors} />}
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name="code"
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            isRequired
                                            label={<FormattedMessage id="credits_ecards.ecards.code" />}
                                            errorMessage={<FieldErrorMessage name="code" errors={errors} />}
                                        />
                                    )}
                                />
                                <Button disabled={!isDirty} type="submit" className="w-full">
                                    <FormattedMessage id="credits_ecards.ecards.button" />
                                </Button>
                                {isError ? (
                                    <p className="text- mt-5 text-xs font-semibold text-error">
                                        <FormattedMessage id="credits_ecards.ecards.error" />
                                    </p>
                                ) : null}
                            </form>
                        )}
                        {isSuccess && (
                            <div className="flex h-full flex-col items-center justify-evenly">
                                <p className="font-account-heading font-medium uppercase">
                                    <FormattedMessage
                                        id="credits_ecards.ecards.remaining"
                                        values={{
                                            amount: eCard?.data?.displayBalance,
                                        }}
                                    />
                                </p>
                                <Button className="w-full" onClick={reset}>
                                    <FormattedMessage id="credits_ecards.ecards.check_another" />
                                </Button>
                            </div>
                        )}
                    </LoaderWrapper>
                </div>
            </div>
        </section>
    );
};

export default ECardSection;
