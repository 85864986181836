import type { SVGProps } from 'react';

const IconCredits = (props: SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.3 1.3H14.2V9.7H1.3V8.70237C2.73598 8.3448 3.8 7.04666 3.8 5.5C3.8 3.95334 2.73598 2.6552 1.3 2.29763V1.3ZM0.5 7.5H0V11H15.5V0H0V3.5H0.5C1.60457 3.5 2.5 4.39543 2.5 5.5C2.5 6.60457 1.60457 7.5 0.5 7.5ZM6.29999 2V3H7.69999V2H6.29999ZM6.29999 4V5H7.69999V4H6.29999ZM6.29999 6V7H7.69999V6H6.29999ZM6.29999 8V9H7.69999V8H6.29999Z"
            fill="currentColor"
        />
    </svg>
);

export default IconCredits;
