import Cookies from 'js-cookie';

import type { OMS } from 'types';

export interface ItemToCompare {
    createdAt: string;
}

const getMonthYearToCompare = (d: string): string => `${new Date(d).getMonth()}-${new Date(d).getFullYear()}`;

export const isSameMonthAndYear = <T extends ItemToCompare>(newItem: T, array: T[], index: number) => {
    if (index === 0) return false;
    return getMonthYearToCompare(newItem.createdAt) === getMonthYearToCompare(array[index - 1].createdAt);
};

export type TimelineStatuses = NonNullable<(typeof orderTimelineStatuses | typeof returnTimelineStatuses)[number]>;

/* ORDERS */
// Please keep order !! Todo: test order
export const orderTimelineStatuses = ['validated', 'in_preparation', 'shipped', 'delivered', 'delivered_to_customer'];
export const getCustomerOrderTrackingStatus = (currentStatus?: OMS.OrderRead['status']) => {
    if (
        currentStatus === 'created' ||
        currentStatus === 'sent_to_logistic' ||
        currentStatus === 'pending' ||
        currentStatus === 'integrated' ||
        currentStatus === 'to_validate' ||
        currentStatus === 'validated'
    )
        return 'validated';
    if (currentStatus === 'in_preparation' || currentStatus === 'packed') return 'in_preparation';
    if (currentStatus === 'shipped') return 'shipped';
    if (currentStatus === 'delivered') return 'delivered';
    if (currentStatus === 'delivered_to_customer') return 'delivered_to_customer';
};

/* RETURNS */
// Please keep order !! Todo: test returns steps order
export const returnTimelineStatuses = ['validated', 'in_transit', 'refunded'];

export const getCustomerReturnTrackingStatus = (currentStatus?: OMS.OrderReturnStatus) => {
    if (currentStatus === 'pending') return 'validated';
    if (currentStatus === 'in_transit' || currentStatus === 'to_be_processed' || currentStatus === 'pending_refund')
        return 'in_transit';
    if (currentStatus === 'processed' || currentStatus === 'refunded_early') return 'refunded';
};

const CART_UPDATED_COOKIE_NAME = 'SezUpdated';

export const setCartUpdated = () =>
    Cookies.set(CART_UPDATED_COOKIE_NAME, Math.floor(Date.now() / 1000).toString(), {
        path: '/',
        domain: window.location.host.replace('account', 'www'),
        secure: true,
        sameSite: 'Lax',
    });
export const RETURN_STEP1 = '1';
export const RETURN_STEP2 = '2';

export const canOrderReturnPoshmark = (order: OMS.OrderRead) => {
    return (
        (order.status === 'delivered_to_customer' || order.status === 'delivered') &&
        order.addresses?.shipping?.countryCode === 'US'
    );
};

export const checkReturnDhl = ({ type }: OMS.ReturnMode) => type === 'dhl_express';
export const checkReturnSchneider = ({ type }: OMS.ReturnMode) => type === 'schneider';
