import { defineMessages, FormattedMessage } from 'react-intl';

import IconCross from '@components/Icons/IconCross';
import type { PickUp, PickUpOpeningPeriod } from 'types/apiOms';

type RelayDays = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';
interface RelayTooltipProps {
    onClose: () => void;
    relay: PickUp;
}

const messages = defineMessages<RelayDays>({
    monday: { id: 'shipping.relays.days.monday' },
    tuesday: { id: 'shipping.relays.days.tuesday' },
    wednesday: { id: 'shipping.relays.days.wednesday' },
    thursday: { id: 'shipping.relays.days.thursday' },
    friday: { id: 'shipping.relays.days.friday' },
    saturday: { id: 'shipping.relays.days.saturday' },
    sunday: { id: 'shipping.relays.days.sunday' },
});

const RelayTooltip = ({ onClose, relay }: RelayTooltipProps) => (
    <div className="absolute bottom-[calc(100%+0.75rem)] z-10 w-[26rem] -translate-x-1/2 border bg-white p-5 font-account-heading text-xs tracking-wide">
        <p className="font-semibold">{relay.pickUpAddress?.name}</p>
        <address className="mt-2 not-italic">
            {relay.pickUpAddress?.address}
            <br />
            {relay.pickUpAddress?.postcode} {relay.pickUpAddress?.city}
        </address>
        <table className="mt-2 w-auto max-w-full table-fixed border-collapse">
            <tbody>
                {Object.keys(relay.openingHours!).map(day => {
                    const dayHours = (relay.openingHours as Record<string, PickUpOpeningPeriod[]>)[day];
                    return (
                        <tr key={day}>
                            <td className="pr-4 pt-1">
                                <FormattedMessage id={messages[day as RelayDays].id} />
                            </td>
                            {dayHours.length ? (
                                dayHours.map((hours, index) => (
                                    <td key={`${day}-${index}`} colSpan={3 - dayHours.length}>
                                        {hours.start} - {hours.end}
                                    </td>
                                ))
                            ) : (
                                <td colSpan={2}>
                                    <FormattedMessage id="shipping.relays.closed" />
                                </td>
                            )}
                        </tr>
                    );
                })}
            </tbody>
        </table>
        <i
            className="absolute right-0 top-0 p-3 text-[0.4rem]"
            onClick={event => {
                event.stopPropagation(); // to prevent Google map triggers
                onClose();
            }}
        >
            <IconCross className="h-[1.8em] w-auto" />
        </i>
    </div>
);

export default RelayTooltip;
