import type { SVGProps } from 'react';

const IconChevron = (props: SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.32139 5.9999L0.910645 1.58916L2.08916 0.410645L7.67841 5.9999L2.08916 11.5892L0.910645 10.4106L5.32139 5.9999Z"
            fill="currentColor"
        />
    </svg>
);

export default IconChevron;
