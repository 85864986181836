import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';

import { useUrl } from '@hooks/useUrl';
import Tabs from './Tabs';

const WishlistsTabs = () => {
    const { routes } = useUrl();
    const location = useLocation();

    return (
        <Tabs
            items={[
                {
                    children: <FormattedMessage id="tabs.wishlists" />,
                    current: location.pathname === routes.wishlists,
                    to: routes.wishlists,
                },
                {
                    children: <FormattedMessage id="tabs.alerts" />,
                    to: routes.alerts,
                    current: location.pathname === routes.alerts,
                },
            ]}
        />
    );
};

export default WishlistsTabs;
