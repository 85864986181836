import type { SVGProps } from 'react';

const IconOrders = (props: SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.35577 2.55323C5.47871 1.82349 6.11421 1.28947 6.86501 1.28947C7.65967 1.28947 8.32597 1.8897 8.40866 2.68005L8.58486 4.36439H5.06379C5.16044 3.73219 5.26276 3.10529 5.35577 2.55323ZM3.57646 5.65385C3.51014 6.15819 3.45534 6.63235 3.42065 7.03701L4.70541 7.14713C4.74211 6.71895 4.80312 6.2039 4.87727 5.65385H8.71976L8.87724 7.15915L10.1597 7.02499L10.0163 5.65385H12.0977L12.5898 15.4642C12.6009 15.6852 12.4247 15.8704 12.2034 15.8704H1.67635C1.4551 15.8704 1.27891 15.6852 1.29 15.4642L1.78205 5.65385H3.57646ZM3.75954 4.36439C3.86677 3.65286 3.98204 2.94554 4.08422 2.33901C4.31353 0.977937 5.49553 0 6.86501 0C8.31989 0 9.53975 1.0989 9.69112 2.54589L9.88137 4.36439H12.7109C13.0545 4.36439 13.3376 4.63373 13.3549 4.97682L13.8776 15.3996C13.9257 16.3572 13.1622 17.1599 12.2034 17.1599H1.67635C0.717572 17.1599 -0.0458807 16.3572 0.00214825 15.3996L0.52492 4.97682C0.542129 4.63373 0.825322 4.36439 1.16884 4.36439H3.75954Z"
            fill="currentColor"
        />
    </svg>
);

export default IconOrders;
