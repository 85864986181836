import { FormattedMessage } from 'react-intl';

import { SecondTitle } from '@components/commons';
import InfoReturnBox from './InfoReturnBox';

interface DhlConfirmBoxProps {
    boxIndex: number;
}

const DhlConfirmBox = ({ boxIndex }: DhlConfirmBoxProps) => {
    return (
        <InfoReturnBox boxIndex={boxIndex}>
            <SecondTitle
                description={<FormattedMessage id="return.dhl_confirmation.box.description" values={{ br: <br /> }} />}
            >
                <FormattedMessage id="return.dhl_confirmation.box.title" />
            </SecondTitle>
        </InfoReturnBox>
    );
};

export default DhlConfirmBox;
