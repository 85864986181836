import { FormattedMessage } from 'react-intl';

import ReturnSection from '@components/Box/Returns/ReturnSection';
import IconPlace from '@components/Icons/IconPlace';
import { infoReturnBoxes, type ReturnBoxes } from './commons';

interface ReturnLabelProps {
    countryISO?: string | null;
    hasReturnLabel?: boolean;
    orderReference?: string;
    sectionCounter: boolean;
}

const returnLabelSchema: ReturnBoxes[] = ['packaging', 'return_label', 'deposit', 'dont_delay'];

export const ReturnLabelSection = ({
    countryISO,
    hasReturnLabel,
    orderReference,
    sectionCounter,
}: ReturnLabelProps) => (
    <ReturnSection
        title={<FormattedMessage id="orderReturn.return.detail.option.deposit" />}
        icon={IconPlace}
        counter={sectionCounter}
    >
        {returnLabelSchema.map(
            (box, index) =>
                infoReturnBoxes({
                    boxIndex: index + 1,
                    orderReference,
                    hasReturnLabel,
                    countryISO,
                })[box]
        )}
    </ReturnSection>
);
