import { getApiConfig, proxyHandler } from './api';
import { OMS } from 'types';

const apiClient = new OMS.Api(getApiConfig(process.env.REACT_APP_OMS_API_URL, process.env.REACT_APP_API_KEY));

type ApiMethods = typeof apiClient;

const createApiClientProxy = (client: OMS.Api<unknown>): ApiMethods => {
    return new Proxy(client, proxyHandler);
};

export default createApiClientProxy(apiClient);
