import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import IconCart from '@components/Icons/IconCart';
import IconChevron from '@components/Icons/IconChevron';
import IconSearch from '@components/Icons/IconSearch';
import LinkButton from '@components/Link/LinkButton';
import { useAppContext } from '@context/AppContext';
import { useCartContext } from '@context/CartContext';
import { useCountry } from '@context/CountryContext';
import { useGeolocContext } from '@context/GeolocContext';
import { useUrl } from '@hooks/useUrl';
import { useWindowWidth } from '@hooks/useWindowWidth';
import OctobreLogo from '@img/octobre.svg?react';
import SezaneLogo from '@img/sezane.svg?react';
import { BRANDS } from '@utils/brand';
import { formatCountryCurrencyLocale } from '@utils/formatters';
import { detectColor } from '@utils/image';
import { cn, OCTOBRE_HEADER_URL, SEZANE_HEADER_URL } from '@utils/main';
import type { MenuItemProps } from '@utils/menu';
import MenuMobile from '../MenuMobile/MenuMobile';
import CartOverlay from './CartOverlay';
import HeaderItem from './HeaderItem';

const headerMessages = defineMessages({
    backOrders: { id: 'header.back.orders' },
    backReturns: { id: 'header.back.returns' },
    backShopping: { id: 'header.back.shopping' },
    search: { id: 'header.menu.search' },
    wishlist: { id: 'header.menu.wishlist' },
});

interface HeaderProps {
    backButtonUrl?: string | null;
    isSimplified?: boolean;
    // for tests only
    initialBgImg?: string;
    initTextColor?: 'white' | 'black';
}

const Header = ({ backButtonUrl = null, initialBgImg, initTextColor, isSimplified }: HeaderProps) => {
    const { routes, urls } = useUrl();
    const { brandCode, locale, site } = useAppContext();
    const { country } = useCountry();
    const { cart } = useCartContext();
    const intl = useIntl();
    const width = useWindowWidth();

    const [bgImg, setBgImg] = useState<string | undefined>(isSimplified ? undefined : initialBgImg);
    const [textColor, setTextColor] = useState<'white' | 'black' | undefined>(isSimplified ? 'black' : initTextColor);

    const shippingCountry = site.shippingCountries?.find(c => c.code === country.countryISO);

    const productsCounter = cart?.products?.reduce((acc, product) => acc + product.quantity!, 0) || 0;
    const Logo = brandCode === BRANDS.sezane ? SezaneLogo : OctobreLogo;

    const backgroundUrl = brandCode === BRANDS.sezane ? SEZANE_HEADER_URL : OCTOBRE_HEADER_URL;

    const { openGeolocDialog } = useGeolocContext();

    const menuItems: Array<
        Omit<MenuItemProps, 'label'> & {
            id: string;
            displayLabel?: boolean;
            label?: string;
        }
    > = [
        ...(country.countryISO
            ? [
                  {
                      id: 'choose',
                      onClick: () => openGeolocDialog(true),
                      mobile: false,
                      label: formatCountryCurrencyLocale(
                          country.countryISO,
                          shippingCountry?.outputCurrencyCode! ?? shippingCountry?.currencyCode,
                          locale
                      ),
                  },
              ]
            : []),
        { icon: <IconSearch className="h-5 mobile:h-9" />, id: 'search', link: urls.search, displayLabel: false },
    ];

    const getBackLabel = () => {
        if (backButtonUrl) {
            if (backButtonUrl === routes.orders) return headerMessages.backOrders.id;
            if (backButtonUrl === routes.returns) return headerMessages.backReturns.id;
        }
        return headerMessages.backShopping.id;
    };

    useEffect(() => {
        if (isSimplified) {
            setBgImg(undefined);
        } else {
            fetch(backgroundUrl).then(async response => {
                const img = await response.blob();
                const imgUrl = (window.URL || window.webkitURL).createObjectURL(img);
                setBgImg(imgUrl || '');
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSimplified]);

    useEffect(() => {
        if (isSimplified) {
            setTextColor('black');
        } else {
            const setColor = () =>
                detectColor(backgroundUrl).then(color => {
                    setTextColor(color);
                });
            if (!bgImg) return;
            if (bgImg && width > 768 && process.env.NODE_ENV !== 'test') {
                setColor();
            } else setTextColor('black'); // Fallback to text black on mobile
        }
    }, [bgImg, backgroundUrl, isSimplified, width]);

    return (
        <header
            className={clsx(
                'header mobile:bg-none! flex w-full flex-col font-account-heading mobile:inset-x-0 mobile:top-0 mobile:py-0 desktop:px-24 desktop:py-[32px]',
                isSimplified ? '' : 'desktop:min-h-[40vh]'
            )}
            style={
                bgImg
                    ? {
                          backgroundImage: `url(${bgImg})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center top',
                          backgroundRepeat: 'no-repeat',
                      }
                    : {}
            }
        >
            {(isSimplified || bgImg) && textColor && (
                <>
                    <div className="relative z-10 flex w-full items-center large:container mobile:fixed mobile:h-[70px] mobile:bg-white mobile:px-8">
                        {!isSimplified && <MenuMobile />}
                        <div className={cn('col-span-4 grow basis-0', !isSimplified && 'mobile:hidden')}>
                            <LinkButton
                                color="tertiary"
                                to={isSimplified ? '/' : backButtonUrl || urls.ecommerce}
                                className={cn(
                                    'flex flex-row items-center pl-0',
                                    textColor === 'white' ? 'text-white' : 'text-black'
                                )}
                            >
                                <IconChevron className="mr-5 h-5 rotate-180 mobile:h-8" />
                                <span className="text-lg mobile:hidden">
                                    {isSimplified ? (
                                        <FormattedMessage id="header.back.home" />
                                    ) : (
                                        <FormattedMessage id={getBackLabel()} />
                                    )}
                                </span>
                            </LinkButton>
                        </div>
                        <div className="col-span-4 flex grow basis-0 justify-center">
                            <a href={urls.ecommerce}>
                                <Logo
                                    className={cn(
                                        'h-auto w-[171px] max-w-full',
                                        textColor === 'white' ? 'fill-white' : 'fill-black'
                                    )}
                                />
                            </a>
                        </div>
                        <div className="col-span-4 flex grow basis-0 flex-row justify-end gap-[1.6rem] text-sm ">
                            <div className="has-tooltip">
                                <Link
                                    className={cn(
                                        'flex flex-row items-end',
                                        textColor === 'white' ? 'text-white' : 'text-black'
                                    )}
                                    to={urls.cart}
                                >
                                    <span className={cn('m-1 flex desktop:hidden large:hidden')}>
                                        <IconCart className="h-5 mobile:h-9" />
                                        <span className="absolute inset-x-0 bottom-0 pb-[4px] text-center font-account-body text-xxs">
                                            {productsCounter}
                                        </span>
                                    </span>
                                    <span className="text-lg font-medium uppercase mobile:hidden">
                                        <FormattedMessage id="header.menu.cart" />
                                        <sup>{productsCounter}</sup>
                                    </span>
                                </Link>
                                <CartOverlay />
                            </div>
                            {site.code &&
                                menuItems.map(({ id, label, ...item }) => (
                                    <HeaderItem
                                        {...item}
                                        key={id}
                                        className={clsx(
                                            textColor === 'white' ? 'text-white' : 'text-black',
                                            'shrink-0 cursor-pointer underline-offset-4 hover:underline'
                                        )}
                                        label={
                                            label ??
                                            intl.formatMessage({
                                                id: headerMessages[id as keyof typeof headerMessages].id,
                                            })
                                        }
                                    />
                                ))}
                        </div>
                    </div>
                    {!isSimplified && (
                        <div className="flex h-full flex-1 items-center justify-center mobile:hidden">
                            <h2
                                className={cn(
                                    'text-center font-account-heading text-[80px] font-semibold mobile:text-[50px]',
                                    textColor === 'white' ? 'text-white' : 'text-black'
                                )}
                            >
                                <FormattedMessage id="header.menu.account" />
                            </h2>
                        </div>
                    )}
                </>
            )}
        </header>
    );
};

export default Header;
