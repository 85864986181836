import type { SVGProps } from 'react';

const IconPlace = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 0 26 23" width={29} height={23} fill="none" {...props}>
        <path
            fill="#000"
            d="M11.94 9a3.42 3.42 0 0 1-3.44 3.44A3.4 3.4 0 0 1 5.06 9c0-1.9 1.5-3.44 3.44-3.44 1.9 0 3.44 1.55 3.44 3.44ZM8.5 11.06c1.12 0 2.06-.9 2.06-2.06A2.1 2.1 0 0 0 8.5 6.94c-1.16 0-2.06.94-2.06 2.06 0 1.16.9 2.06 2.06 2.06ZM16.75 9c0 3.78-5.03 10.44-7.26 13.23a1.3 1.3 0 0 1-2.02 0C5.23 19.44.25 12.78.25 9a8.25 8.25 0 0 1 16.5 0ZM8.5 2.12A6.87 6.87 0 0 0 1.62 9c0 .69.22 1.59.7 2.75a23.75 23.75 0 0 0 1.88 3.52 61.23 61.23 0 0 0 4.3 6.02 70.26 70.26 0 0 0 4.25-6.02c.78-1.2 1.42-2.4 1.9-3.52.47-1.16.72-2.06.72-2.75A6.9 6.9 0 0 0 8.5 2.12Z"
        />
    </svg>
);

export default IconPlace;
