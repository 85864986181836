import { useState } from 'react';
import { clsx } from 'clsx';
import { useIntl } from 'react-intl';

import { useAppContext } from '@context/AppContext';
import { useCountry } from '@context/CountryContext';
import { useGeolocContext } from '@context/GeolocContext';
import { useUrl } from '@hooks/useUrl';
import { formatCountryCurrencyLocale } from '@utils/formatters';
import { getMenuData, logout, menuHomeMessages, menuMessages } from '../Menu/Menu';
import MenuMobileItem from './MenuMobileItem';

const MenuMobile = () => {
    const { brandCode, locale, site } = useAppContext();
    const { openGeolocDialog } = useGeolocContext();
    const { country } = useCountry();
    const { routes, urls } = useUrl();
    const intl = useIntl();

    const [isOpen, setIsOpen] = useState(false);

    const menuData = getMenuData(routes, urls.ecommerce);

    const shippingCountry = site.shippingCountries?.find(c => c.code === country.countryISO);

    const open = () => {
        // avoid scroll while menu is open
        document.body.classList.add('overflow-hidden');
        setIsOpen(true);
    };
    const close = () => {
        document.body.classList.remove('overflow-hidden');
        setIsOpen(false);
    };
    const toggleOpen = () => {
        if (isOpen) {
            close();
        } else {
            open();
        }
    };

    return (
        <div className="z-50 col-span-12 flex grow basis-0 justify-start desktop:hidden">
            <button data-testid="menumobile-header" className="relative h-[20px] w-[24px]" onClick={toggleOpen}>
                <span
                    className={clsx('absolute inset-x-0 top-0 h-[2px] bg-black transition-all', {
                        'top-[9px] rotate-45': isOpen,
                    })}
                />
                <span
                    className={clsx('absolute inset-x-0 top-1/2 h-[2px] translate-y-[-1px] bg-black transition-all', {
                        hidden: isOpen,
                    })}
                />
                <span
                    className={clsx('absolute inset-x-0 bottom-0 h-[2px] bg-black transition-all', {
                        'top-[9px] -rotate-45': isOpen,
                    })}
                />
            </button>
            {isOpen && (
                <nav className="fixed inset-x-0 bottom-0 top-[70px] overflow-auto bg-white p-10 pb-4 font-account-heading font-medium uppercase">
                    <ul>
                        {menuData.map(({ id, link }) => (
                            <MenuMobileItem
                                key={id}
                                label={intl.formatMessage(
                                    id === 'home' ? menuHomeMessages[brandCode] : menuMessages[id]
                                )}
                                link={link}
                                onClick={close}
                            />
                        ))}
                        {country.countryISO ? (
                            <MenuMobileItem
                                label={formatCountryCurrencyLocale(
                                    country.countryISO,
                                    shippingCountry?.outputCurrencyCode! ?? shippingCountry?.currencyCode,
                                    locale
                                )}
                                onClick={() => openGeolocDialog(true)}
                                className="mt-10"
                            />
                        ) : null}
                        <MenuMobileItem
                            label={intl.formatMessage({ id: 'menu.logout' })}
                            onClick={logout(brandCode, site)}
                            className="mt-10"
                        />
                    </ul>
                </nav>
            )}
        </div>
    );
};

export default MenuMobile;
