import clsx from 'clsx';
import { FormattedMessage, type MessageDescriptor } from 'react-intl';

import { cn } from '@utils/main';
import type { TimelineStatuses } from '@utils/order';
import './horizontaltimeline.css';

interface HorizontalTimelineTypes<Status extends string> {
    displayedTrackingStatus: Status[];
    currentTrackingStatus: Status;
    statusMessages: Record<Status, MessageDescriptor>;
    className?: string;
}

const HorizontalTimeline = ({
    displayedTrackingStatus,
    currentTrackingStatus,
    statusMessages,
    className,
}: HorizontalTimelineTypes<TimelineStatuses>) => {
    const trackingReturnStatusIndex = displayedTrackingStatus.indexOf(currentTrackingStatus);

    if (trackingReturnStatusIndex === -1 && currentTrackingStatus !== 'canceled') return null;

    return (
        <div className={cn('flex w-full mobile:col-span-full', className)}>
            {trackingReturnStatusIndex >= 0
                ? displayedTrackingStatus.map((stepStatus, i) => {
                      const inProgress = displayedTrackingStatus[trackingReturnStatusIndex] === stepStatus;
                      return (
                          <div key={`returnStatus${stepStatus}`} className="relative w-full grow">
                              <div
                                  className={clsx(
                                      'horizontal-timeline-step font-account-subheading text-xs uppercase',
                                      i > trackingReturnStatusIndex && 'horizontal-timeline-future text-gray-lighter',
                                      i === 0
                                          ? 'horizontal-timeline-first text-left'
                                          : i + 1 === displayedTrackingStatus.length
                                          ? 'horizontal-timeline-last text-right'
                                          : `horizontal-timeline-center-${displayedTrackingStatus.length}-${i} text-center`,
                                      inProgress && 'in-progress'
                                  )}
                              >
                                  <FormattedMessage id={statusMessages[stepStatus].id as MessageDescriptor['id']} />
                              </div>
                          </div>
                      );
                  })
                : null}
            {currentTrackingStatus === 'canceled' && (
                <span className="font-account-subheading text-sm uppercase text-gray-medium">
                    <FormattedMessage id={statusMessages.canceled.id} />
                </span>
            )}
        </div>
    );
};

export default HorizontalTimeline;
