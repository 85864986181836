import { useEffect, useRef } from 'react';

const useOutsideClick = (callback: Function) => {
    const ref = useRef<HTMLElement>();

    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback();
            }
        };

        document.addEventListener('click', handleClick, true);

        return () => {
            document.removeEventListener('click', handleClick, true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);

    return ref;
};

export default useOutsideClick;
