import type { SVGProps } from 'react';

const IconEmail = (props: SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 48 47" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M47.022 20.2A23.257 23.257 0 1 0 .94 26.524 23.421 23.421 0 0 0 20.905 46.55c1.035.135 2.077.204 3.12.204a23.027 23.027 0 0 0 11.958-3.326 2.249 2.249 0 0 0-.619-4.111 2.25 2.25 0 0 0-1.703.256A18.75 18.75 0 1 1 26.478 4.91 18.75 18.75 0 0 1 41.72 29.642a3.249 3.249 0 0 1-3.119 2.109 3.357 3.357 0 0 1-3.352-3.353V14.501a2.25 2.25 0 1 0-4.5 0v.02a11.25 11.25 0 1 0 .811 17.285 7.836 7.836 0 0 0 7.041 4.445 7.75 7.75 0 0 0 7.371-5.136 23.301 23.301 0 0 0 1.05-10.915ZM24 30.25a6.75 6.75 0 1 1 6.75-6.75A6.757 6.757 0 0 1 24 30.25Z"
            fill="currentColor"
        />
    </svg>
);

export default IconEmail;
