import { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import Loader from '@components/Loader/Loader';
import { AppContextProvider } from '@context/AppContext';
import { AuthProvider } from '@context/AuthContext';
import { CartProvider } from '@context/CartContext';
import { CountryProvider } from '@context/CountryContext';
import { GeolocProvider } from '@context/GeolocContext';
import { GlobalEProvider } from '@context/GlobalEContext';
import TranslationProvider from '@context/TranslationProvider';
import { logAppVersion } from '@utils/logs';
import { getBrand } from './utils/brand';
import MainContainer from './MainContainer';
import './styles/main.css';

logAppVersion();

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
        },
    },
});

function App() {
    const brandCode = getBrand();

    return (
        <Suspense fallback={<Loader />}>
            <QueryClientProvider client={queryClient}>
                <AppContextProvider brandCode={brandCode}>
                    <AuthProvider>
                        <TranslationProvider>
                            <CountryProvider>
                                <HelmetProvider>
                                    <GeolocProvider>
                                        <GlobalEProvider>
                                            <CartProvider>
                                                <MainContainer />
                                            </CartProvider>
                                        </GlobalEProvider>
                                    </GeolocProvider>
                                </HelmetProvider>
                            </CountryProvider>
                        </TranslationProvider>
                    </AuthProvider>
                </AppContextProvider>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </Suspense>
    );
}

export default App;
