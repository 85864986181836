import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';

import { useUrl } from '@hooks/useUrl';
import Tabs from './Tabs';

const OrdersTabs = () => {
    const { routes } = useUrl();
    const location = useLocation();

    return (
        <Tabs
            items={[
                {
                    children: <FormattedMessage id="tabs.orders" />,
                    current: location.pathname === routes.orders,
                    to: routes.orders,
                },
                {
                    children: <FormattedMessage id="tabs.returns" />,
                    to: routes.returns,
                    current: location.pathname === routes.returns,
                },
            ]}
        />
    );
};

export default OrdersTabs;
