import { FormattedMessage } from 'react-intl';

import { PageHeader } from '@components/commons';
import LinkButton from '@components/Link/LinkButton';
import { useAuth } from '@context/AuthContext';
import { useUrl } from '@hooks/useUrl';
import MyAddresses from './MyAddresses';
import MyInformations from './MyInformations';

interface DescriptionProps {
    email?: string;
}

const Description = ({ email }: DescriptionProps) => (
    <>
        <FormattedMessage id="informations.description.account" values={{ email }} />
    </>
);

const ModifyButtons = () => {
    const { urls } = useUrl();
    return (
        <>
            <LinkButton color="secondary" href={urls.keycloak.email} className="w-full">
                <FormattedMessage id="informations.modify.email" />
            </LinkButton>
            <LinkButton color="secondary" href={urls.keycloak.password} className="w-full">
                <FormattedMessage id="informations.modify.password" />
            </LinkButton>
        </>
    );
};

const InfoPage = () => {
    const { user } = useAuth();

    return (
        <>
            <PageHeader
                title="informations.title"
                description={<Description email={user!.email} />}
                column={<ModifyButtons />}
            >
                <FormattedMessage id="informations.title" />
            </PageHeader>
            <MyInformations />
            <MyAddresses />
        </>
    );
};

export default InfoPage;
