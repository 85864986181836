import type { UseFormReturn } from 'react-hook-form';
import { type CalendarDate, parseAbsolute, parseAbsoluteToLocal } from '@internationalized/date';

import type { OMS } from 'types';

export type ReturnReason = NonNullable<OMS.References['order_return_motives']>[0];

export type ExtendedOrderProduct = OMS.OrderProduct & { orderIndex: number };

export type ReturnProduct = {
    product: ExtendedOrderProduct;
    reason?: ReturnReason;
};

export type ReturnProducts = Record<string, ReturnProduct>;

// TODO: get this from api specs
export type DhlSlot = {
    id?: string;
    startTime?: string;
    closeTime?: string;
};

export type Timezone = {
    id: string;
    value: string;
};

export interface FormValues {
    address?: OMS.Address;
    order?: OMS.OrderRead;
    pickupDate?: CalendarDate | null;
    pickupSlot?: DhlSlot;
    products?: ReturnProducts;
    refundOption?: OMS.OrderReturnWrite['refundOption'];
    returnMode?: OMS.ReturnMode;
    timezone?: Timezone;
    withTimezone?: boolean;
}

const COMMON_STEPS = ['step_order', 'step_products', 'step_refund'];
export const getCreateReturnSteps = (isDhl?: boolean) =>
    isDhl ? [...COMMON_STEPS, 'step_dhl_pickup', 'step_dhl_slot'] : [...COMMON_STEPS, 'step_confirm'];

type StepsConfiguration = {
    canSubmit?: () => boolean;
    checkErrors?: (callBack?: () => Promise<any>) => void;
};

export const getStepsConfiguration = (
    formValues: FormValues,
    methods: UseFormReturn<FormValues>
): Record<string, StepsConfiguration> => ({
    step_order: {
        canSubmit: () => !!formValues.order,
    },
    step_products: {
        canSubmit: () => {
            const products = Object.values(formValues.products || {});
            return products.length > 0;
        },
        checkErrors: () => {
            const errors = Object.values(formValues.products || {})
                .filter(({ reason }) => !reason)
                .map(({ product }) => product)
                .reduce<Record<string, any>>(
                    (errors, product) => ({
                        ...errors,
                        [getExtendedProductId(product)]: 'missing_reason',
                    }),
                    {}
                );

            if (Object.values(errors).length > 0) {
                return methods.setError('products', errors);
            }
        },
    },
    step_refund: {
        canSubmit: () => !!formValues.refundOption,
    },
    step_confirm: {},
    step_dhl_pickup: {
        checkErrors: async checkDhlAddress => {
            const isValid = await methods.trigger(['address', 'timezone']);

            if (isValid && checkDhlAddress) {
                return checkDhlAddress();
            }
        },
    },
    step_dhl_slot: {},
});

export const getExtendedProductId = (product: ExtendedOrderProduct) => `${product.ean}-${product.orderIndex}`;

export const splitOrderItems = (
    items: OMS.OrderProduct[] | undefined,
    getQuantity: (item: OMS.OrderProduct) => number
) => {
    if (!items) return [];

    const splittedItems: ExtendedOrderProduct[] = [];

    let orderIndex = 0;
    items.forEach(item => {
        Array(getQuantity(item))
            .fill(null)
            .forEach(() => {
                splittedItems.push({
                    ...item,
                    orderIndex,
                });
                orderIndex += 1;
            });
    });

    return splittedItems;
};

export const mapCreateValues = ({
    address,
    pickupSlot,
    products,
    refundOption,
    returnMode,
}: FormValues): OMS.OrderReturnWrite => ({
    refundOption: refundOption!,
    returnedItems: products
        ? Object.values(products).map(({ product, reason }) => ({
              expectedQuantity: 1,
              returnMotive: reason?.name as OMS.OrderReturnItemWrite['returnMotive'],
              orderLineItem: {
                  uid: product.orderItemProductId!,
              },
          }))
        : [],

    // For DHL / Schneider
    returnMode: returnMode ? { uid: returnMode.uid! } : undefined,

    // For DHL
    pickupAddress: {
        ...address,
        countryCode: address?.countryCode?.split('-')[0],
        state: address?.countryCode?.split('-')[1] || address?.state || null,
    },
    pickupDateAndTime: pickupSlot?.startTime,
    pickupClosingTime: pickupSlot?.closeTime,
});

export const parseZonedDate = (dateStr: string, timezone?: Timezone) =>
    timezone ? parseAbsolute(dateStr, timezone?.value) : parseAbsoluteToLocal(dateStr);

export const parseTimezoneDate = (dateStr: string, timezone?: Timezone) => {
    const parsedDate = parseZonedDate(dateStr, timezone);
    const localTimezoneOffset = new Date().getTimezoneOffset();
    return new Date(new Date(dateStr).getTime() + parsedDate.offset + localTimezoneOffset * 60 * 1000);
};

export const formatSlotDate = (date: Date, locale: string, withoutYear?: boolean) =>
    date.toLocaleDateString(locale, {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: withoutYear ? undefined : 'numeric',
    });

export const formatSlotTime = (date: Date, locale: string) =>
    date.toLocaleTimeString(locale, {
        hour: '2-digit',
        minute: '2-digit',
    });
