import type { SVGProps } from 'react';

const IconCart = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 14 18" {...props}>
        <path
            fillRule="evenodd"
            d="M5.356 2.553A1.522 1.522 0 0 1 6.865 1.29c.795 0 1.461.6 1.544 1.391l.176 1.684H5.064c.096-.632.199-1.259.292-1.81Zm-1.78 3.1c-.066.505-.12.98-.155 1.384l1.284.11c.037-.428.098-.943.172-1.493H8.72l.157 1.505 1.283-.134-.144-1.371h2.082l.492 9.81a.387.387 0 0 1-.387.406H1.676a.387.387 0 0 1-.386-.406l.492-9.81h1.794Zm.184-1.289c.107-.711.222-1.418.324-2.025A2.812 2.812 0 0 1 6.865 0a2.842 2.842 0 0 1 2.826 2.546l.19 1.818h2.83c.344 0 .627.27.644.613l.523 10.423a1.676 1.676 0 0 1-1.675 1.76H1.676A1.676 1.676 0 0 1 .002 15.4L.525 4.977a.645.645 0 0 1 .644-.613h2.59Z"
            clipRule="evenodd"
        />
    </svg>
);
export default IconCart;
