import { useAppContext } from '@context/AppContext';
import { DEFAULT_SITECODE } from '@utils/common';
import { RETURN_STEP1, RETURN_STEP2 } from '@utils/order';
import type { Site } from 'types';

export type Routes = {
    alerts: string;
    care: string;
    contact: string;
    credits: string;
    deleteAccount: string;
    info: string;
    orders: string;
    payment: string;
    returnCreate: string;
    returnDefect: string;
    returns: string;
    wishlists: string;
};

interface ReturnStep {
    step: 1 | 2;
    orderId?: string;
}

export type Urls = {
    account: string;
    ecommerce: string;
    ecommerceRoot: string;
    cart: string;
    search: string;
    return: (returnStep: ReturnStep) => string;
    faq: {
        root: string;
        shipping: string;
        returns: string;
        return_conditions: string;
        return_refunds: string;
        return_second: string;
        orders: string;
        refund: string;
        refund_delay: string;
        fabrication: string;
        shipping_conditions: string;
        payment: string;
        defect: string;
        exchange: string;
    };
    keycloak: {
        email: string;
        password: string;
    };
    privacyPolicy: string;
    contact: string;
};

interface ReturnUrlProps {
    baseUrl: string;
    siteCode: string;
    locale: string;
}

export const getAccountUrl = (host: string = window.location.host) =>
    `https://${host.replace('account', 'shop').replace('www', 'shop')}`;

export const getKeycloakUrl = (host: string = window.location.host) =>
    // auth.octobre-editions doesn't exist
    `https://${host.replace('account', 'auth').replace('www', '').replace('octobre-editions', 'sezane')}`;

export const getRoutes = (siteCode?: string, locale?: string): Routes => {
    switch (siteCode) {
        case 'fr':
            return {
                alerts: '/alertes',
                care: '/conseils-entretien',
                contact: '/contact',
                credits: '/avoirs-ecartes',
                deleteAccount: '/supprimer-compte',
                info: '/informations',
                orders: '/commandes',
                payment: '/methodes-paiement',
                returnCreate: '/retours/create',
                returnDefect: '/retours/defect',
                returns: '/retours',
                wishlists: '/wishlists',
            };
        case 'es':
            return {
                alerts: '/alertas',
                care: '/instrucciones-de-cuidado',
                contact: '/contacto',
                credits: '/creditos-tarjetas',
                deleteAccount: '/eliminar-cuenta',
                info: '/informaciones',
                orders: '/pedidos',
                payment: '/metodos-de-pago',
                returnCreate: '/devoluciones/create',
                returnDefect: '/devoluciones/defect',
                returns: '/devoluciones',
                wishlists: '/listas-de-deseos',
            };
        case 'de':
            return {
                alerts: '/warnungen',
                care: '/pflegehinweise',
                contact: '/kontakt',
                credits: '/guthaben-e-karten',
                deleteAccount: '/konto-loeschen',
                info: '/informationen',
                orders: '/bestellungen',
                payment: '/zahlungsmethoden',
                returnCreate: '/ruckgaben/create',
                returnDefect: '/ruckgaben/defect',
                returns: '/ruckgaben',
                wishlists: '/wunschlisten',
            };
        case 'it':
            return {
                alerts: '/allarmi',
                care: '/istruzioni-cura',
                contact: '/contatto',
                credits: '/crediti-e-carte',
                deleteAccount: '/elimina-account',
                info: '/informazioni',
                orders: '/ordini',
                payment: '/metodi-di-pagamento',
                returnCreate: '/resi/create',
                returnDefect: '/resi/defect',
                returns: '/resi',
                wishlists: '/liste-desideri',
            };
        case 'pt':
            return {
                alerts: '/alertas',
                care: '/instrucoes-cuidado',
                contact: '/contato',
                credits: '/creditos-e-cartoes',
                deleteAccount: '/excluir-conta',
                info: '/informacoes',
                orders: '/pedidos',
                payment: '/metodos-de-pagamento',
                returnCreate: '/devolucoes/create',
                returnDefect: '/devolucoes/defect',
                returns: '/devolucoes',
                wishlists: '/listas-de-desejos',
            };
        case 'nl':
            return {
                alerts: '/waarschuwingen',
                care: '/onderhoudsinstructies',
                contact: '/contact',
                credits: '/credits-e-cards',
                deleteAccount: '/account-verwijderen',
                info: '/informatie',
                orders: '/bestellingen',
                payment: '/betaalmethoden',
                returnCreate: '/retouren/create',
                returnDefect: '/retouren/defect',
                returns: '/retouren',
                wishlists: '/verlanglijstjes',
            };
        case 'eu':
            return getRoutes(locale);
        default:
            return {
                alerts: '/alerts',
                care: '/care-instructions',
                contact: '/contact',
                credits: '/credits-ecards',
                deleteAccount: '/delete-account',
                info: '/informations',
                orders: '/orders',
                payment: '/payment-methods',
                returnCreate: '/returns/create',
                returnDefect: '/returns/defect',
                returns: '/returns',
                wishlists: '/wishlists',
            };
    }
};

export const getSearchPageUrl = (baseUrl: string, siteCode?: string, locale?: string): string => {
    switch (siteCode) {
        case 'fr':
            return `${baseUrl}/recherche`;
        case 'de':
            return `${baseUrl}/suche`;
        case 'es':
            return `${baseUrl}/buscar`;
        case 'it':
            return `${baseUrl}/cerca`;
        case 'pt':
            return `${baseUrl}/pesquisa`;
        case 'nl':
            return `${baseUrl}/zoeken`;
        case 'eu':
            return getSearchPageUrl(baseUrl, locale);
        default:
            return `${baseUrl}/search`;
    }
};

export const getFaqUrls = (baseUrl: string, locale: string): Urls['faq'] => {
    switch (locale) {
        case 'fr':
            return {
                root: `${baseUrl}/help`,
                shipping: `${baseUrl}/help?thematic=livraison`,
                returns: `${baseUrl}/help?question=comment-faire-un-retour-77782`,
                return_conditions: `${baseUrl}/help?question=quelles-sont-les-conditions-pour-un-retour`,
                return_refunds: `${baseUrl}/help?thematic=retour-echange-and-remboursement`,
                return_second: `${baseUrl}/help?question=comment-faire-un-second-retour`,
                refund: `${baseUrl}/help?question=quand-vais-je-recevoir-mon-remboursement`,
                orders: `${baseUrl}/help?question=ou-en-est-ma-commande`,
                fabrication: `${baseUrl}/help?question=comment-en-savoir-plus-sur-la-fabrication-de-nos-pieces`,
                shipping_conditions: `${baseUrl}/help?quels-sont-les-modes-delais-et-frais-de-livraison`,
                payment: `${baseUrl}/help?thematic=commande-and-paiement`,
                defect: `${baseUrl}/help?question=mon-produit-semble-defectueux-que-faire`,
                refund_delay: `${baseUrl}/help?question=quand-vais-je-recevoir-mon-remboursement`,
                exchange: `${baseUrl}/help?question=puis-je-faire-un-echange`,
            };
        case 'de':
            return {
                root: `${baseUrl}/help`,
                shipping: `${baseUrl}/help?thematic=lieferung`,
                returns: `${baseUrl}/help?question=wie-kann-ich-eine-retoure-vornehmen-77782`,
                return_conditions: `${baseUrl}/help?question=welche-voraussetzungen-mussen-fur-eine-retoure-erfullt-sein`,
                return_refunds: `${baseUrl}/help?thematic=ruckgabe-umtausch-and-ruckerstattung`,
                return_second: `${baseUrl}/help?question=wie-mache-ich-eine-zweite-rückkehr`,
                refund: `${baseUrl}/help?question=wann-erhalte-ich-meine-ruckerstattungt`,
                orders: `${baseUrl}/help?question=wie-weit-ist-meine-bestellung`,
                fabrication: `${baseUrl}/help?question=wie-kann-ich-mehr-uber-die-herstellung-der-stucke-von-sezane-und-octobre-erfahren`,
                shipping_conditions: `${baseUrl}/help?question=welche-lieferfristen-arten-und-kosten-gibt-es`,
                payment: `${baseUrl}/help?thematic=bestellung-and-zahlung`,
                defect: `${baseUrl}/help?question=mein-artikel-scheint-beschadigt-zu-sein-was-kann-ich-tun`,
                refund_delay: `${baseUrl}/help?question=wann-erhalte-ich-meine-ruckerstattung`,
                exchange: `${baseUrl}/help?question=kann-ich-meinen-artikel-umtauschen`,
            };
        case 'es':
            return {
                root: `${baseUrl}/help`,
                shipping: `${baseUrl}/help?thematic=entrega`,
                returns: `${baseUrl}/help?question=como-hacer-una-devolucion-77782`,
                return_conditions: `${baseUrl}/help?question=cuales-son-las-condiciones-de-devolucion`,
                return_refunds: `${baseUrl}/help?thematic=devolucion-cambio-y-reembolso`,
                return_second: `${baseUrl}/help?question=como-hacer-una-segunda-devolucion`,
                refund: `${baseUrl}/help?question=cuando-recibire-el-reembolso`,
                orders: `${baseUrl}/help?question=donde-esta-mi-pedido`,
                fabrication: `${baseUrl}/help?question=como-puedo-saber-mas-sobre-confeccion-los-articulos-sezane-octobre`,
                shipping_conditions: `${baseUrl}/help?question=cuales-son-los-plazos-metodos-y-costes-de-entrega`,
                payment: `${baseUrl}/help?thematic=pedido-y-pago`,
                defect: `${baseUrl}/help?question=mi-prenda-parece-estar-defectuosa-que-debo-hacer`,
                refund_delay: `${baseUrl}/help?question=cuando-recibire-el-reembolso`,
                exchange: `${baseUrl}/help?question=puedo-hacer-un-cambio`,
            };
        case 'it':
            return {
                root: `${baseUrl}/help`,
                shipping: `${baseUrl}/help?thematic=consegna`,
                returns: `${baseUrl}/help?question=come-fare-un-reso-77782`,
                return_conditions: `${baseUrl}/help?question=quali-sono-le-condizioni-per-il-reso`,
                return_refunds: `${baseUrl}/help?thematic=reso-scambio-and-rimborso`,
                return_second: `${baseUrl}/help?question=como-fare-un-secondo-reso`,
                refund: `${baseUrl}/help?question=quando-ricevero-il-rimborso`,
                orders: `${baseUrl}/help?question=dove-si-trova-l-ordine`,
                fabrication: `${baseUrl}/help?question=come-posso-ottenere-maggiori-informazioni-su-come-vengono-realizzati-gli-articoli-sezane-e-octobre`,
                shipping_conditions: `${baseUrl}/help?question=quali-sono-i-tempi-le-modalita-e-i-costi-di-consegna`,
                payment: `${baseUrl}/help?thematic=ordine-and-pagamento`,
                defect: `${baseUrl}/help?question=sembra-che-il-mio-articolo-sia-difettoso-cosa-devo-fare`,
                refund_delay: `${baseUrl}/help?question=quando-ricevero-il-rimborso`,
                exchange: `${baseUrl}/help?question=posso-fare-un-cambio`,
            };
        case 'pt':
            return {
                root: `${baseUrl}/help`,
                shipping: `${baseUrl}/help?thematic=entrega`,
                returns: `${baseUrl}/help?question=como-efetuar-uma-devolucao-77782`,
                return_conditions: `${baseUrl}/help?question=quais-sao-as-condicoes-para-uma-devolucao`,
                return_refunds: `${baseUrl}/help?thematic=devolucao-troca-and-reembolso`,
                return_second: `${baseUrl}/help?question=como-fazer-um-segundo-retorno`,
                refund: `${baseUrl}/help?question=quando-e-que-recebo-o-meu-reembolso`,
                orders: `${baseUrl}/help?question=onde-esta-a-minha-encomenda`,
                fabrication: `${baseUrl}/help?question=como-posso-obter-informacoes-sobre-o-fabrico-das-pecas-sezane-e-octobre`,
                shipping_conditions: `${baseUrl}/help?question=quais-sao-os-prazos-metodos-e-custos-de-entrega`,
                payment: `${baseUrl}/help?thematic=pedido-and-pagamento`,
                defect: `${baseUrl}/help?question=sa-minha-peca-parece-estar-defeituosa-o-que-devo-fazer`,
                refund_delay: `${baseUrl}/help?question=quando-e-que-recebo-o-meu-reembolso`,
                exchange: `${baseUrl}/help?posso-efetuar-uma-troca`,
            };
        case 'nl':
            return {
                root: `${baseUrl}/help`,
                shipping: `${baseUrl}/help?thematic=levering`,
                returns: `${baseUrl}/help?question=hoe-kan-ik-een-retourzending-uitvoeren-77782`,
                return_conditions: `${baseUrl}/help?question=wat-zijn-de-voorwaarden-voor-een-retourzending`,
                return_refunds: `${baseUrl}/help?thematic=retourneren-uitwisselen-and-terugbetaling`,
                return_second: `${baseUrl}/help?question=hoe-maak-ik-een-tweede-retourzendin`,
                refund: `${baseUrl}/help?question=wanneer-krijg-ik-mijn-geld-terug`,
                orders: `${baseUrl}/help?question=waar-is-mijn-bestelling`,
                fabrication: `${baseUrl}/help?question=hoe-kan-ik-meer-weten-komen-over-hoe-producten-van-sezane-octobre-worden-gemaakt`,
                shipping_conditions: `${baseUrl}/help?question=wat-zijn-de-levertijden-methoden-en-kosten`,
                payment: `${baseUrl}/help?thematic=bestelling-and-betaling`,
                defect: `${baseUrl}/help?question=mijn-artikel-lijkt-defect-wat-moet-ik-doen`,
                refund_delay: `${baseUrl}/help?question=wanneer-krijg-ik-mijn-geld-terug`,
                exchange: `${baseUrl}/help?kan-ik-omruilen`,
            };
        default:
            return {
                root: `${baseUrl}/help`,
                shipping: `${baseUrl}/help?thematic=delivery`,
                returns: `${baseUrl}/help?question=how-do-i-return-a-product`,
                return_conditions: `${baseUrl}/help?question=what-are-the-terms-for-a-return`,
                return_refunds: `${baseUrl}/help?thematic=return-exchange-and-refund`,
                return_second: `${baseUrl}/help?question=how-to-do-a-second-return`,
                refund: `${baseUrl}/help?question=when-will-i-receive-my-refund`,
                orders: `${baseUrl}/help?question=where-is-my-order`,
                fabrication: `${baseUrl}/help?question=how-can-i-find-out-more-about-how-sezane-and-octobre-products-are-made`,
                shipping_conditions: `${baseUrl}/help?question=what-are-the-delivery-times-methods-and-costs`,
                payment: `${baseUrl}/help?thematic=order-and-payment`,
                defect: `${baseUrl}/help?question=my-item-seems-to-be-faulty-what-should-i-do`,
                refund_delay: `${baseUrl}/help?question=when-will-i-receive-my-refund`,
                exchange: `${baseUrl}/help?question=can-i-exchange-an-item`,
            };
    }
};

const getCartUrl = (baseUrl: string, siteCode: string) => {
    const shopUrl = getAccountUrl();
    return process.env.NODE_ENV === 'development' ? `${baseUrl}/checkout` : `${shopUrl}/${siteCode}/`;
};

export const getPrivacyPolicyUrl = (baseUrl: string, siteCode: string, locale: string): string => {
    switch (siteCode) {
        case 'fr':
            return `${baseUrl}/politique-de-confidentialite-des-donnees`;
        case 'de':
            return `${baseUrl}/datenschutzrichtlinie`;
        case 'es':
            return `${baseUrl}/politica-de-confidencialidad-de-datos`;
        case 'it':
            return `${baseUrl}/informativa-sulla-privacy-per-il-trattamento-dei-dati-personali`;
        case 'pt':
            return `${baseUrl}/politica-de-confidencialidade-dos-dados`;
        case 'nl':
            return `${baseUrl}/privacybeleid`;
        case 'eu':
            return getPrivacyPolicyUrl(baseUrl, locale, locale);
        default:
            return `${baseUrl}/privacy-policy`;
    }
};

export const getReturnStep =
    (url: string) =>
    ({ orderId, step }: ReturnStep) => {
        if (step === 1 || !orderId) return `${url + RETURN_STEP1}`;
        else return `${url + RETURN_STEP2}?orderId=${orderId}`;
    };

export const getReturnUrl = ({ baseUrl, siteCode, locale }: ReturnUrlProps): ((returnStep: ReturnStep) => string) => {
    switch (siteCode) {
        case 'fr':
            return getReturnStep(`${baseUrl}/mon-compte/retour/etape`);
        case 'de':
            return getReturnStep(`${baseUrl}/mein-konto/rueckgabe/schritt`);
        case 'es':
            return getReturnStep(`${baseUrl}/mi-cuenta/devolucion/etapa`);
        case 'it':
            return getReturnStep(`${baseUrl}/il-mio-account/reso/fase`);
        case 'pt':
            return getReturnStep(`${baseUrl}/minha-conta/devolucao/etapa`);
        case 'nl':
            return getReturnStep(`${baseUrl}/mijn-account/retourzending/stap`);
        case 'eu':
            return getReturnUrl({ baseUrl, siteCode: locale, locale });
        default:
            return getReturnStep(`${baseUrl}/my-account/return/step`);
    }
};

const getUrls = (frontUrl: string, site: Site, siteSegment: string, locale: string): Urls => {
    const siteCode = site.code || DEFAULT_SITECODE;
    const basePath = process.env.REACT_APP_BASE_PATH || '';
    const baseUrl = `${frontUrl}/${siteSegment}`;
    const accountBaseUrl = `/${siteSegment}`;

    return {
        account: `${accountBaseUrl}${basePath}`,
        ecommerce: baseUrl,
        ecommerceRoot: frontUrl,
        cart: getCartUrl(baseUrl, siteCode),
        search: getSearchPageUrl(baseUrl, siteCode, locale),
        faq: getFaqUrls(baseUrl, locale),
        contact: `${baseUrl}/contact`,
        return: getReturnUrl({ baseUrl, siteCode, locale }),
        keycloak: {
            email: `${getKeycloakUrl()}/auth/realms/customer/account`,
            password: `${getKeycloakUrl()}/auth/realms/customer/account/password`,
        },
        privacyPolicy: getPrivacyPolicyUrl(baseUrl, siteCode, locale),
    };
};

export const useUrl = () => {
    const { frontUrl, locale, site, siteSegment } = useAppContext();
    const urls = getUrls(frontUrl, site, siteSegment, locale);
    const routes = getRoutes(site.code, locale);

    return {
        urls,
        routes,
    };
};
