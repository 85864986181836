import type { FC, ReactNode } from 'react';
import { FormattedMessage, type MessageDescriptor, useIntl } from 'react-intl';

import { cn } from '@utils/main';
import Button from '../Button/Button';
import TrashIcon from '../Icons/IconTrash';
import { Box } from './Box';
import cardTypes from './cardTypes';
import type { OMS } from 'types';

interface BoxCardProps {
    card: OMS.PaymentMethod;
    deletePaymentMethod: (reference: string) => void;
    isLoading?: boolean;
    src?: string | null;
    svg?: ((title: string) => ReactNode) | null;
}

export const BoxCard: FC<BoxCardProps> = ({ card, deletePaymentMethod, isLoading, src, svg }) => {
    const intl = useIntl();
    const formattedCardType = cardTypes[card.type!]
        ? intl.formatMessage({
              id: `payments.card_type.${card.type}` as MessageDescriptor['id'],
          })
        : card.type ?? 'unknown';

    return (
        <Box className="mobile:flow-row mb-4 grid grid-cols-4 items-center justify-between border-b font-account-heading text-base uppercase mobile:auto-rows-min mobile:grid-cols-2 mobile:gap-y-4 mobile:border mobile:px-4 desktop:p-[1.4rem]">
            <div className="mobile:order-3">XXXX - XXXX - XXXX - {card.number}</div>
            <div className="mobile:order-4">
                {card.expiryMonth} / {card.expiryYear}
            </div>
            <div className="mobile:order-1 mobile:self-center">
                {src ? (
                    <img
                        className="h-16 min-w-[66px] rounded border border-gray-lightest p-2"
                        src={src}
                        alt={formattedCardType}
                        title={formattedCardType}
                    />
                ) : (
                    <div className={cn('h-16 w-fit rounded border border-gray-lightest px-5', svg ? 'py-2' : 'py-4')}>
                        {svg ? svg(formattedCardType) : <span>{formattedCardType}</span>}
                    </div>
                )}
            </div>
            <div className="mobile:order-2 mobile:row-span-3 mobile:self-end mobile:justify-self-end">
                <Button
                    color="tertiary"
                    className="group flex items-center gap-5 pl-0 pr-4 hover:text-error mobile:pb-2 mobile:pt-4 desktop:py-4"
                    onClick={() => deletePaymentMethod(card.reference!)}
                    isLoading={isLoading}
                >
                    <FormattedMessage id="payments.methods.delete" />
                    <TrashIcon className="-translate-y-1 fill-gray-light group-hover:fill-error" />
                </Button>
            </div>
        </Box>
    );
};
