import { FormattedMessage, type MessageDescriptor } from 'react-intl';

import { cn } from '@utils/main';

export type headProps = { name: MessageDescriptor; className?: string };

interface ListHeaderProps {
    formatHeader: headProps[];
}
const ListHeader = ({ formatHeader }: ListHeaderProps) => {
    return (
        <div className="flex items-center justify-between p-8 font-account-heading uppercase text-gray-medium mobile:hidden">
            {formatHeader.map(({ name, className }) => (
                <span className={cn(className, 'flex-1')} key={name.id}>
                    <FormattedMessage id={name.id} />
                </span>
            ))}
        </div>
    );
};
export default ListHeader;
