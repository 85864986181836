import { defineMessages, FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { useQuery } from '@tanstack/react-query';

import { Box } from '@components/Box/Box';
import ListHeader, { type headProps } from '@components/List/ListHeader';
import Loader from '@components/Loader/Loader';
import ErrorOnFetch from '@components/Page/ErrorOnFetch';
import Pagination from '@components/Pagination/Pagination';
import { useAppContext } from '@context/AppContext';
import { useAuth } from '@context/AuthContext';
import { useCountry } from '@context/CountryContext';
import useCurrency from '@hooks/useCurrency';
import { usePagination } from '@hooks/usePagination';
import apiOms from '@utils/apiOms';
import type { BRANDS } from '@utils/brand';
import { DEFAULT_CURRENCYCODE, DEFAULT_SITECODE } from '@utils/common';
import { getContentRange } from '@utils/fetch';
import { cn } from '@utils/main';
import { CREDITS_KEY, ITEMS_PER_PAGE } from './config';

const creditsColumnNames = defineMessages({
    remaining_amount: { id: 'credits_ecards.credits.list.remaining_amount' },
    initial_amount: { id: 'credits_ecards.credits.list.initial_amount' },
    credit_code: { id: 'credits_ecards.credits.list.credit_code' },
    validity_date: { id: 'credits_ecards.credits.list.validity_date' },
});

const headFormater: headProps[] = [
    { name: creditsColumnNames.remaining_amount },
    { name: creditsColumnNames.initial_amount },
    { name: creditsColumnNames.credit_code },
    { name: creditsColumnNames.validity_date },
];

interface GetCredits {
    keycloakId: string;
    brandCode: BRANDS;
    siteCode: string;
    countryCode: string;
    currencyCode: string;
    currentPage: number;
    itemsPerPage: number;
}

const getCredits = ({
    keycloakId,
    brandCode,
    currentPage,
    itemsPerPage,
    siteCode,
    countryCode,
    currencyCode,
}: GetCredits) => ({
    queryKey: [CREDITS_KEY, `${currentPage}-${itemsPerPage}`],
    queryFn: () =>
        apiOms.omsAuthenticated.getCredits(keycloakId, {
            brandCode,
            siteCode,
            countryCode,
            currencyCode,
            page: currentPage,
            itemsPerPage,
        }),
    keepPreviousData: true,
});

const CreditsSection = () => {
    const { keycloakId } = useAuth();
    const {
        brandCode,
        site: { code },
    } = useAppContext();
    const { currentPage, setCurrentPage } = usePagination();
    const { country } = useCountry();
    const currency = useCurrency();

    const { data, isSuccess, isError, isLoading } = useQuery(
        getCredits({
            keycloakId,
            brandCode,
            siteCode: code || DEFAULT_SITECODE,
            currentPage,
            countryCode: country.countryISO!,
            currencyCode: currency?.code || DEFAULT_CURRENCYCODE,
            itemsPerPage: ITEMS_PER_PAGE,
        })
    );
    const credits = data?.data;
    const contentRange = data && getContentRange(data);

    return (
        <>
            {isSuccess && !!credits?.length && (
                <>
                    <ListHeader formatHeader={headFormater} />
                    {credits?.map(c => (
                        <Box
                            key={c.id}
                            className={cn(
                                'mobile:flow-row mb-8 grid grid-cols-4 items-center justify-between border-b font-account-heading uppercase mobile:auto-rows-min mobile:grid-cols-2 mobile:gap-y-8 mobile:border mobile:px-8 desktop:py-12',
                                { 'text-gray-light': c.isExpired || c.isAmountExhausted }
                            )}
                        >
                            <span>
                                <b className="hidden mobile:block">
                                    <FormattedMessage id={creditsColumnNames.remaining_amount.id} />
                                </b>
                                {c.displayPrice}
                            </span>
                            <span>
                                <b className="hidden mobile:block">
                                    <FormattedMessage id={creditsColumnNames.initial_amount.id} />
                                </b>
                                {c.originalAmountDisplayPrice}
                            </span>
                            <span>
                                <b className="hidden mobile:block">
                                    <FormattedMessage id={creditsColumnNames.credit_code.id} />
                                </b>
                                {c.code}
                            </span>
                            <span>
                                <b className="hidden mobile:block">
                                    <FormattedMessage id={creditsColumnNames.validity_date.id} />
                                </b>
                                {c.expiredAt && (
                                    <>
                                        <FormattedDate value={c.expiredAt} />{' '}
                                        <FormattedTime value={c.expiredAt} hour="numeric" minute="numeric" />
                                        {c.isExpired && (
                                            <>
                                                {' - '}
                                                <FormattedMessage id="credits_ecards.credits.list.expired" />
                                            </>
                                        )}
                                    </>
                                )}
                            </span>
                        </Box>
                    ))}
                </>
            )}
            {isSuccess && !credits?.length && (
                <p className="mb-4 text-gray-medium">
                    <FormattedMessage id="credits_ecards.credits.no_credit-code" />
                </p>
            )}
            {isError && <ErrorOnFetch />}
            {isLoading && <Loader />}
            {credits?.length ? (
                <Pagination
                    contentRange={contentRange}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    resultsByPage={ITEMS_PER_PAGE}
                />
            ) : null}
        </>
    );
};
export default CreditsSection;
