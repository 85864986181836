import type { SVGProps } from 'react';

const IconPayment = (props: SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.6501 0.143066V3.15007H16.6501V5.95007V12.8501V15.6501H0.350098V3.30707L11.6501 0.143066ZM15.3501 4.45007V5.95007H10.8001C8.89472 5.95007 7.3501 7.49469 7.3501 9.40007C7.3501 11.3055 8.89472 12.8501 10.8001 12.8501H15.3501V14.3501H1.6501V4.45007H15.3501ZM15.3501 7.25007V11.5501H10.8001C9.61269 11.5501 8.6501 10.5875 8.6501 9.40007C8.6501 8.21266 9.61269 7.25007 10.8001 7.25007H15.3501ZM10.3501 3.15007V1.85706L5.73224 3.15007H10.3501ZM11.0001 10.3334C11.5524 10.3334 12.0001 9.91553 12.0001 9.40007C12.0001 8.8846 11.5524 8.46674 11.0001 8.46674C10.4478 8.46674 10.0001 8.8846 10.0001 9.40007C10.0001 9.91553 10.4478 10.3334 11.0001 10.3334Z"
            fill="currentColor"
        />
    </svg>
);

export default IconPayment;
