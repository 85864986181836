const FORCED_CURRENCY_SYMBOLS: Record<string, string> = {
    AUD: 'AU$',
    CAD: 'CA$',
    HKD: 'HK$',
    NZD: 'NZ$',
    SGD: 'SG$',
    TWD: 'NT$',
    USD: 'US$',
    XCD: 'EC$',
};

export const formatCountryCurrencyLocale = (countryISO: string, currencyCode: string | undefined, locale: string) => {
    if (!currencyCode) return `${countryISO} | ${locale}`.toUpperCase();

    let currencySymbol: string;
    const forcedCurrencySymbol = FORCED_CURRENCY_SYMBOLS[currencyCode];

    if (forcedCurrencySymbol) {
        currencySymbol = forcedCurrencySymbol;
    } else {
        const formatter = new Intl.NumberFormat(document.documentElement.lang || locale, {
            style: 'currency',
            currency: currencyCode,
        });
        const formattedNb = formatter.formatToParts(0);
        currencySymbol = formattedNb.find(part => part.type === 'currency')?.value!;
    }

    return `${countryISO} (${currencySymbol}) | ${locale}`.toUpperCase();
};
