import { lazy, type MouseEvent, Suspense, useEffect, useRef, useState } from 'react';
import { type Coords } from 'google-map-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CapAdressAutocomplete, DEFAULT_TOAST_TIMEOUT, Tooltip } from '@sezane/front-components';
import { useQuery } from '@tanstack/react-query';

import Button from '@components/Button/Button';
import IconPlace from '@components/Icons/IconPlace';
import apiOms from '@utils/apiOms';
import Loader from '../Loader/Loader';
import MapMarker from './MapMarker';
import RelayTooltip from './RelayTooltip';
import type { PickUp } from 'types/apiOms';

interface RelaysMapProps {
    countryISO?: string;
}

const Map = lazy(() => import('../Map/Map'));

// Sézane HQ
const DEFAULT_MAP_POSITION = {
    lat: 48.8707806,
    lng: 2.3414433,
};

const GEOLOCATION_MARKER_ID = 'geolocation-marker';

const RelaysMap = ({ countryISO }: RelaysMapProps) => {
    const intl = useIntl();

    const [postCode, setPostCode] = useState('');
    const [city, setCity] = useState('');
    const [position, setPosition] = useState(DEFAULT_MAP_POSITION);
    const [geolocation, setGeolocation] = useState<Coords>();
    const [geolocationError, setGeolocationError] = useState(false);
    const [loadingGeoloc, setLoadingGeoloc] = useState(false);
    const [selectedRelay, setSelectedRelay] = useState<PickUp>();
    const [displaySearchAround, setDisplaySearchAround] = useState(false);
    const mapCenter = useRef<Coords>();

    const {
        data: relaysResponse,
        isFetching: isFetchingRelays,
        refetch: fetchRelays,
    } = useQuery(
        ['relays'],
        () => {
            return apiOms.omsPublic.getPickUps(countryISO!, {
                postcode: postCode,
                city: city,
                lat: geolocation?.lat.toString(),
                long: geolocation?.lng.toString(),
            });
        },
        {
            enabled: false,
        }
    );

    // when postcode change: reset geoloc (to prevent sending both postcode and lat/lng)
    useEffect(() => {
        if (postCode) {
            setGeolocation(undefined);
        }
    }, [postCode]);

    // when geolocation loaded: fetch relays
    useEffect(() => {
        if (geolocation) {
            fetchRelays();
            setDisplaySearchAround(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geolocation]);

    // when relays loaded: update position
    useEffect(() => {
        if (relaysResponse?.data?.length) {
            setPosition({
                lat: relaysResponse.data[0].pickUpAddress?.latitude!,
                lng: relaysResponse.data[0].pickUpAddress?.longitude!,
            });
        }
    }, [relaysResponse]);

    // when relay selected: pick relay + update position
    useEffect(() => {
        if (selectedRelay) {
            setPosition({
                lat: selectedRelay.pickUpAddress?.latitude!,
                lng: selectedRelay.pickUpAddress?.longitude!,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRelay]);

    const reset = () => {
        setGeolocationError(false);
        setSelectedRelay(undefined);
    };

    const fetchRelaysWithPostalCode = (event?: MouseEvent<HTMLButtonElement>) => {
        event && event.preventDefault();

        reset();
        fetchRelays();
    };

    const fetchRelaysWithGeolocation = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        reset();
        setPostCode('');
        setCity('');
        setLoadingGeoloc(true);

        navigator.geolocation.getCurrentPosition(
            data => {
                setLoadingGeoloc(false);
                setGeolocation({
                    lat: data.coords.latitude,
                    lng: data.coords.longitude,
                });
            },
            error => {
                setLoadingGeoloc(false);
                setGeolocationError(true);
                console.error(error);
            },
            {
                timeout: DEFAULT_TOAST_TIMEOUT,
            }
        );
    };

    const geolocMarker = geolocation
        ? ({
              id: GEOLOCATION_MARKER_ID,
              pickUpAddress: {
                  longitude: geolocation.lng,
                  latitude: geolocation.lat,
              },
          } as PickUp)
        : undefined;

    const searchAroundMapCenter = () => {
        if (mapCenter.current) {
            setGeolocation(mapCenter.current);
        }
    };

    return (
        <div className="large:pr-24">
            <div className="my-8 flex gap-8">
                {countryISO === 'FR' && (
                    <Tooltip
                        id="map_geoloc_tooltip"
                        bubbleClassName="w-max max-w-md whitespace-normal mobile:block"
                        icon={
                            <Button color="secondary" className="h-full px-5" onClick={fetchRelaysWithGeolocation}>
                                <i className="">
                                    <IconPlace />
                                </i>
                                <span className="sr-only">
                                    <FormattedMessage id="shipping.relays.geolocation.action" />
                                </span>
                            </Button>
                        }
                        content={
                            <p>
                                <FormattedMessage id="shipping.relays.geolocation.action" />
                            </p>
                        }
                    />
                )}
                <div className="flex">
                    <CapAdressAutocomplete
                        id="relays-postalCode"
                        countryCode={countryISO}
                        value={postCode}
                        valueProperty="postalCode"
                        placeholder={intl.formatMessage({
                            id: 'shipping.relays.postal_code',
                        })}
                        onChange={val => setPostCode(val.toString())}
                        onOptionSelected={option => {
                            setCity(option.localityInfo_3 || option.localitySynonym || option.locality);
                        }}
                        required={false}
                    />
                    <Button
                        className="px-8"
                        disabled={loadingGeoloc || isFetchingRelays || !postCode}
                        onClick={fetchRelaysWithPostalCode}
                        color="primary"
                    >
                        <FormattedMessage id="shipping.relays.search" />
                    </Button>
                </div>
            </div>

            {geolocationError && (
                <div className="">
                    <p className="u-text-sm u-color-error">
                        <FormattedMessage id="shipping.relays.geolocation.error" />
                    </p>
                </div>
            )}
            {relaysResponse?.data && !relaysResponse?.data.length && (
                <div className="o-grid__col o-grid__col--24">
                    <p className="u-text-sm u-color-error">
                        <FormattedMessage id="shipping.relays.not_found" />
                    </p>
                </div>
            )}

            <div className="">
                <Suspense fallback={<Loader />}>
                    <div className="relative">
                        <Map
                            center={position}
                            markers={[...(relaysResponse?.data || []), ...(geolocMarker ? [geolocMarker] : [])]}
                            renderMarker={relay =>
                                relay.id === GEOLOCATION_MARKER_ID ? (
                                    <MapMarker
                                        icon={<IconPlace />}
                                        iconClassname="u-color-error u-text-icon-xxs"
                                        key={relay.id}
                                        lat={relay.pickUpAddress?.latitude!}
                                        lng={relay.pickUpAddress?.longitude!}
                                    />
                                ) : (
                                    <MapMarker
                                        key={relay.id}
                                        lat={relay.pickUpAddress?.latitude!}
                                        lng={relay.pickUpAddress?.longitude!}
                                        onClick={() => relay.id !== GEOLOCATION_MARKER_ID && setSelectedRelay(relay)}
                                        renderTooltip={() => (
                                            <RelayTooltip relay={relay} onClose={() => setSelectedRelay(undefined)} />
                                        )}
                                        selected={!!selectedRelay && selectedRelay.id === relay.id}
                                    />
                                )
                            }
                            onChange={value => {
                                mapCenter.current = value.center;
                            }}
                            onDragEnd={() => {
                                if (countryISO === 'FR') {
                                    setDisplaySearchAround(true);
                                }
                            }}
                        >
                            {displaySearchAround && (
                                <Button
                                    onClick={searchAroundMapCenter}
                                    color="secondary"
                                    className="absolute bottom-12 left-1/2 -translate-x-1/2"
                                >
                                    <FormattedMessage id="shipping.relays.map.search_here" />
                                </Button>
                            )}
                        </Map>
                        {(loadingGeoloc || isFetchingRelays) && <Loader size={40} fullScreen shadow />}
                    </div>
                </Suspense>
            </div>
        </div>
    );
};

export default RelaysMap;
