import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Checkbox, Dropdown } from '@sezane/front-components';
import { useQuery } from '@tanstack/react-query';

import { GiftsInfo } from '@components/Box/Returns/additionalInfos';
import Loader from '@components/Loader/Loader';
import { useAppContext } from '@context/AppContext';
import OrderCustomisation from '@pages/OrdersPage/OrderCustomisation';
import apiOms from '@utils/apiOms';
import { DEFAULT_IMG_PLACEHOLDER } from '@utils/common';
import Step from './Step';
import type { ExtendedOrderProduct, FormValues, ReturnProducts, ReturnReason } from './utils';
import { getCreateReturnSteps, getExtendedProductId, splitOrderItems } from './utils';

interface ProductCheckboxProps {
    error?: string;
    item: ExtendedOrderProduct;
    onChange?: (selected: boolean) => void;
    onSelectReason?: (reason: ReturnReason) => void;
    reasonValue?: ReturnReason;
    selectable?: boolean;
    selected?: boolean;
}

const ProductCheckbox = ({
    error,
    item,
    onChange,
    onSelectReason,
    reasonValue,
    selectable,
    selected,
}: ProductCheckboxProps) => {
    const { referencesOms } = useAppContext();
    const returnReasons = referencesOms?.order_return_motives;

    return (
        <div
            className={clsx(
                'flex gap-6 border px-6 py-10 font-heading text-black transition-colors',
                selected ? 'border-black' : 'border-gray-200',
                selectable ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'
            )}
            onClick={() => onChange && onChange(!selected)}
        >
            <Checkbox
                className={clsx('self-center', !selectable && 'opacity-0')}
                isDisabled={!selectable}
                isSelected={selected}
                onChange={onChange}
            />
            <div className="mr-4 shrink-0">
                <img className="h-[120px]" alt={item.label} src={item.imageUrl || DEFAULT_IMG_PLACEHOLDER} />
            </div>
            <div className="flex min-w-0 grow flex-col justify-between">
                <div className="text-xs">
                    <div className="text-3xl font-medium uppercase ">{item.label}</div>
                    <div>
                        {item.colorLabel && <span className="mr-5 text-xs text-gray-medium">{item.colorLabel}</span>}
                        {item.sizeLabel && (
                            <FormattedMessage
                                id="return.step_products.product.size"
                                values={{ size: item.sizeLabel.toUpperCase() }}
                            />
                        )}
                    </div>
                    <div>{item.totalDisplayPrice}</div>
                    <div className="my-2">
                        <OrderCustomisation className="text-xs" product={item} />
                    </div>
                </div>
                {selectable && onSelectReason && (
                    <Dropdown<ReturnReason>
                        id="returnReason"
                        className={clsx('px-6 py-4', error === 'missing_reason' && 'border-error text-error')}
                        items={returnReasons || []}
                        itemKey="name"
                        value={reasonValue}
                        defaultLabel={<FormattedMessage id="return.step_products.product.reason" />}
                        renderItem={({ translation }) => translation}
                        onChange={onSelectReason}
                        stopPropagation
                    />
                )}
            </div>
        </div>
    );
};

interface StepProductsProps {
    onChange: () => void;
}

const StepProducts = ({ onChange }: StepProductsProps) => {
    const { clearErrors, formState, setValue, watch } = useFormContext<FormValues>();
    const selectedOrder = watch('order');
    const products = watch('products');

    const [returnableItems, setReturnableItems] = useState<ExtendedOrderProduct[]>();
    const [nonReturnableItems, setNonReturnableItems] = useState<ExtendedOrderProduct[]>();
    const [selectedProducts, setSelectedProducts] = useState<string[]>([]);

    const { data: itemsResponse, isFetching: isLoadingItems } = useQuery(
        ['getReturnableItems', selectedOrder?.number],
        () => apiOms.omsAuthenticated.getReturnableItems(selectedOrder?.number!),
        {
            enabled: !!selectedOrder,
        }
    );
    const items = itemsResponse?.data;

    const onSelectProduct = (selectedProducts: string[]) => {
        const newProducts: ReturnProducts = {};
        selectedProducts?.forEach(id => {
            if (products && products[id]) {
                newProducts[id] = products[id];
            } else {
                const product = returnableItems?.find(p => getExtendedProductId(p) === id);
                if (product) {
                    newProducts[id] = { product };
                }
            }
        });
        setValue('products', newProducts);
        clearErrors();
        onChange();
    };

    const onSelectReason = (product: ExtendedOrderProduct, reason: ReturnReason) => {
        // select product if not yet selected
        const id = getExtendedProductId(product);
        if (!selectedProducts.includes(id)) {
            const newSelectedProducts = [...(selectedProducts || []), id];
            setSelectedProducts(newSelectedProducts);
        }

        // select reason
        setValue(`products.${id}`, {
            product,
            reason,
        });
        clearErrors();
        onChange();
    };

    useEffect(() => {
        if (items) {
            setReturnableItems(splitOrderItems(items.returnableItems, item => item.returnableQuantity || 0));
            setNonReturnableItems(
                splitOrderItems(items.nonReturnableItems, item => (item.quantity || 0) - (item.returnableQuantity || 0))
            );
        }
    }, [items]);

    return (
        <Step
            id={getCreateReturnSteps()[1]}
            className="mt-24 pt-6"
            title={<FormattedMessage id="return.step_products.title" />}
            description={<FormattedMessage id="return.step_products.description" />}
        >
            {isLoadingItems ? (
                <Loader className="mt-10" />
            ) : (
                <>
                    {returnableItems && returnableItems.length ? (
                        <div className="flex flex-col gap-6">
                            {returnableItems.map(item => {
                                const id = getExtendedProductId(item);
                                const selected = !!selectedProducts.find(productId => productId === id);
                                return (
                                    <ProductCheckbox
                                        key={id}
                                        item={item}
                                        reasonValue={products ? products[id]?.reason : undefined}
                                        onChange={checked => {
                                            const newSelectedProducts = checked
                                                ? [...selectedProducts, id]
                                                : selectedProducts?.filter(
                                                      selectedProductId => selectedProductId !== id
                                                  );
                                            setSelectedProducts(newSelectedProducts);
                                            onSelectProduct(newSelectedProducts);
                                        }}
                                        onSelectReason={async reason => {
                                            onSelectReason(item, reason);
                                        }}
                                        selectable
                                        selected={selected}
                                        error={formState.errors.products && (formState.errors.products[id] as string)}
                                    />
                                );
                            })}
                        </div>
                    ) : (
                        <FormattedMessage id="return.step_products.no_items" />
                    )}

                    {!!items?.returnableItems?.length &&
                        products &&
                        Object.keys(products).length === items.returnableItems.length &&
                        items.hasGift && (
                            <div className="mt-10">
                                <GiftsInfo />
                            </div>
                        )}

                    {nonReturnableItems && nonReturnableItems.length > 0 && (
                        <>
                            <h2 className="mt-20">
                                <FormattedMessage id="return.step_products.non_returnable.title" />
                            </h2>
                            <p className="mt-6">
                                <FormattedMessage id="return.step_products.non_returnable.description" />
                            </p>
                            <div className="mt-10 flex flex-col gap-6">
                                {nonReturnableItems.map(item => (
                                    <ProductCheckbox key={item.ean} item={item} selectable={false} />
                                ))}
                            </div>
                        </>
                    )}
                </>
            )}
        </Step>
    );
};

export default StepProducts;
