import type { SVGProps } from 'react';

const IconWhatsapp = (props: SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M30.06 27.3c-.9.38-1.49 1.8-2.07 2.53-.3.37-.67.43-1.13.24a15.84 15.84 0 0 1-7.9-6.77c-.32-.49-.27-.87.12-1.32.57-.67 1.28-1.43 1.43-2.34.35-2-2.26-8.18-5.7-5.38-9.92 8.08 16.53 29.5 21.3 17.92 1.35-3.29-4.54-5.5-6.05-4.87Zm-5.73 17c-3.5 0-6.95-.92-9.97-2.69a2.1 2.1 0 0 0-1.61-.21l-6.56 1.8 2.28-5.04a2.1 2.1 0 0 0-.2-2.09A19.83 19.83 0 0 1 24.33 4.7c10.92 0 19.8 8.89 19.8 19.81 0 10.92-8.88 19.8-19.8 19.8Zm0-43.8a24.03 24.03 0 0 0-20.16 37L.52 45.55a2.1 2.1 0 0 0 1.91 2.96c1.35 0 8.72-2.32 10.6-2.83a23.98 23.98 0 0 0 35.3-21.17c0-13.23-10.76-24-24-24Z"
            fill="currentColor"
        />
    </svg>
);

export default IconWhatsapp;
