import { type RefObject } from 'react';
import { FormattedMessage } from 'react-intl';

import LinkButton from '@components/Link/LinkButton';
import { useCartContext } from '@context/CartContext';
import useOutsideClick from '@hooks/useClickOutside';
import { useUrl } from '@hooks/useUrl';
import { cn } from '@utils/main';

export default function CartOverlay() {
    const { cart, overlayVisible, setOverlayVisible } = useCartContext();
    const overlayRef = useOutsideClick(() => {
        setOverlayVisible(false);
    });
    const { urls } = useUrl();
    const isEmpty = !cart || cart.products?.length === 0;

    return (
        <div
            ref={overlayRef as RefObject<HTMLDivElement>}
            className={cn('absolute right-0 z-50 w-[300px] pt-[14px] mobile:hidden', {
                tooltip: !overlayVisible,
                'z-50 desktop:visible': overlayVisible,
            })}
        >
            <div className="bg-white before:absolute before:right-8 before:block before:h-5 before:w-5 before:-translate-y-1/2 before:rotate-45 before:bg-white">
                {cart?.products?.map(product => (
                    <div key={product.ean} className="flex flex-row p-[28px] pb-0 uppercase">
                        <img
                            src={product.imageUrl}
                            alt={product.label}
                            className="h-[80px] object-contain"
                            height={80}
                            width={60}
                        />
                        <div className="flex w-full flex-col justify-evenly px-5">
                            <p>{product.label}</p>
                            <p className="text-gray-light">{product.colorLabel}</p>
                            <p className="text-gray-light">
                                {product.sizeLabel} x {product.quantity}
                            </p>
                        </div>
                        <div className="flex flex-col justify-evenly">
                            <p>{product.totalDisplayPrice}</p>
                        </div>
                    </div>
                ))}

                <div className="flex h-full flex-col justify-evenly p-[28px] text-center text-sm">
                    {isEmpty && (
                        <p className="font-account-body text-sm text-gray-light">
                            <FormattedMessage id="cart.empty" />
                        </p>
                    )}
                    <p className="mt-[28px] font-account-body text-xs">
                        <FormattedMessage id="cart.shipping_info" />
                    </p>
                </div>
            </div>
            <LinkButton className="w-full" href={urls.cart}>
                <FormattedMessage id="cart.actions.order" />
            </LinkButton>
        </div>
    );
}
