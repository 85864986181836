import { useId } from 'react';

const VisaLogo = ({ title }: { title: string }) => {
    const id = useId();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={id}
            width="40"
            height="41"
            viewBox="50 150 1000.046 323.653"
            role="img"
        >
            <title id={id}>{title}</title>
            <path
                fill="#00579f"
                d="M433.352 318.983H352.34L403.011 5.68h81.006zm293.67-305.645C711.045 7 685.7 0 654.363 0c-80 0-136.336 42.66-136.682 103.652-.664 45 40.335 69.993 71 84.997 31.341 15.333 41.995 25.34 41.995 39.006-.319 20.99-25.326 30.664-48.65 30.664-32.343 0-49.673-4.988-76.009-16.665l-10.667-5.006-11.336 70.33c19 8.656 54.005 16.338 90.349 16.675 85.002 0 140.34-42 140.997-106.997.323-35.666-21.326-62.994-68-85.326-28.334-14.335-45.687-24.001-45.687-38.669.333-13.334 14.677-26.992 46.661-26.992 26.336-.669 45.686 5.662 60.345 11.997l7.327 3.327 11.018-67.655zm107.67 194.653c6.672-18 32.344-87.66 32.344-87.66-.337.669 6.658-18.332 10.658-29.996l5.662 26.997s15.34 74.994 18.672 90.659h-67.335zM934.69 5.679h-62.662c-19.323 0-34.004 5.661-42.341 25.995L709.356 318.979h85.002s13.994-38.67 17.002-46.998h104.011c2.326 11 9.666 46.998 9.666 46.998h75.008L934.69 5.679zm-650.012 0l-79.336 213.644-8.67-43.33C182.005 125.997 136.004 71.676 84.67 44.667l72.669 273.984h85.666L370.34 5.68h-85.662z"
            ></path>
            <path
                fill="#faa61a"
                d="M131.671 5.679H1.333L0 12.009c101.672 26 169.007 88.67 196.672 163.997L168.338 32.015c-4.665-20.01-19-25.676-36.667-26.336z"
            ></path>
        </svg>
    );
};

export default VisaLogo;
