import type { ReactNode } from 'react';

import { cn } from '@utils/main';

interface BoxProps {
    children: ReactNode;
    className?: string;
    imageBox?: boolean;
    grid?: boolean;
    border?: boolean;
    dense?: boolean;
}

export const Box = ({
    children,
    className,
    grid = false,
    imageBox = false,
    border = true,
    dense = false,
    ...props
}: BoxProps) => (
    <div
        className={cn(
            imageBox && 'flex-col pb-[1.6rem]',
            !grid && 'flex mobile:flex-col',
            grid && 'grid',
            !imageBox && 'mobile:py-[1.6rem] desktop:p-[1.6rem]',
            imageBox && 'flex-col',
            border && 'border-gray-lighter',
            border && dense && 'desktop:border-x desktop:border-t desktop:last-of-type:border-b',
            border && !dense && 'border',
            className
        )}
        {...props}
    >
        {children}
    </div>
);
