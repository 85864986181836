import { checkReturnDhl, checkReturnSchneider } from '@utils/order';
import { DHLReturnSection } from './DHLReturnSection';
import { FreeReturnSection } from './FreeReturnSection';
import { ReturnLabelSection } from './ReturnLabelSection';
import { ReturnSchneiderSection } from './SchneiderReturnSection';
import { StoreReturnSection } from './StoreReturnSection';
import type { OMS } from 'types';

interface ReturnSectionsProps {
    countryISO?: string | null;
    eligibleReturnModes?: OMS.EligibleOrderReturnModes;
    hasReturnLabel?: boolean;
    isDhl?: boolean;
    isDhlConfirm?: boolean;
    orderReference?: string;
    pickupAddress?: OMS.OptionalPhysicalAddress;
    pickupDateStart?: string | null;
    pickupDateEnd?: string | null;
}

const ReturnSections = ({
    countryISO,
    eligibleReturnModes,
    hasReturnLabel,
    isDhl,
    isDhlConfirm,
    orderReference,
    pickupAddress,
    pickupDateStart,
    pickupDateEnd,
}: ReturnSectionsProps) => {
    const sectionCounter =
        !!eligibleReturnModes?.returnModes &&
        eligibleReturnModes.returnModes.filter(returnMode => !checkReturnDhl(returnMode)).length > 1;

    return (
        <>
            {isDhl ? (
                // DHL RETURN SECTION
                <DHLReturnSection
                    hasReturnLabel={hasReturnLabel}
                    isDhlConfirm={isDhlConfirm}
                    orderReference={orderReference}
                    pickupAddress={pickupAddress}
                    pickupDateStart={pickupDateStart}
                    pickupDateEnd={pickupDateEnd}
                />
            ) : (
                <>
                    {/* SCHNEIDER RETURN SECTION */}
                    {eligibleReturnModes?.returnModes?.some(checkReturnSchneider) && (
                        <ReturnSchneiderSection
                            sectionCounter={sectionCounter}
                            hasReturnLabel={hasReturnLabel}
                            orderReference={orderReference}
                        />
                    )}
                    {/* RETURN LABEL SECTION */}
                    {eligibleReturnModes?.returnModes?.some(mode => mode.type === 'return_label') && (
                        <ReturnLabelSection
                            sectionCounter={sectionCounter}
                            countryISO={countryISO}
                            hasReturnLabel={hasReturnLabel}
                            orderReference={orderReference}
                        />
                    )}
                    {/* STORE RETURN SECTION */}
                    {eligibleReturnModes?.returnModes?.some(mode => mode.type === 'store_return') &&
                        eligibleReturnModes.shops?.length && (
                            <StoreReturnSection sectionCounter={sectionCounter} shops={eligibleReturnModes.shops} />
                        )}
                    {/* FREE RETURN SECTION */}
                    {eligibleReturnModes?.returnModes?.some(mode => mode.type === 'free') && (
                        <FreeReturnSection
                            sectionCounter={sectionCounter}
                            hasReturnLabel={hasReturnLabel}
                            orderReference={orderReference}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default ReturnSections;
