import TrashIcon from '@components/Icons/IconTrash';
import { cn } from '@utils/main';
import Button, { type ButtonPropsTypes } from './Button';

const DeleteButton = ({ children, onClick, color = 'tertiary', className, ...props }: ButtonPropsTypes) => (
    <Button
        {...props}
        color={color}
        className={cn('group flex items-center justify-center gap-5 p-4 hover:text-error', className)}
        onClick={onClick}
    >
        {children}
        <TrashIcon className="-translate-y-1 fill-gray-light transition-colors duration-300 group-hover:fill-error" />
    </Button>
);

export default DeleteButton;
