import { clsx } from 'clsx';

import { getTotalPagesFromContentRange } from '@utils/pagination';
import IconChevron from '../Icons/IconChevron';

interface PaginationProps {
    contentRange: string | null | undefined;
    currentPage: number;
    setCurrentPage: (page: number) => void;
    resultsByPage: number;
}

const NB_DISPLAYED = 3;
const NB_PAGES_EXTENDED = 7;

const buttonClassNames = 'flex items-center justify-center w-4 h-4 mr-5 last:mr-0 disabled:text-gray-lighter';

const PaginationButton = ({
    currentPage,
    index,
    setCurrentPage,
}: {
    currentPage: number;
    index: number;
    setCurrentPage: (index: number) => void;
}) => (
    <button
        aria-label={`Page ${index}`}
        className={clsx(buttonClassNames, currentPage === index ? 'text-black' : 'text-gray-light')}
        onClick={() => setCurrentPage(index)}
    >
        {index}
    </button>
);
const Pagination = ({ contentRange, currentPage, setCurrentPage, resultsByPage }: PaginationProps) => {
    if (!contentRange || contentRange === null) return;

    const nbPages = getTotalPagesFromContentRange(contentRange, resultsByPage);
    const pagesArray = new Array(nbPages).fill('').map((_empty, index) => index);

    const prev = () => currentPage > 0 && setCurrentPage(currentPage - 1);
    const next = () => currentPage < nbPages && setCurrentPage(currentPage + 1);

    const showMiddleNumbers =
        nbPages > NB_PAGES_EXTENDED && currentPage > NB_DISPLAYED && currentPage < nbPages - NB_DISPLAYED + 1;

    return nbPages > 1 ? (
        <div className="flex justify-end pt-8 font-account-heading text-sm font-medium ">
            <div className="flex">
                <button className={buttonClassNames} disabled={currentPage === 1} onClick={prev}>
                    <IconChevron className="rotate-180" height={12} />
                </button>

                {nbPages <= NB_PAGES_EXTENDED &&
                    pagesArray
                        .slice(0, nbPages)
                        .map(index => (
                            <PaginationButton
                                key={index}
                                currentPage={currentPage}
                                index={index + 1}
                                setCurrentPage={setCurrentPage}
                            />
                        ))}
                {nbPages > NB_PAGES_EXTENDED && (
                    <>
                        {pagesArray.slice(0, showMiddleNumbers ? 1 : NB_DISPLAYED).map(index => (
                            <PaginationButton
                                key={index}
                                currentPage={currentPage}
                                index={index + 1}
                                setCurrentPage={setCurrentPage}
                            />
                        ))}
                        <span className={buttonClassNames}>...</span>
                        {showMiddleNumbers &&
                            pagesArray
                                .slice(currentPage - 1, currentPage + 2)
                                .map(index => (
                                    <PaginationButton
                                        key={index}
                                        currentPage={currentPage}
                                        index={index + 1}
                                        setCurrentPage={setCurrentPage}
                                    />
                                ))}
                        {showMiddleNumbers && currentPage < nbPages - 2 && (
                            <span className={buttonClassNames}>...</span>
                        )}
                        {pagesArray
                            .slice(showMiddleNumbers ? nbPages - 1 : nbPages - NB_DISPLAYED, nbPages)
                            .map(index => (
                                <PaginationButton
                                    key={index}
                                    currentPage={currentPage}
                                    index={index + 1}
                                    setCurrentPage={setCurrentPage}
                                />
                            ))}
                    </>
                )}
                <button className={buttonClassNames} disabled={currentPage === nbPages} onClick={next}>
                    <IconChevron height={12} />
                </button>
            </div>
        </div>
    ) : null;
};

export default Pagination;
