import { clsx } from 'clsx';
import { matchPath, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import type { MenuItemProps } from '@utils/menu';
import IconChevron from '../Icons/IconChevron';

const MenuItem = ({ icon, label, link, onClick, className, additionalMatchLinks = [] }: MenuItemProps) => {
    const { pathname } = useLocation();

    const selected = [link || '', ...additionalMatchLinks].some(link => matchPath(link, pathname));

    return (
        <li
            className={clsx(
                'menu-item text-xl transition-colors last:mb-0 hover:text-black',
                selected || !!onClick ? 'text-black' : 'text-gray-light',
                className
            )}
        >
            {link && (
                <Link className="flex items-center" to={link}>
                    <div className="menu-item-icon mr-4 flex items-center justify-center">{icon}</div>
                    <div className="mr-12 grow">{label}</div>
                    <IconChevron height={12} />
                </Link>
            )}
            {onClick && (
                <div className="flex cursor-pointer items-center" onClick={onClick}>
                    <div className="menu-item-icon mr-4 flex items-center justify-center">{icon}</div>
                    <div className="mr-12 grow">{label}</div>
                </div>
            )}
        </li>
    );
};

export default MenuItem;
