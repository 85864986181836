import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@tanstack/react-query';

import Loader from '@components/Loader/Loader';
import { useAppContext } from '@context/AppContext';
import ReturnSections from '@pages/ReturnsPage/returnSections/ReturnSections';
import apiOms from '@utils/apiOms';
import { checkReturnDhl, checkReturnSchneider } from '@utils/order';
import Step from './Step';
import StepDhlPickup from './StepDhlPickup';
import { type FormValues, getCreateReturnSteps } from './utils';

interface StepGeneralConfirmProps {
    onChange: () => void;
}

const StepGeneralConfirm = ({ onChange }: StepGeneralConfirmProps) => {
    const { locale } = useAppContext();
    const { setValue, watch } = useFormContext<FormValues>();
    const selectedOrder = watch('order');
    const selectedReturnMode = watch('returnMode');

    const isDhl = !!selectedReturnMode && checkReturnDhl(selectedReturnMode);

    const { data: returnModesResponse, isFetching: isLoadingReturnModes } = useQuery(
        ['getEligibleOrderReturnModes', selectedOrder?.number],
        () => apiOms.omsAuthenticated.getEligibleOrderReturnModes(selectedOrder?.number!, { locale })
    );
    const eligibleReturnModes = returnModesResponse?.data;

    useEffect(() => {
        if (eligibleReturnModes) {
            const dhlReturnMode = eligibleReturnModes?.returnModes?.find(checkReturnDhl);
            if (dhlReturnMode) {
                setValue('returnMode', dhlReturnMode);
            } else {
                const schneiderReturnMode = eligibleReturnModes?.returnModes?.find(checkReturnSchneider);
                if (schneiderReturnMode) {
                    setValue('returnMode', schneiderReturnMode);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eligibleReturnModes]);

    return isLoadingReturnModes ? (
        <div className="mt-24 pt-6">
            <Loader />
        </div>
    ) : isDhl ? (
        <StepDhlPickup onChange={onChange} />
    ) : (
        <Step
            id={getCreateReturnSteps()[3]}
            className="mt-24 pt-6"
            title={<FormattedMessage id="return.step_confirm.title" />}
        >
            <ReturnSections
                orderReference={selectedOrder?.number}
                countryISO={selectedOrder?.addresses?.shipping?.countryCode}
                eligibleReturnModes={eligibleReturnModes}
                hasReturnLabel={!!selectedOrder?.hasReturnLabel}
            />
        </Step>
    );
};

export default StepGeneralConfirm;
