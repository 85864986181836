import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { SecondTitle } from '@components/commons';
import LinkButton from '@components/Link/LinkButton';
import RelaysMap from '@components/Map/RelaysMap';
import InfoReturnBox from './InfoReturnBox';

interface DepositBoxProps {
    boxIndex: number;
    countryISO?: string | null;
}

const messages = defineMessages({
    us: { id: 'orderReturn.return.detail.deposit.description.us' },
    gb: { id: 'orderReturn.return.detail.deposit.description.gb' },
    default: { id: 'orderReturn.return.detail.deposit.description' },
});

const messagesHref = defineMessages({
    us: { id: 'orderReturn.return.detail.deposit.description.us.href' },
    gb: { id: 'orderReturn.return.detail.deposit.description.gb.href' },
});

const messagesCta = defineMessages({
    us: { id: 'orderReturn.return.detail.deposit.description.us.cta' },
    gb: { id: 'orderReturn.return.detail.deposit.description.gb.cta' },
});

const EXCEPTION_COUNTRY_RELAYS = ['GB', 'US'];

const DepositBox = ({ countryISO, boxIndex }: DepositBoxProps) => {
    const intl = useIntl();
    const isExceptionCountry = countryISO && EXCEPTION_COUNTRY_RELAYS.includes(countryISO?.toUpperCase());

    return (
        <InfoReturnBox boxIndex={boxIndex}>
            <SecondTitle
                description={
                    <FormattedMessage
                        id={isExceptionCountry ? messages[countryISO?.toLowerCase()].id : messages.default.id}
                        values={{ br: <br />, i: chunks => <i>{chunks}</i> }}
                    />
                }
            >
                <FormattedMessage id="orderReturn.return.detail.deposit.title" />
            </SecondTitle>
            {isExceptionCountry ? (
                <LinkButton
                    className="mt-8"
                    fullWidth
                    color="quartenary"
                    href={intl.formatMessage({ id: messagesHref[countryISO?.toLowerCase()].id })}
                    target="_blank"
                >
                    <FormattedMessage id={messagesCta[countryISO?.toLowerCase()].id} />
                </LinkButton>
            ) : countryISO ? (
                <RelaysMap countryISO={countryISO} />
            ) : null}
        </InfoReturnBox>
    );
};

export default DepositBox;
