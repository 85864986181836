import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from '@tanstack/react-query';

import Button from '@components/Button/Button';
import { PageHeader } from '@components/commons';
import { logout } from '@components/Menu/Menu';
import { useAppContext } from '@context/AppContext';
import useConfirm from '@hooks/useConfirm';
import { useUrl } from '@hooks/useUrl';
import apiOms from '@utils/apiOms';
import { DEFAULT_SITECODE } from '@utils/common';

const DeleteAccountPage = () => {
    const { site, brandCode } = useAppContext();
    const { urls } = useUrl();
    const intl = useIntl();

    const { mutate } = useMutation({
        mutationFn: () =>
            apiOms.omsAuthenticated.deleteUserAuthenticated({ siteCode: site.code || DEFAULT_SITECODE, brandCode }),
        onSuccess: logout(brandCode, site),
    });
    const confirmDeleteAccount = useConfirm(intl.formatMessage({ id: 'delete_account.action.confirm' }), mutate);

    return (
        <>
            <PageHeader title="delete_account.title">
                <FormattedMessage id="delete_account.title" />
            </PageHeader>
            <div className="flex flex-col gap-8 text-justify">
                <p>
                    <FormattedMessage id="delete_account.description.goodbye" />
                </p>
                <p>
                    <FormattedMessage id="delete_account.description.delete" />
                </p>
                <p>
                    <FormattedMessage id="delete_account.description.accounts" />
                    <a className="underline" href={urls.privacyPolicy}>
                        <FormattedMessage id="delete_account.link.text" />
                    </a>
                </p>
            </div>
            <Button className="mt-8" color="danger" onClick={confirmDeleteAccount}>
                <FormattedMessage id="delete_account.button"></FormattedMessage>
            </Button>
        </>
    );
};

export default DeleteAccountPage;
