import { defineMessages } from 'react-intl';

const cardTypes = defineMessages({
    ach: { id: 'payments.card_type.ach' },
    amex: { id: 'payments.card_type.amex' },
    applepay: { id: 'payments.card_type.applepay' },
    bcmc: { id: 'payments.card_type.bcmc' },
    blik: { id: 'payments.card_type.blik' },
    cartebancaire: { id: 'payments.card_type.cartebancaire' },
    cup: { id: 'payments.card_type.cup' },
    diners: { id: 'payments.card_type.diners' },
    directEbanking: { id: 'payments.card_type.directEbanking' },
    discover: { id: 'payments.card_type.discover' },
    eftpos_australia: { id: 'payments.card_type.eftpos_australia' },
    girocard: { id: 'payments.card_type.girocard' },
    giropay: { id: 'payments.card_type.giropay' },
    googlepay: { id: 'payments.card_type.googlepay' },
    ideal: { id: 'payments.card_type.ideal' },
    interac_card: { id: 'payments.card_type.interac_card' },
    jcb: { id: 'payments.card_type.jcb' },
    maestro: { id: 'payments.card_type.maestro' },
    mc: { id: 'payments.card_type.mc' },
    mobilepay: { id: 'payments.card_type.mobilepay' },
    onlinebanking_Pl: { id: 'payments.card_type.onlinebanking_Pl' },
    twint_pos: { id: 'payments.card_type.twint' },
    twint: { id: 'payments.card_type.twint' },
    vipps: { id: 'payments.card_type.vipps' },
    visa: { id: 'payments.card_type.visa' },
    vpay: { id: 'payments.card_type.vpay' },
    wechatpay: { id: 'payments.card_type.wechatpay' },
});

export default cardTypes;
