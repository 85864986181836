import { getAuth } from './auth';
import { setCartUpdated } from './order';
import type { ApiConfig } from 'types';
import { Api } from 'types';

export const getApiConfig = (baseUrl = '', apiKey = ''): ApiConfig => ({
    baseUrl,
    baseApiParams: {
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey,
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        secure: true,
    },
    securityWorker: async () => {
        const auth = await getAuth();

        if (!auth?.accessToken) return {};

        return {
            headers: {
                Authorization: `Bearer ${auth.accessToken}`,
            },
        };
    },
});

export const proxyHandler: ProxyHandler<any> = {
    get(target: ApiMethods, propKey: keyof ApiMethods) {
        const origMethod = target[propKey];

        if (typeof origMethod !== 'function') {
            return new Proxy(origMethod, proxyHandler);
        }

        return function (...args: any[]) {
            // @ts-expect-error
            let result = origMethod.apply(this, args);
            return result
                .catch(e => {
                    if (e.status === 401) {
                        // @ts-expect-error
                        return getAuth().then(() => origMethod.apply(this, args));
                    }

                    throw e;
                })
                .then(response => {
                    if (['addProduct', 'deleteWishlistItemAuthenticated', 'deleteWishlistItemAuthenticated'].includes(propKey)) {
                        setCartUpdated();
                    }

                    return response;
                });
        };
    },
};

const apiClient = new Api(getApiConfig(process.env.REACT_APP_API_URL, process.env.REACT_APP_API_KEY));

type ApiMethods = typeof apiClient.ecommercePublic;

const createApiClientProxy = (client: Api<unknown>): ApiMethods => {
    return new Proxy(client.ecommercePublic, proxyHandler);
};

export default createApiClientProxy(apiClient);
