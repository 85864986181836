import { FormattedMessage } from 'react-intl';

import { PageHeader } from '@components/commons';

const CarePage = () => {
    return (
        <>
            <PageHeader title="care.title">
                <FormattedMessage id="care.title" />
            </PageHeader>
            <p className="text-justify">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in interdum nisl, at mattis velit.
                Curabitur pellentesque urna sed odio imperdiet, sed porta tortor cursus. Cras imperdiet ullamcorper
                lectus a pharetra. Morbi ut commodo nunc. Etiam a orci massa. Phasellus eu interdum mauris. Donec eu
                magna sed enim elementum efficitur ac nec erat. Nam tristique augue ut ligula aliquet, nec sodales nisl
                ornare. Vestibulum dapibus mi nulla, commodo lacinia ligula commodo congue.
            </p>
        </>
    );
};

export default CarePage;
