import type { SVGProps } from 'react';

const IconWishlist = ({ filled, ...props }: SVGProps<SVGSVGElement> & { filled?: boolean }) => (
    <svg viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        {!filled ? (
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.43627 4.93002L7.10773 3.07238C6.62131 2.39224 5.87111 1.98343 5.05284 1.98343C3.68976 1.98343 2.46683 3.15723 2.46683 4.75381C2.46683 5.54233 2.77202 6.24084 3.24981 6.7389L5.90134 9.50294H5.89437L8.43619 12.1558L10.978 9.50294H10.9712L13.6227 6.7389C14.1005 6.24084 14.4057 5.54233 14.4057 4.75381C14.4057 3.15723 13.1828 1.98343 11.8197 1.98343C11.0014 1.98343 10.2512 2.39224 9.76481 3.07238L8.43627 4.93002ZM8.43619 14.5167L2.06737 7.86961H2.07113C2.07053 7.86898 2.06993 7.86836 2.06933 7.86773C2.06868 7.86705 2.06803 7.86637 2.06737 7.86568C1.63135 7.41001 1.29048 6.85513 1.07854 6.23627C0.919899 5.77304 0.833496 5.27396 0.833496 4.75381C0.833496 2.32171 2.72256 0.350098 5.05284 0.350098C5.85509 0.350098 6.60505 0.583782 7.24364 0.98949C7.7075 1.28419 8.11259 1.66965 8.43627 2.12225C8.75996 1.66965 9.16505 1.28419 9.6289 0.98949C10.2675 0.583782 11.0174 0.350098 11.8197 0.350098C14.15 0.350098 16.039 2.32171 16.039 4.75381C16.039 5.27396 15.9526 5.77304 15.794 6.23627C15.582 6.8552 15.2411 7.41015 14.805 7.86585C14.8044 7.8665 14.8038 7.86715 14.8031 7.86781C14.8026 7.86841 14.802 7.86901 14.8014 7.86961H14.805L8.43619 14.5167Z"
                fill="currentColor"
            />
        ) : (
            <path
                fill="black"
                d="M8.44 14.52L2.07 7.87c-.44-.46-.78-1.01-.99-1.63-.16-.47-.25-.97-.25-1.49 0-2.43 1.89-4.4 4.22-4.4.81 0 1.56.23 2.19.64.47.29.87.68 1.2 1.13.32-.45.73-.84 1.19-1.13a4.04 4.04 0 012.19-.64c2.33 0 4.22 1.97 4.22 4.4 0 .52-.09 1.02-.25 1.49-.21.62-.55 1.17-.99 1.63l-6.36 6.65z"
            ></path>
        )}
    </svg>
);

export default IconWishlist;
