import { createContext, useContext } from 'react';

import { useOrders } from '@hooks/useOrders';
import { usePagination } from '@hooks/usePagination';
import { formatIsoDate } from '@utils/date';
import { getContentRange } from '@utils/fetch';
import { useAppContext } from './AppContext';
import type { OrderContextType, OrdersProviderProps } from 'types/context/order';

const OrdersContext = createContext<OrderContextType>({} as OrderContextType);

export const OrdersProvider = ({ children, keycloakId }: OrdersProviderProps) => {
    const { brandCode } = useAppContext();
    const { searchParams, currentPage, setCurrentPage } = usePagination();
    const dateFrom = searchParams.has('dateFrom') ? formatIsoDate(searchParams.get('dateFrom')!) : undefined;
    const dateTo = searchParams.has('dateTo') ? formatIsoDate(searchParams.get('dateTo')!) : undefined;

    const {
        data: ordersResponse,
        isSuccess,
        isError,
        isLoading,
    } = useOrders({
        currentPage,
        keycloakId,
        brandCode,
        dateFrom,
        dateTo,
    });
    const contentRange = ordersResponse && getContentRange(ordersResponse);

    return (
        <OrdersContext.Provider
            value={{
                keycloakId,
                orders: ordersResponse?.data,
                isSuccess,
                isError,
                isLoading,
                currentPage,
                setCurrentPage,
                contentRange,
            }}
        >
            {children}
        </OrdersContext.Provider>
    );
};

export const useOrdersContext = () => useContext(OrdersContext);
