import type { SVGProps } from 'react';

const IconMarker = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16" viewBox="0 0 13 16" aria-hidden="true" {...props}>
        <g>
            <path d="M6.5 0C10.4198939 0 13 3.18100572 13 6.84117207c0 1.19270772-.4368788 2.32724423-.9777576 3.29142223-.5477727.9763464-1.2592272 1.8787916-1.9396591 2.6335334-.68437118.7591937-1.36421209 1.3984835-1.87101512 1.8469361-.25438636.2251661-.46750757.4043295-.61907576.5286853-.07583333.0622273-.1365.1109012-.17924242.1449333l-.05052273.039968-.01467424.011476-.00462879.0035615-.00275757.0020775L6.5 16l-.84066667-.6562346-.00275757-.0020775-.00462879-.0035615-.01467424-.011476-.05052273-.039968c-.04284091-.0340321-.10340909-.082706-.17924242-.1449333-.15156819-.1243558-.3646894-.3035192-.61907576-.5286853-.50680303-.4484526-1.18664394-1.0877424-1.87111364-1.8469361-.68033333-.7547418-1.39182727-1.657187-1.93952121-2.6335334C.43685909 9.1684163 0 8.03387979 0 6.84117207 0 3.18100572 2.58010606 0 6.5 0zm0 5.2c-.71800831 0-1.3.58199169-1.3 1.3 0 .71793575.58204415 1.3 1.3 1.3.71795585 0 1.3-.58206426 1.3-1.3 0-.7180083-.5819917-1.3-1.3-1.3z" />
            <path
                fill="#000"
                fillRule="nonzero"
                d="M6.5 0C10.4198939 0 13 3.18100572 13 6.84117207c0 1.19270772-.4368788 2.32724423-.9777576 3.29142223-.5477727.9763464-1.2592272 1.8787916-1.9396591 2.6335334-.68437118.7591937-1.36421209 1.3984835-1.87101512 1.8469361l-.28415794.2475545-.58674357.4831471L6.5 16l-1.02183866-.8005817-.40557158-.3388c-.08725128-.0747821-.1824034-.1574881-.28415794-.2475545l-.36165582-.3264622c-.44752731-.4120404-.9770925-.9299899-1.50945782-1.5204739-.68033333-.7547418-1.39182727-1.657187-1.93952121-2.6335334C.43685909 9.1684163 0 8.03387979 0 6.84117207 0 3.18100572 2.58010606 0 6.5 0zm-.33127534 14.2140915L6.5 14.477l.33134118-.2629625.37789979-.3185227c.06718474-.0580378.13630486-.1184611.20711238-.1811352.60493355-.535285 1.20985505-1.1248798 1.77496094-1.7517689.72861259-.8081843 1.33673561-1.6192812 1.78435571-2.4171163.5346365-.95305036.82433-1.85941144.82433-2.70432233C11.8 3.65216424 9.57833601 1.2 6.5 1.2c-3.07833604 0-5.3 2.45216422-5.3 5.64117207 0 .84494289.28970425 1.75132509.82437437 2.70433132.44749327.79772371 1.05549612 1.60869041 1.78419867 2.41709161.56519951.6269027 1.17021012 1.2165616 1.775209 1.7519044.21228716.1879026.40952318.3556673.58494262.4995921z"
            />
            <path
                fill="#000"
                fillRule="nonzero"
                d="M6.5 4C7.88074999 4 9 5.11924999 9 6.5 9 7.880625 7.88074999 9 6.5 9 5.11925 9 4 7.880625 4 6.5 4 5.11924999 5.11925 4 6.5 4zm0 1.2c-.71800831 0-1.3.58199169-1.3 1.3 0 .71793575.58204415 1.3 1.3 1.3.71795585 0 1.3-.58206426 1.3-1.3 0-.7180083-.5819917-1.3-1.3-1.3z"
            />
        </g>
    </svg>
);

export default IconMarker;
