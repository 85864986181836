import clsx from 'clsx';

import { CashInfo, CreditsdInfo, EcardInfo, GiftsInfo, KlarnaInfo } from '@components/Box/Returns/additionalInfos';
import type { OMS } from 'types';

type Info = { name?: string };

interface AdditionalInformationsProps {
    additionalInformations: Info[];
    className?: string;
    refundOption?: OMS.OrderReturn['refundOption'];
}

export const AdditionalInformations = ({
    additionalInformations,
    className,
    refundOption,
}: AdditionalInformationsProps) => {
    return (
        <div className={clsx('flex flex-col gap-[1.6rem]', className)}>
            {refundOption === 'card' && additionalInformations.some(({ name }) => name === 'credits') && (
                <CreditsdInfo />
            )}
            {additionalInformations.some(({ name }) => name === 'cash') && <CashInfo />}
            {additionalInformations.some(({ name }) => name === 'klarna') && <KlarnaInfo />}
            {additionalInformations.some(({ name }) => name === 'ecard') && <EcardInfo />}
            {additionalInformations.some(({ name }) => name === 'gift') && <GiftsInfo />}
        </div>
    );
};
