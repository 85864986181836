import type { SVGProps } from 'react';

const IconInstagram = (props: SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M34.3672.5H14.9661C7.0813.5.6666 6.9147.6666 14.7995v19.401C.6666 42.0853 7.0813 48.5 14.9661 48.5h19.4011c7.8847 0 14.2994-6.4147 14.2994-14.2995v-19.401C48.6665 6.9147 42.2518.5 34.3672.5Zm9.4706 33.7005c0 5.2305-4.2402 9.4707-9.4706 9.4707H14.9661c-5.2305 0-9.4707-4.2402-9.4707-9.4707v-19.401c0-5.2306 4.2402-9.4707 9.4707-9.4707h19.4011c5.2304 0 9.4706 4.2401 9.4706 9.4707v19.401Z"
            fill="currentColor"
        />
        <path
            d="M24.6666 12.0859c-6.8454 0-12.4145 5.5692-12.4145 12.4145 0 6.8452 5.5691 12.4145 12.4145 12.4145 6.8454 0 12.4145-5.5692 12.4145-12.4145 0-6.8454-5.5691-12.4145-12.4145-12.4145Zm0 20.0003c-4.1895 0-7.5857-3.3962-7.5857-7.5858 0-4.1895 3.3963-7.5857 7.5857-7.5857 4.1895 0 7.5857 3.3962 7.5857 7.5857 0 4.1895-3.3963 7.5858-7.5857 7.5858Zm12.4385-16.9335c1.643 0 2.9748-1.3319 2.9748-2.9748s-1.3318-2.9748-2.9748-2.9748c-1.6429 0-2.9747 1.3319-2.9747 2.9748s1.3318 2.9748 2.9747 2.9748Z"
            fill="currentColor"
        />
    </svg>
);

export default IconInstagram;
