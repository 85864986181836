import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAnalytics } from '@hooks/useAnalytics';
import useConfirm from '@hooks/useConfirm';
import apiOms from '@utils/apiOms';
import Button from './Button';

interface CancelOrderButtonProps {
    className?: string;
    uid: string;
    queryKey: string;
}
const CancelOrderButton = ({ className, uid, queryKey }: CancelOrderButtonProps) => {
    const queryClient = useQueryClient();
    const intl = useIntl();
    const { onOrderRefunded } = useAnalytics();
    const cancelOrder = useMutation(async () => await apiOms.omsAuthenticated.cancelOrder(uid), {
        onSettled: (data) => {
            data?.data && onOrderRefunded(data?.data);
            queryClient.invalidateQueries({ queryKey: [queryKey] });
        },
    });
    const confirmCancelOrder = useConfirm(
        intl.formatMessage({ id: 'orders.order.action.cancel_order.confirm' }),
        cancelOrder.mutate
    );
    return (
        <Button color="danger" isLoading={cancelOrder.isLoading} onClick={confirmCancelOrder} className={className}>
            <FormattedMessage id="orders.order.detail.action.cancel_order" />
        </Button>
    );
};

export default CancelOrderButton;
