import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppContext } from 'src/context/AppContext';

import LinkButton from '@components/Link/LinkButton';

interface EmptyListProps {
    icon: ReactNode;
    message: React.ReactNode;
    withoutLink?: boolean;
}
const EmptyList = ({ icon, message, withoutLink }: EmptyListProps) => {
    const { frontUrl } = useAppContext();

    return (
        <div className="mt-32 flex flex-col items-center gap-8 mobile:mt-20">
            {icon}
            <div className="text-center font-account-heading text-xl font-medium uppercase text-gray-medium">
                {message}
            </div>
            {!withoutLink && (
                <LinkButton href={frontUrl}>
                    <FormattedMessage id="commons.discover" />
                </LinkButton>
            )}
        </div>
    );
};

export default EmptyList;
