import { FormattedMessage, useIntl } from 'react-intl';

import AdditionnalInfoReturnBox from '@components/Box/Returns/additionalInfos/AdditionnalInfoReturnBox';
import DisplayReturnedItem from '@components/Box/Returns/DisplayReturnedItem';
import ReturnInformations from '@components/Box/Returns/ReturnInformations';
import { SubTitle } from '@components/commons';
import Faq from '@components/Faq';
import { type CurrentTrackingStatus, type ReturnModeType } from '@utils/return';
import ReturnDetailsLayout from './Layout/ReturnDetailsLayout';
import type { UserOrderReturn } from 'types/apiOms';

interface ReturnDetailsTrackingProps {
    orderReturn: UserOrderReturn;
    currentTrackingStatus: CurrentTrackingStatus;
}

const ReturnDetailsTracking = ({ orderReturn, currentTrackingStatus }: ReturnDetailsTrackingProps) => {
    const intl = useIntl();
    return (
        <ReturnDetailsLayout
            orderReturnId={orderReturn.id}
            orderReturnModeType={orderReturn.returnModeType as ReturnModeType}
            currentTrackingStatus={currentTrackingStatus}
        >
            <section className="large:col-span-2">
                <div className="mb-8 flex flex-col gap-6 text-base">
                    <SubTitle className="m-0 text-5xl normal-case">
                        <FormattedMessage id="orderReturn.return.detail.tracking.products.title" />
                    </SubTitle>
                    {orderReturn.returnedItems &&
                        orderReturn.returnedItems.map((product, index) => (
                            <DisplayReturnedItem
                                product={product}
                                key={`${product.orderLineItem?.uid}-${index}`}
                                displayReturnedQuantity={currentTrackingStatus === 'refunded'}
                            />
                        ))}
                </div>

                {currentTrackingStatus === 'in_transit' && (
                    <AdditionnalInfoReturnBox title={intl.formatMessage({ id: 'orderReturn.return.detail.faq' })}>
                        <Faq faqLinks={['exchange', 'refund_delay', 'defect']} returns={true} />
                    </AdditionnalInfoReturnBox>
                )}
            </section>
            <section className="flex flex-col gap-[1.6rem]">
                <ReturnInformations
                    orderReference={orderReturn.firstOrderReference}
                    refundMode={orderReturn.refund_mode}
                    totalRefundAmount={orderReturn.totalRefundAmount}
                />
            </section>
        </ReturnDetailsLayout>
    );
};

export default ReturnDetailsTracking;
